import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginDataService, User } from 'src/app/modules/login/services/login-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<User> {

  constructor(private lds: LoginDataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.lds.getUserObs().pipe(take(1), map((data)=> {
      return data}))
  }
}

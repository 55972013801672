export const httpServerError: { [code: number]: string } = {
  0: 'No Internet',
  401: 'SessionExpired',
  403: 'SessionExpired',
  404: 'Resource Not Found',
  422: 'Invalid Input',
  500: 'Internal Server Error',
  502: 'Internal Server Error'
}

/** 
  * Extracts recursively the error message, 
  * given the error object structure from the server 
  */
export const getError = (error: any): string => error.error ? getError(error.error) : error;
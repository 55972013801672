import {Injectable} from '@angular/core';
import {combineLatest, ReplaySubject, Subject} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {dataLoadingStatus, DataRequestStatus} from "../../../shared/interfaces/data-request-status";
import {AsyncWrapInterface, FloorStandardGroup} from "../../../shared/services/api-calls-interfaces";
import {ApiCallsService, applyFailThresholdToStandardGroup, asyncWrap} from "../../../shared/services/api-calls.service";
import {BuildingItem, CampusItem, FloorItem} from "../../../shared/interfaces/interfaces.shared";

@Injectable({
  providedIn: 'root'
})
export class SpacesDataService {
  clearNestedSubs$ = new Subject<void>();
  paramsSubject = new ReplaySubject<any>();

  private spacesIdsSubject = new ReplaySubject<SpacesNavigation>();
  spacesIdsObs$ = this.spacesIdsSubject.asObservable();
  buildingStandardsResponseMessage = new ReplaySubject<DataRequestStatus>();

  buildingStandardsResponseResult = new ReplaySubject<FloorStandardGroup>();
  campus_id: number;
  building_id: number;
  floor_id: number;

  constructor(
    private apiCallsService: ApiCallsService
  ) {
    this.connectIdStream();
  }

  empty_current_space(): void {
    this.clearNestedSubs$.next();
    this.spacesIdsSubject.next(undefined);
    this.paramsSubject.next(undefined);
    this.buildingStandardsResponseMessage.next(undefined);
    this.buildingStandardsResponseResult.next(undefined);
  }

  connectIdStream(): void {
    this.paramsSubject.asObservable()
      .pipe(distinctUntilChanged((prev, cur) => {

        return prev?.campusId === cur?.campusId &&
          prev?.siteId === cur?.siteId &&
          prev?.floorId === cur?.floorId;

      }))
      .subscribe(
        (res: { campusId?: string, floorId?: string, siteId?: string }) => {
        if (!res || JSON.stringify(res) === '{}') {
          this.campus_id = undefined;
          this.building_id = undefined;
          this.floor_id = undefined;
          if (!res) { return; }
          this.spacesIdsSubject.next({floor: {id: undefined, label: undefined}, building: {id: undefined, label: undefined}, campus: {id: undefined, label: undefined}});
          return;
        }
        this.campus_id = +res.campusId;
        this.floor_id = +res.floorId;
        this.building_id = +res.siteId;

        const callsToMake: AsyncWrapInterface<any>[] = [];
        if (this.campus_id) {
          callsToMake.push(asyncWrap(this.apiCallsService.getCampusData(this.campus_id)));
        }

        if (this.building_id) {
          this.getBuildingStandards();
          callsToMake.push(asyncWrap(this.apiCallsService.getBuildingData(this.building_id)));
        }

        if (this.floor_id) {
          callsToMake.push(asyncWrap(this.apiCallsService.getFloorData(this.floor_id)));
        }
        if (!callsToMake.length) {
          return;
        }
        combineLatest(callsToMake).subscribe(
          data => {
            const campusData = data[0]?.data as CampusItem || {label: undefined};
            const buildingData = data[1]?.data as BuildingItem || {label: undefined};
            const floorData = data[2]?.data as FloorItem || {label: undefined};

            const nav: SpacesNavigation = {
              campus: {
                id: this.campus_id ? Number(this.campus_id) : undefined,
                label: campusData ? `${campusData.label}` : undefined
              },
              building: {
                id: this.building_id ? Number(this.building_id) : undefined,
                label: buildingData ? `${buildingData.label}` : undefined
              },
              floor: {
                id: this.floor_id ? Number(this.floor_id) : undefined,
                label: floorData ? `${floorData.label}` : undefined
              }
            };
            this.spacesIdsSubject.next(nav);
          }
        )
      }
    )
  }

  getBuildingStandards(): void {
    this.buildingStandardsResponseMessage.next(dataLoadingStatus);
    this.clearNestedSubs$.next();

    this.apiCallsService.getBuildingStandards(this.building_id)
      .pipe(takeUntil(this.clearNestedSubs$))
      .subscribe(
        (res: FloorStandardGroup) => {
          if (!res || !Object.keys(res).length) {
            throw new Error('Building not configured - no standards attached');
          }
          applyFailThresholdToStandardGroup(res);

          this.buildingStandardsResponseResult.next(res);
          this.buildingStandardsResponseMessage.next({
            message: `Results`,
            status: 'Complete'
          })
        }
      )
  }



}

export interface SpacesNavigation {
  campus: {
    label: string,
    id: number
  },
  building: {
    label: string,
    id: number
  },
  floor: {
    label: string,
    id: number
  },
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutModalContainerComponent} from './layout-modal-container.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    LayoutModalContainerComponent
  ],
  exports: [
    LayoutModalContainerComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ]
})
export class LayoutModalContainerModule {
}

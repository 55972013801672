<div class='message-container'>
<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content>
  <div class='message' [innerHTML]="data.message"></div>

</div>
<div mat-dialog-actions >
  <button class='botton dark' mat-button cdkFocusInitial (click)='close()' >Close</button>
</div>
</div>

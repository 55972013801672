<div class="tickets_filters" [style.grid-template-columns]="'repeat('+parameters.length+', auto)'">
  <ng-container *ngIf="!!currentData">
    <ng-container *ngFor="let parameter of parameters" [ngSwitch]="parameter.type">
      <ng-container *ngIf="!parameter.lockedTo">
        <div #variableDiv 
          *ngSwitchCase="'checkbox'"
          [ngClass]="{
            'active': (currentDropdown$ | async) === variableDiv,
            'disabled': (currentData.filters[parameter.name]?.true.length + currentData.filters[parameter.name]?.false.length) <= 0,
            'filtering': activeFilterSettings[parameter.name]?.length > 0
          }"
          class="parameter_parent"
        >
          <span (click)="showDropdown(variableDiv, parameter.name, currentData.filters)">
            <span>{{ parameter.name }}</span>
            <fa-icon [icon]="faSortDown"></fa-icon>
            <span *ngIf="activeFilterSettings[parameter.name]?.length > 0">
              {{activeFilterSettings[parameter.name].length }}
            </span>
          </span>
          <div class="dropdown">
            <ul>
              <li *ngFor="let parameterFilter of currentData.filters[parameter.name]?.true"
                (click)="swapFilter(parameterFilter, false, currentData.filters[parameter.name])"
              >
                <input type="checkbox" [checked]="true"> {{ parameterFilter }}
              </li>
              <li *ngFor="let parameterFilter of currentData.filters[parameter.name]?.false"
                (click)="swapFilter(parameterFilter, true, currentData.filters[parameter.name])"
              >
                <input type="checkbox" [checked]="false"> {{ parameterFilter }}
              </li>
            </ul>
            <div class="actions">
              <button (click)="clearFilter(currentData.filters[parameter.name], parameter.name)">
                Clear
              </button>
              <button (click)="saveFilter(currentData.filters[parameter.name], parameter.name)">
                Save
              </button>
            </div>
          </div>
        </div>

        <ng-container *ngSwitchCase="'readingtree'">
          <app-filter-parameter
            [currentData]="currentData"
            [currentSort$]="currentSortSub$"
            [showStatuses]="true"
            [resourceStatusInput]="resourceStatus"
          ></app-filter-parameter>
        </ng-container>

        <div #dateElement
          *ngSwitchCase="parameter.type === 'date' || parameter.type ==='datetime' ? parameter.type  : ''"
          class="input_style parameter_parent date-range"
        >
          <mat-date-range-input
            [rangePicker]="picker"
            [min]="minDate"
            [max]="maxDate"
          >
            <input
              #dateRangeStart
              class="custom"
              matStartDate
              [(ngModel)]="calendarFromDate"
              placeholder="Start date"
              readonly
            />
            <input
              #dateRangeEnd
              class="custom"
              matEndDate
              [(ngModel)]="calendarToDate"
              (ngModelChange)="onDateRangeChange({
                from: calendarFromDate._d,
                to: calendarToDate._d
              })"
              placeholder="End date"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker (selectedChange)="onDateRangeChange($event)">
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDateRangePickerApply>Save</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>
</div>
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  faEnvelope,
  faPhoneAlt,
  faUnlock,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { filter, take, tap } from 'rxjs/operators';

import { ArbnValidators } from 'src/app/shared/validators/form-validators';
import { LoginApiService } from '../services/login-api.service';
import { LoginDataService } from '../services/login-data.service';
import { LoginLogicService } from '../services/login-logic.service';
import { takeUntilComponent } from '../../../shared/services/takeUntilComponent';

// TODO This component (including the template) needs further refactoring.
// Ideally, every form should be managed in it's own component.
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends takeUntilComponent implements OnInit {
  faEnvelope = faEnvelope;
  faUnlock = faUnlock;
  faUserAlt = faUserAlt;
  // faBuilding = faBuilding;
  faPhoneAlt = faPhoneAlt;

  form: UntypedFormGroup;

  @Input() childLoginView = false;

  currentMode: 'signUp' | 'login' | 'forgot' | 'recover' | 'confirmAuth' =
    'login';

  recoverEmailSent = false;

  recoverSuccessTrue = false;

  pageTitle: string;
  successMessage: string;
  successTrue: boolean;
  passwordLabel: string;
  repeatPasswordLabel: string;
  passwordResetToken: string;
  backToLoginLabel: string = 'I already have a login';
  managerRegistrationCode: string;
  domesticRegistrationCode: string;
  signInUnlockToken?: string;

  rememberMe: boolean = false;

  loadingStatus$ = this.loginDataService.getSavingStatusObs();
  loginErrorMessage$ = this.loginDataService.getErrorMessageObs().pipe(
    tap((data) => {
      if (!data) {
        return;
      }

      if (
        data?.responseData?.type ===
        'Arbnwell::Errors::Unauthorized::LockedDueToIdleness'
      ) {
        this.router.navigate(['auth_confirm']);
        return;
      }

      if (
        data?.responseData?.type ===
        'Arbnwell::Errors::Unauthorized::NewPasswordRequired'
      ) {
        this.setToForgotPassword();
        return;
      }

      this.setFormFieldIsValid('email', data?.email_status !== false);
      this.setFormFieldIsValid('name', data?.name_status !== false);
      this.setFormFieldIsValid('password', data?.password_status !== false);
      this.setFormFieldIsValid(
        'confirmPassword',
        data?.mobilePhone_status !== false
      );
    })
  );

  constructor(
    private fb: UntypedFormBuilder,
    private loginDataService: LoginDataService,
    private loginApiService: LoginApiService,
    private lls: LoginLogicService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.setToLogin();

    // Initial navigation.
    this.activatedRoute.url.subscribe(this.navigateComponent);
    // Subsequent navigations.
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe(this.navigateComponent);

    if (!this.childLoginView) {
      this.connectParams();
      this.connectLoggedUserStream();
    }
  }

  navigateComponent = () => {
    if (document.location.pathname.startsWith('/auth_confirm')) {
      this.currentMode = 'confirmAuth';
      return;
    }

    if (document.location.pathname.startsWith('/login')) {
      this.setToLogin();
      return;
    }

    if (document.location.pathname.startsWith('/sign_up')) {
      this.lls.logout(false);
      this.setToSignUp();
      return;
    }
  };

  onSubmitLoginForm(): void {
    switch (this.currentMode) {
      case 'forgot':
        this.tryForgotPassword();
        return;
      case 'login':
        this.tryLogin();
        return;
      case 'recover':
        this.tryResetPassword();
        return;
      case 'signUp':
        this.trySignUp();
        return;
      default:
        return;
    }
  }

  setFormFieldIsValid = (formKey: string, isValid: boolean) => {
    this.setFormFieldError(formKey, isValid ? undefined : 'invalid');
  };

  setFormFieldError = (formKey: string, error?: string) => {
    this.form.get(formKey)?.setErrors(error ? { [error]: true } : null);
  };

  trySignUp(): void {
    if (this.form.valid) {
      let isDomestic: boolean = true;
      let regCode: string = this.domesticRegistrationCode;

      if (this.managerRegistrationCode) {
        isDomestic = false;
        regCode = this.managerRegistrationCode;
      }

      this.lls.signUpUser({
        email: this.form.value.email,
        name: this.form.value.name,
        mobilePhone: this.form.value.mobilePhone,
        password: this.form.value.password,
        confirmPassword: this.form.value.confirmPassword,
        registrationCode: regCode,
        isDomestic,
      });
    }
  }

  resetForm(): void {
    this.loginDataService.setErrorMessage(undefined);
    this.successTrue = false;
    this.recoverSuccessTrue = false;
    this.recoverEmailSent = false;
    this.form.reset();
  }

  setToSignUp(route = false): void {
    if (route) {
      this.router.navigate(['sign_up']);
    }
    this.pageTitle = 'Sign up';
    this.currentMode = 'signUp';
    this.repeatPasswordLabel = 'Confirm Password';

    this.form = this.fb.group({
      name: ['', Validators.required],
      mobilePhone: ['', mobilePhoneValidatorFn()],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, ArbnValidators.password]],
      confirmPassword: [
        '',
        [Validators.required, ArbnValidators.match('password')],
      ],
    });
    this.resetForm();
  }

  setToLogin(route = false): void {
    if (route) {
      this.router.navigate(['login']);
    }

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.resetForm();
    this.pageTitle = 'Login';
    this.passwordLabel = 'Password';
    this.currentMode = 'login';
  }

  tryLogin(): void {
    if (this.form.valid) {
      this.lls.loginUser({
        email: this.form.value.email,
        password: this.form.value.password,
        signInUnlockToken: this.signInUnlockToken,
        shouldRemember: this.rememberMe,
      });
    }
  }

  setToForgotPassword(): void {
    this.currentMode = 'forgot';
    this.pageTitle = 'Recover Password';

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.resetForm();
  }

  setToChangePassword(): void {
    this.pageTitle = 'Change password';
    this.passwordLabel = 'New Password';
    this.repeatPasswordLabel = 'Confirm password';

    this.currentMode = 'recover';

    this.form = this.fb.group({
      password: ['', [Validators.required, ArbnValidators.password]],
      confirmPassword: [
        '',
        [Validators.required, ArbnValidators.match('password')],
      ],
    });
    this.resetForm();
  }

  tryForgotPassword(): void {
    this.lls
      .forgotPassword(this.form.value.email)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.recoverEmailSent = true;
      });
  }

  tryResetPassword(): void {
    if (this.form.valid) {
      this.lls.setRecoveryPassword(
        this.form.value.password,
        this.passwordResetToken
      );
    }
  }

  connectParams(): void {
    this.activatedRoute.queryParams
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        if (data.sign_up) {
          this.setToSignUp();
        }

        if (data.sign_in_unlock_token) {
          this.signInUnlockToken = data.sign_in_unlock_token;
        }

        if (data.manager_registration_code) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.managerRegistrationCode = data.manager_registration_code;
        }

        if (data.domestic_registration_code) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.domesticRegistrationCode = data.domestic_registration_code;
        }

        if (data.email) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.form.get('email').setValue(data.email);
        }

        if (data.name) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.form.get('name').setValue(data.name);
        }

        if (data.mobilePhone) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.form.get('mobilePhone').setValue(data.mobilePhone);
        }

        if (data.manager_email) {
          if (this.currentMode !== 'signUp') {
            this.setToSignUp();
          }
          this.form.get('email').setValue(data.manager_email);
        }

        if (data.password_reset_token) {
          this.passwordResetToken = data.password_reset_token;
          this.setToChangePassword();
        }
      });
  }

  connectLoggedUserStream(): void {
    this.loginDataService
      .getUserTokenObs()
      .pipe(this.takeUntilDestroyed())
      .subscribe((user) => {
        if (!user) {
          // this.router.navigate(['/login']);
          return;
        } else {
          this.loginApiService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(() => {
              this.loginDataService.setSavingStatus({
                status: 'Complete',
                message: 'Login Complete',
              });
              if (this.childLoginView) {
                return;
              }
              this.router.navigate(['/filter-portfolios']);
            });
        }
      });
  }
}

export interface LoginFormInterface {
  name: string;
  mobilePhone: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface ChangePasswordInterface {
  email: string;
  old_password: string;
  password: string;
  confirm_password: string;
}

export const mobilePhoneValidatorFn =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;
    if (!value) {
      return null;
    }

    const isValid = isMobileValid(value);

    if (isValid) {
      return null;
    }

    return { invalid: true };
  };

export const isMobileValid = (input: string): boolean => {
  // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html#:~:text=Thanks%20to%20the%20international%20phone,in%20use%20contain%20seven%20digits.
  return !!input
    .split(' ')
    .join('')
    .split('-')
    .join('')
    .match('^\\+[0-9]{1,3}.[0-9]{4,14}(?:x.+)?$');
};

import { SignupUserOptions, UpdateUserOptions } from '../types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './login-data.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(private httpClient: HttpClient) {}

  confirmAccount(email: string): Observable<AuthenticationConfirm> {
    return this.httpClient
      .post<AuthenticationConfirmResponse>(
        `${environment.apiUrl}/users/reinstate_idle`,
        { email }
      )
      .pipe(map((data) => data.data));
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient
      .get<UsersListResponse>(`${environment.apiUrl}/users`)
      .pipe(map((data) => data.data.user));
  }

  loginUser(
    email: string,
    password: string,
    signInUnlockToken?: string
  ): Observable<UserSignInResult> {
    return this.httpClient.post<UserSignInResult>(
      `${environment.apiUrl}/users/sign_in`,
      {
        user: {
          email,
          password,
          sign_in_unlock_token: signInUnlockToken,
        },
      }
    );
  }

  getPreAuthNewSessionToken(
    token: string
  ): Observable<PreAuthicationNewSessionResponse> {
    const payload = { pre_authentication_token: token };
    return this.httpClient.post<any>(
      `${environment.apiUrl}/users/pre_authentication/confirm`,
      payload
    );
  }

  amILoggedIn(): Observable<boolean> {
    return this.httpClient
      .get<UsersListResponse>(`${environment.apiUrl}/users`)
      .pipe(
        map((data) => {
          return !!data.data.user;
        })
      );
  }

  logoutUser(): Observable<UserSignOutResult> {
    return this.httpClient.delete<UserSignOutResult>(
      `${environment.apiUrl}/users/sign_out`
    );
  }

  signUpUser({
    email,
    name,
    mobilePhone,
    password,
    confirmPassword,
    registrationCode,
    isDomestic,
  }: SignupUserOptions): Observable<UserResponse> {
    const userValues = {
      name,
      mobile_phone: mobilePhone,
      email,
      password,
      password_confirmation: confirmPassword,
    };

    let regPayLoad: any = { user: userValues };

    if (registrationCode) {
      if (isDomestic) {
        regPayLoad = {
          user: userValues,
          domestic_registration_code: registrationCode,
        };
      } else {
        regPayLoad = {
          user: userValues,
          manager_registration_code: registrationCode,
        };
      }
    }
    return this.httpClient
      .post<UserSignUpResult>(`${environment.apiUrl}/users/sign_up`, regPayLoad)
      .pipe(map((data) => data.data.user));
  }

  updateUser({
    email,
    name,
    surname,
    mobilePhone,
    password,
    confirmPassword,
    requirePasswordRotation,
    password_rotation_locked,
    enable_dashboard_view,
  }: UpdateUserOptions): Observable<UserSignUpResult> {
    return this.httpClient.patch<UserSignUpResult>(
      `${environment.apiUrl}/users`,
      {
        user: {
          name,
          surname,
          mobile_phone: mobilePhone,
          email,
          password,
          password_confirmation: confirmPassword,
          require_password_rotation: requirePasswordRotation,
          password_rotation_locked,
          enable_dashboard_view,
        },
      }
    );
  }

  forgotPassword(email: string): Observable<SendForgotPasswordResponse> {
    return this.httpClient.post<SendForgotPasswordResponse>(
      `${environment.apiUrl}/users/password_recovery?user_email=${email}`,
      {}
    );
  }

  setRecoveryPassword(
    password: string,
    token: string
  ): Observable<PasswordRecoverResponse> {
    return this.httpClient.put<PasswordRecoverResponse>(
      `${environment.apiUrl}/users/password_recovery/${token}`,
      { user: { password: password, password_confirmation: password } }
    );
  }

  setChangePassword(
    password: string,
    password_confirmation: string
  ): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl}/users`, {
      user: {
        password,
        password_confirmation,
      },
    });
  }
}

export interface PreAuthPayLoad {
  pre_authentication_token: string;
}

export interface UserSignInResult {
  data: {
    session_token: string;
    expiry: string;
  };
}

export interface UserSignUpResult {
  data: {
    user: UserResponse;
  };
}

export interface UsersListResponse {
  data: {
    user: UserResponse;
  };
}

export interface UserSignOutResult {
  data: {
    signed_out: boolean;
  };
}
export interface SendForgotPasswordResponse {
  data: {
    password_recovery_code: {
      used: boolean;
      created_at: string;
      expires_at: string;
    };
  };
}

export interface PasswordRecoverResponse {
  data: {
    user: UserResponse;
  };
}

export interface UserResponse {
  added_as_manager_by_id: boolean;
  gdpr_personal_data_usage_consent_at: string;
  role: 'super_user' | 'manager' | 'user' | 'domestic';
  id: number;
  email: string;
  created_at: string;
  updated_at: string;
  name: string;
  surname: string;
  mobile_phone: number;
  session_token_active_version?: number;
  password_digest?: string;
  logical_deletion?: boolean;
  session_token?: string;
}

export interface PreAuthicationNewSessionResponse {
  data: {
    pre_authentication_token: {
      new_session_token: {
        user: User;
        token: string;
        expiration_date: string;
      };
    };
  };
}

export interface AuthenticationConfirm {
  status: 'email_sent' | string;
}

export interface AuthenticationConfirmResponse {
  data: AuthenticationConfirm;
}

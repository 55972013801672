import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { UnitsPipePipe } from '../units-pipe.pipe';

@Pipe({
  name: 'readableParameter',
})
export class ReadableParameterPipe implements PipeTransform {
  constructor(private sani: DomSanitizer) {}

  transform(parameter: string, ...args: any[]): any {
    const isImperial = localStorage.getItem('user_units_imperial') === 'true';
    const showUnits = args[0];
    const isAlert = args[1];
    const isTooltip = args[3];

    if (!parameter) {
      return '';
    }

    if (isTooltip) {
      return `${ReadableParameterPipe.returnReadableReading(
        parameter,
        isAlert,
        isTooltip
      )}${
        showUnits
          ? `&nbsp;(${UnitsPipePipe.returnUnits(
              parameter.toLowerCase(),
              isImperial
            )})`
          : ''
      }`;
    }

    return this.sani
      .bypassSecurityTrustHtml(`<span style="display: inline-flex">
      ${ReadableParameterPipe.returnReadableReading(
        parameter.toLowerCase(),
        isAlert,
        isTooltip
      )}
      ${
        showUnits
          ? `&nbsp;(${UnitsPipePipe.returnUnits(
              parameter.toLowerCase(),
              isImperial
            )})`
          : ''
      }</span>`);
  }

  static returnReadableReading(
    input: string,
    isAlert: boolean = false,
    isTooltip: boolean = false
  ): string {
    const paramKeyToDisplayNameMap = {
      co2: isTooltip
        ? isAlert
          ? 'Ventilation (CO2)'
          : 'CO2'
        : isAlert
        ? '<span style="display: grid; grid-template-columns: auto auto 1fr; white-space: nowrap;">Ventilation (CO<span style="font-size: 0.66em; transform: translateY(40%)">2</span>)</span>'
        : '<span style="display: grid; grid-template-columns: auto auto 1fr; white-space: nowrap;">CO<span style="font-size: 0.66em; transform: translateY(40%)">2</span></span>',
      temperature: 'Temperature',
      humidity: 'Humidity',
      als: 'Lighting',
      lighting: 'Lighting',
      pm: isTooltip ? 'PM' : getPmDisplayName(''),
      pm25: isTooltip ? 'PM2.5' : getPmDisplayName('2.5'),
      pm10: isTooltip ? 'PM10' : getPmDisplayName('10'),
      pm01: isTooltip ? 'PM1.0' : getPmDisplayName('1.0'),
      pm04: isTooltip ? 'PM4.0' : getPmDisplayName('4.0'),
      tvoc: 'TVOC',
      formaldehyde: 'Formaldehyde',
      air_quality: 'Air Quality',
      ventilation: 'Ventilation',
      comfort: 'Comfort',
      air_movement: 'Air Movement',
      energy: 'Energy',
      current: 'Current',
      smell: 'Smell',
      noise: 'Noise',
      dust: 'Dust',
      air: 'Stuffiness',
      light: 'Light',
      sound: 'Sound',
      motion: 'Motion Level',
      no2: isTooltip ? 'NO2' : 'NO<sub>2</sub>',
      ammonia: 'Ammonia',
      so2: isTooltip ? 'SO2' : 'SO<sub>2</sub>',
      ozone: 'Ozone',
      co: 'CO',
    };

    return paramKeyToDisplayNameMap[input] || 'Parameter';
  }
}

// TODO replace this and other similar code with <sup> or <sub> tags, if
// possible.
const getPmDisplayName = (pmNumber: string = '') =>
  '<span style="display: grid; grid-template-columns: auto auto 1fr; white-space: nowrap;">' +
  'PM' +
  '<span style="font-size: 0.66em; transform: translateY(40%)">' +
  pmNumber +
  '</span></span>';

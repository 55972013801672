import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ColumnTypes } from 'src/app/modules/spaces/0-portfolio/portfolio.component';
import { SideMenuData, SideMenuDataService, SideMenuItem } from 'src/app/modules/spaces/data/side-menu-data.service';
import { takeUntilComponent } from "../../../shared/services/takeUntilComponent";
import { DataRequestStatus } from '../../interfaces/data-request-status';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends takeUntilComponent implements OnInit {

  private eventsSubscription: Subscription;


  sideMenuMessage$: Observable<DataRequestStatus> = this.smd.sideMenuMessageSubject$;
  sideMenuData$: Observable<SideMenuItem> = this.smd.sideMenuDataObs$;
  sideMenuType$: Observable<string> = this.smd.sideMenuTypeObs$;
  parameters: string[] = [ 'aqi', 'comfort', 'ventilation']

  @Input() openEvents: Observable<SideMenuDataInput>;
  @Input() columnTypes: ColumnTypes;
 
  spaceName: string;
  isOpen: boolean = false;

  constructor(private smd: SideMenuDataService) {
    super();
   }

  ngOnInit(): void {
    this.eventsSubscription = this.openEvents.pipe(this.takeUntilDestroyed()).subscribe(data => this.handleSubscription(data));


  }

  handleSubscription(input: SideMenuDataInput): void{
    if (input.action === 'open') {
      this.openMenu(input.id, input.index, input.hierarchy, input.name);
      setTimeout(()=> { this.isOpen = true; }, 500)
    }

    if (input.action ==='close' && this.isOpen) {
      this.closeMenu();
    }
  }

  getObjectKey(obj: any): string{
    const key =  Object.keys(obj)[0];
    let first = key.substr(0,1).toUpperCase();
    const keyCapitilised =  first + key.substr(1); 
    return keyCapitilised;
  }

  

  getIconSource(obj: SideMenuData): string{
    const key = this.getObjectKey(obj);
    return this.columnTypes[key].icon;
  }
  


  openMenu(id: number, index: number, heirachy: string[], name: string): void {
    this.smd.getSideMenuData(id, index, heirachy);
    this.spaceName = name;
    document.getElementById("mySidenav").style.width = "412px";
    document.getElementById("mySidenav").style.boxShadow = "-5px 5px 8px 4px rgba(0,0,0,0.2)";
  }
  
  closeMenu():void {
    this.isOpen = false;
    this.smd.empty_current_side_menu();
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.boxShadow = "-5px 5px 8px 4px rgba(0,0,0,0.0)";
  }

  typeName(type: string): string{
    return (type === 'site' ? 'building' : type);
  }

  formatTitle(title: string): string {
    return (title === 'aqi' ? 'air quality' : title)
  }


}

export interface SideMenuDataInput {
  action: 'open' | 'close',
  id?: number,
  index?: number,
  hierarchy?: string[],
  name?: string
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { PortfolioDataService } from '../modules/spaces/data/portfolio-data.service';

@Injectable({
  providedIn: 'root'
})
export class SpacesGuard implements CanActivate {

  constructor(
    private apis: PortfolioDataService,
    private router: Router
  ) {}
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    const fragments = state.url.split('/spaces').filter(fragment => !!fragment);

    if (fragments.length > 0) {
      return of(true);
    }
    
    return this.apis.loadPortfolioTiers().pipe(
      map(() => {
        
        if (this.apis.isDashboardEntitled()) {
          return this.router.parseUrl('/dashboard');
        }

        return true;
      })
    );
  }
  
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TippyService } from '@ngneat/helipopper';
import { CreateOptions } from '@ngneat/helipopper/lib/tippy.types';
import { TooltipComponent, ToolTipParams } from '../tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit {

  @Input('tooltip') source: any;
  @Input('tooltipDisabled') disabled: boolean = false;
  @Input('data') data: ToolTipParams;

  constructor(
    private el: ElementRef,
    private tippy: TippyService
  ) { }

  ngOnInit(): void {
    if (!this.source) {
      this.source = TooltipComponent;
    }

    const opts: Partial<CreateOptions> = {
      placement: this.data?.position || 'bottom',
      appendTo: this.data?.appendTo || document.body
    };

    if (this.data) opts.data = this.data;

    if (!this.disabled) {
      this.tippy.create(this.el.nativeElement as unknown as Element, this.source, opts);
    }

  }

}

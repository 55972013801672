import {
  AnonymiseBuildingPipe,
  AnonymiseFloorPipe,
} from '../pipes/anonymise.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionItemComponent } from './layout/accordion-item/accordion-item.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ArbnButtonModule } from '@arbncoltd/arbn-components';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FeedbackParameterPipePipe } from '../pipes/feedback-parameter-pipe.pipe';
import { FilterParameterModule } from './inputs/filter-parameter/filter-parameter.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from '../modules/home/footer/footer.component';
import { FormCheckboxInputComponent } from './inputs/form-checkbox-input/form-checkbox-input.component';
import { FormRadioInputComponent } from './inputs/form-radio-input/form-radio-input.component';
import { FormRadioInputType2Component } from './inputs/form-radio-input-type2/form-radio-input-type2.component';
import { HeaderBarComponent } from './layout/header-bar/header-bar.component';
import { IssuesFiltersComponent } from './inputs/issues-filters/issues-filters.component';
import { IssuesIconsComponent } from './widgets/issues-icons/issues-icons.component';
import { IssuesModalComponent } from './components/issues-modal/issues-modal.component';
import { IssuesSummaryComponent } from './widgets/issues-summary/issues-summary.component';
import { LayoutActionButtonComponent } from './layout/layout-action-button/layout-action-button.component';
import { LayoutActionContainerComponent } from './layout/layout-action-container/layout-action-container.component';
import { LayoutModalContainerModule } from './layout/layout-modal-container/layout-modal-container.module';
import { LayoutPageContainerComponent } from './layout/layout-page-container/layout-page-container.component';
import { LayoutTableComponent } from './layout/layout-table/layout-table.component';
import { LayoutTablePaginationComponent } from './layout/layout-table-pagination/layout-table-pagination.component';
import { LayoutTabsComponent } from './layout/layout-tabs/layout-tabs.component';
import { LayoutTabsPageComponent } from './layout/layout-tabs-page/layout-tabs-page.component';
import { LayoutTemplateListComponent } from './layout/layout-template-list/layout-template-list.component';
import { LayoutTreeComponent } from './layout/layout-tree/layout-tree.component';
import { LegendComponent } from './widgets/legend/legend.component';
import { LoadingModalComponent } from './widgets/loading-modal/loading-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { PageOverlayComponent } from './layout/page-overlay/page-overlay.component';
import { PageWrapperComponent } from './layout/page-wrapper/page-wrapper.component';
import { PasswordTipsComponent } from './widgets/password-tips/password-tips.component';
import { QueryErrorComponent } from './widgets/query-error/query-error.component';
import { QueryLoadingComponent } from './widgets/query-loading/query-loading.component';
import { RangeSliderComponent } from './inputs/range-slider/range-slider.component';
import { ReadableParameterModule } from '../pipes/readable-parameter/readable-parameter.module';
import { ReadableParameterPipe } from '../pipes/readable-parameter/pipe-readings.pipe';
import { ResourceStatusModule } from '../pipes/resource-status/resource-status.module';
import { SanitisePipe } from '../pipes/sanitise.pipe';
import { SideMenuComponent } from './layout/side-menu/side-menu.component';
import { SiteTzModule } from '../pipes/site-tz/site-tz.module';
import { StandardHistogramComponent } from '../modules/spaces/2-building/building-settings-tab/standards-settings/standard-histogram/standard-histogram.component';
import { SubmitButtonComponent } from './inputs/submit-button/submit-button.component';
import { TableSortIconComponent } from './widgets/table-sort-icon/table-sort-icon.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TriColourBarComponent } from './widgets/tri-colour-bar/tri-colour-bar.component';
import { UnitsPipePipe } from '../pipes/units-pipe.pipe';
import { UsUnitsPipe } from '../pipes/us-units.pipe';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { TooltipModule } from './modules/tooltip/tooltip.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

// Currently we don't have any choices but using the Moment.js Adapter
// for Material Date Picker. This is because at this point, Angular Material
// natively supports a date adapter based on the native JavaScript Date object.
// As you know, this isn't optimized, error proning and not flexible at all,
// especially on parsing date strings into dates.
// Despite moment.js is officially deprecated, the app still takes advantage of
// its interfaces and Angular Material does not provide yet valid alternatives to
// that as standard date library for parsing & formatting dates.
export const WELL_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY, hh:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    RangeSliderComponent,
    IssuesModalComponent,
    IssuesFiltersComponent,
    HeaderBarComponent,
    QueryLoadingComponent,
    QueryErrorComponent,
    UnitsPipePipe,
    TableSortIconComponent,
    IssuesIconsComponent,
    LayoutTabsComponent,
    LayoutTemplateListComponent,
    SanitisePipe,
    StandardHistogramComponent,
    LegendComponent,
    LayoutTableComponent,
    LayoutTablePaginationComponent,
    LayoutTreeComponent,
    LayoutTabsPageComponent,
    AlertsComponent,
    TicketsComponent,
    IssuesSummaryComponent,
    FeedbackParameterPipePipe,
    PageWrapperComponent,
    PageOverlayComponent,
    FormCheckboxInputComponent,
    LayoutActionContainerComponent,
    FormRadioInputComponent,
    LayoutActionButtonComponent,
    FooterComponent,
    LayoutPageContainerComponent,
    ConfirmDialogComponent,
    AccordionItemComponent,
    LayoutTreeComponent,
    TriColourBarComponent,
    SideMenuComponent,
    AnonymiseFloorPipe,
    AnonymiseBuildingPipe,
    LoadingModalComponent,
    UsUnitsPipe,
    FormRadioInputType2Component,
    SubmitButtonComponent,
    PasswordTipsComponent,
    ButtonToggleComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModalContainerModule,
    SiteTzModule,
    FilterParameterModule,
    ReadableParameterModule,
    ResourceStatusModule,
    ArbnButtonModule,
    TooltipModule
  ],
  exports: [
    CommonModule,
    ArbnButtonModule,
    MatMenuModule,
    RangeSliderComponent,
    IssuesModalComponent,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    IssuesFiltersComponent,
    HeaderBarComponent,
    QueryLoadingComponent,
    QueryErrorComponent,
    TableSortIconComponent,
    IssuesIconsComponent,
    LayoutTabsComponent,
    LayoutTemplateListComponent,
    SanitisePipe,
    UnitsPipePipe,
    StandardHistogramComponent,
    LegendComponent,
    LayoutTableComponent,
    LayoutTablePaginationComponent,
    LayoutTreeComponent,
    TriColourBarComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    LayoutTabsPageComponent,
    AlertsComponent,
    TicketsComponent,
    IssuesSummaryComponent,
    FeedbackParameterPipePipe,
    PageWrapperComponent,
    PageOverlayComponent,
    FormCheckboxInputComponent,
    LayoutActionContainerComponent,
    FormRadioInputComponent,
    LayoutActionButtonComponent,
    FooterComponent,
    LayoutPageContainerComponent,
    AccordionItemComponent,
    ReadableParameterPipe,
    MatProgressSpinnerModule,
    AnonymiseFloorPipe,
    AnonymiseBuildingPipe,
    LoadingModalComponent,
    FormRadioInputType2Component,
    UsUnitsPipe,
    SubmitButtonComponent,
    PasswordTipsComponent,
    ButtonToggleComponent,
    TooltipModule
  ],
  providers: [
    DatePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: WELL_DATE_FORMATS 
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    }
  ],
})
export class ArbnwellSharedModule {}

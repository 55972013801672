import {
  ARBN_FORM_FIELD_CONFIG,
  ArbnButtonModule,
  ArbnCheckboxModule,
  ArbnFormFieldModule,
} from '@arbncoltd/arbn-components';

import { ArbnwellSharedModule } from '../../shared/arbnwell-shared.module';
import { AuthConfirmComponent } from './auth-confirm/auth-confirm.component';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LoginComponent, AuthConfirmComponent],
  exports: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    ArbnwellSharedModule,
    ArbnFormFieldModule,
    ArbnButtonModule,
    ArbnCheckboxModule,
  ],
  providers: [
    {
      provide: ARBN_FORM_FIELD_CONFIG,
      useValue: { requiredIndicatorType: 'asterisk' },
    },
  ],
})
export class LoginModule {}

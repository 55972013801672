import {Observable} from "rxjs";
import {CustomHttpError} from "../../guards/apikey.interceptor";
import {ArcScores, BuildingItem, CampusItem, DeviceItem, FloorItem, RoomItem, WellI} from "../interfaces/interfaces.shared";
import {environment} from "../../../environments/environment";
import {HashTable} from "../../modules/spaces/data/floor-data.service";
import {StandardItem} from '../../modules/spaces/2-building/building-settings-tab/standards-settings/standards-api.service';
import {BuildingParentItem} from '../../modules/spaces/data/building-data.service';
import { WellnessItem } from "src/app/modules/spaces/data/tree-view-data.service";

// platform status: https://github.com/arbncoltd/arbnwell-ror/pull/927
export type PlatformServiceReport = {
  name: "Database" | "Cache" | "Redis" | "Readings consumers" | "Readings processors",
  message: string,
  status: "OK" | "ERROR"
}

export enum PlatformStatusEnum {
  OK = "ok",
  UNAVAILABLE = "service_unavailable",
  MAINTENANCE = "under_scheduled_maintenance"
}

export type PlatformMaintenance = {
  message: string;
  from: string;
  to: string;
}

export interface PlatformStatusI {
  results: PlatformServiceReport[];
  status: PlatformStatusEnum;
  timestamp: string;
  maintenance: {
    active: PlatformMaintenance[],
    past: PlatformMaintenance[],
    upcoming: PlatformMaintenance[]
  }
}

export enum WellResource {
  'portfolios' = 'portfolios',
  'campuses' = 'campuses',
  'sites' = 'sites',
  'floors' = 'floors',
  'devices' = 'devices',
  'rooms' = 'rooms',
}

export type ResourceSummary = PortfolioSummary | CampusSummary | SiteSummary;

export interface PortfolioSummary {
  portfolio: ItemSummary;
}

export interface CampusSummary {
  campus: ItemSummary;
}

export interface SiteSummary {
  site: ItemSummary;
}

export interface ItemSummary {
  active_alerts: number;
  active_tickets: number;
  wellness: WellnessItem;
  campuses?: number;
  total_floors?: number;
  total_sites?: number;
  total_online_floors?: number;
  total_online_sites?: number;
  total_devices?: number;
  total_online_devices?: number;
}

export interface deviceSingleReadingWithRange {
  dateRange: getDataRangeResponse;
  readings: RoomDeviceReadings;
}

export interface getDataRangeResponse {
  data_range: {
    maximum_date: string,
    minimum_date: string
  };
}

export interface PostTicketResponse {
  data: {
    ticket: FeedbackTicketItem
  };
}

export interface FeedbackTicketItem {
  type: string;
  content: string;
  created_at: string;
  device: DeviceItem;
  room: RoomItem;
  floor: FloorItem;
  site: BuildingItem;
  campus: CampusItem;

  id: number;
  new_ticket: boolean;
  parameters_as_integer: {
    air: number;
    dust: number;
    smell: number;
    noise: number;
    lighting: number;
    humidity: number;
    temperature: number;
    air_movement: number;
    clothing_level: number;
  };
  air: string;
  air_movement: string;
  clothing_level: string;
  dust: string;
  humidity: string;
  lighting: string;
  noise: string;
  read: boolean;
  resolved: boolean;
  smell: string;
  temperature: string;
  updated_at: string;
  user_id: number;
  facility_issue?: any;
}
export interface EnvironmentFeedbackTicket {
  content?: string;
  co2?: true | false;
  humidity?: -1 | 0 | 1;
  als?: -1 | 0 | 1;
  pm?: true | false;
  temperature?: -1 | 0 | 1;
  tvoc?: true | false;
  formaldehyde?: true | false;
  noisy?: true | false;
  activity_level?: -1 | 0 | 1;
  clothing_level?: -1 | 0 | 1;
}

export interface FacilityFeedbackTicket {
  type: 'Tickets::Facility';
  facility_issue: 'electrical' | 0 | 'waste' | 1 | 'appliance' | 2 | 'furniture' | 3 | 'other' | 4;
  content: string;
}

export interface RoomsListEdgesResponse {
  data: {
    rooms: RoomWithEdges[]
  };
}

export interface Room {
  created_at: string;
  floor_id: number;
  id: number;
  label: string;
  updated_at: string;
  campus_id: number;
  site_id: number;
}

export interface RoomWithEdges extends Room {
  edges: Edge[];
}

export interface Edge {
  id: number;
  x_start: number;
  x_end: number;
  y_start: number;
  y_end: number;
}

export interface FloorStandardAllResponse {
  categories: string[];
  data: {
    temperature: StandardItem[],
    co2: StandardItem[],
    humidity: StandardItem[],
    als: StandardItem[],
    tvoc: StandardItem[],
    formaldehyde: StandardItem[],
    pm01: StandardItem[],
    pm25: StandardItem[],
    pm10: StandardItem[],
    pm04: StandardItem[],
    current: StandardItem[]
  };
}

export interface FloorStandardResponse {
  data: FloorStandardGroup;
}

export interface FloorStandardGroup {
  temperature: StandardItem;
  humidity: StandardItem;
  co2: StandardItem;
  als: StandardItem;
  tvoc: StandardItem;
  formaldehyde: StandardItem;
  pm01: StandardItem;
  pm10: StandardItem;
  pm25: StandardItem;
  pm04: StandardItem;
  current: StandardItem;
}

export interface FloorBuildingStandards {
  floor: FloorItem;
  building: BuildingParentItem;
  standards: FloorStandardGroup;
}

export interface PaginationInput {
  page?: number;
  per_page?:number;
  search?: string;
  sort_by?: string;
  order_direction?: 'desc' | 'asc';
  clear?: boolean
}

export interface PaginationResponse {
  data_range?: {
    maximum_date: string,
    minimum_date: string
  };
  page: number;
  page_size: number;
  total_pages: number;
  total_records?: number;
}

export interface GroupedReadingResponse extends PaginationResponse {
  data: GroupedByDeviceReadings;
}
export interface GetReadingsResponse extends PaginationResponse  {
  data: HashTable<RoomDeviceReadings>
}

export type GroupedByDeviceReadings = HashTable<HashTable<RoomDeviceReadingsForGrouped>>;

export interface RoomDeviceReadingsForGrouped {
  count: number;
  als: number;
  co2: number;
  humidity: number;
  pm01: number;
  pm10: number;
  pm25: number;
  pm04: number;
  temperature: number;
  tvoc: number;
  formaldehyde: number;
  current: number;
}
export interface RoomDeviceReadings {
  als: ReadingRange;
  co2: ReadingRange;
  count: number;
  humidity: ReadingRange;
  pm01: ReadingRange;
  pm25: ReadingRange;
  pm10: ReadingRange;
  pm04: ReadingRange;
  temperature: ReadingRange;
  tvoc: ReadingRange;
  formaldehyde: ReadingRange;
  current: ReadingRange;
  date?: Date;
}

export interface ReadingRange {
  min: number;
  med: number;
  max: number;
}

export interface AsyncWrapInterface<T> extends Observable<{data: T,  error: CustomHttpError}> {}

export interface GetFloorResponse {
  data: {
    floor: FloorItem;
  }
}

export interface GetCampusResponse {
  data: {
    campus: CampusItem;
  }
}

export interface  ResourceInput {
  portfolio_id?: number,
  campus_id?: number,
  building_id?: number,
  floor_id?: number,
  device_id?: number,
  ticket_id?: number,
  authorized_tiers?: boolean,
  favorites?: boolean
}

export interface GeneralPaginationResponse extends PaginationResponse {
  data: any[]
}

/**
 * @deprecated use getResourceURLParams instead.
 * @param resources 
 * @param endpoint 
 * @param from 
 * @param to 
 * @param parameters 
 * @param pagination 
 * @returns 
 */
export function getResourceURL(resources: ResourceInput, endpoint: string, from?: number, to?: number, parameters?: string[], pagination?: PaginationInput ) : string {
  let url = `${environment.apiUrl}`;
  if (resources.portfolio_id) {
    url += `/portfolios/${resources.portfolio_id}`
  }
  if (resources.campus_id) {
    url += `/campuses/${resources.campus_id}`
  }
  if (resources.building_id) {
    url += `/sites/${resources.building_id}`
  }
  if (resources.floor_id) {
    url += `/floors/${resources.floor_id}`
  }
  if (resources.device_id) {
    url += `/devices/${resources.device_id}`
  }
  if (resources.ticket_id) {
    url += `/tickets/${resources.ticket_id}`
  }
  url += `/${endpoint}`;
  if (parameters) {
    for (let i = 0; i < parameters.length; i++ ) {
      url += `${i === 0 ? '?' : '&'}${parameters[i]}`;
    }
  }
  if (from && to) {
    url += `${parameters ? '&' : '?'}from=${from}&to=${to}`;
  }

  if(pagination){
    url += `${parameters || (from && to) ? '&' : '?'}`;
    if(pagination.page){
      url += `page=${pagination.page}`; 
    }
    if(pagination.per_page){
      url += `${pagination.page ? '&' : ''}per_page=${pagination.per_page}`;
    }
  }
  return url;
}

/**
 * Returns the url generated from the composition of resources and params.
 * @param resources 
 * @param endpoint 
 * @param params 
 * @returns 
 */
export function getResourceURLParams(resources: ResourceInput, endpoint: string, params: ParamsInput = {}) : string {
  let url = `${environment.apiUrl}`;
  if (resources.portfolio_id) {
    url += `/portfolios/${resources.portfolio_id}`
  }
  if (resources.campus_id) {
    url += `/campuses/${resources.campus_id}`
  }
  if (resources.building_id) {
    url += `/sites/${resources.building_id}`
  }
  if (resources.floor_id) {
    url += `/floors/${resources.floor_id}`
  }
  if (resources.device_id) {
    url += `/devices/${resources.device_id}`
  }
  if (resources.ticket_id) {
    url += `/tickets/${resources.ticket_id}`
  }
  url += `/${endpoint}`;

  if(Object.keys(params).length > 0){
    url += '?'
  
  if (params?.parameters?.length > 0) {
    for (let i = 0; i < params.parameters.length; i++ ) {
      url += `${i === 0 ? '' : '&'}${params.parameters[i]}`;
    }
  }
  if (params.from && params.to) {
  
    url += `${url.charAt(url.length - 1) === '?' ? '' : '&' }from=${params.from}&to=${params.to}`;
  }

  if(params.page){
    url += `${url.charAt(url.length - 1) === '?' ? '' : '&'}page=${params.page}`; 
    }
  if(params.per_page){
      url += `${url.charAt(url.length - 1) === '?' ? '' : '&'}per_page=${params.per_page}`; 
  }
  if(params.search){
    url += `${url.charAt(url.length - 1) === '?' ? '' : '&'}search=${params.search}`; 
}
if(params.sort_by){
  url += `${url.charAt(url.length - 1) === '?' ? '' : '&'}sort_by=${params.sort_by}`; 
}
if(params.order_direction){
  url += `${url.charAt(url.length - 1) === '?' ? '' : '&'}order_direction=${params.order_direction}`; 
}
  }


  return url;
}

export interface ParamsInput extends PaginationInput {
  from?: number, 
  to?: number, 
  parameters?: string[]
}

export const ReadingTypes = [
  'als',
  'pm25',
  'pm04',
  'pm10',
  'pm01',
  'tvoc',
  'formaldehyde',
  'co2',
  'temperature',
  'humidity',
  'current'
];

export interface WellnessScoreResponse {
  data: {
    wellness_score: WellnessItem;
  };
}

export type WellnessScoreDataTrend = WellnessItem[];

export interface WellnessScoreTrendResponse extends PaginationResponse {
  data: {
    scores: WellnessScoreDataTrend;
  }
}

export interface ArcScoreRecord {
  stored_at: string;
  id: number;
  scores: ArcScores;
  site_id: number;
  created_at: string;
  updated_at: string;
};

export type ArcScoreDataTrend = ArcScoreRecord[];

export interface ArcScoreTrendResponse extends PaginationResponse {
  data: {
    scores: ArcScoreDataTrend;
  }
}

export type ScoreTrendResponse = ArcScoreTrendResponse | WellnessScoreTrendResponse;

export type ScoreDataTrend = ArcScoreDataTrend | WellnessScoreDataTrend;

type DailyAlertCount = {
  [timestamp: string]: { weekday: number, count: number };
}

// Exposes information for alert counter end point: https://github.com/arbncoltd/arbnwell-ror/pull/773
export interface AlertCounters {
  total: number;
  weekday_values?: {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
  },
  per_day?: DailyAlertCount;
}

export interface ScheduleSurveyPayload {
  arc_auth_attributes: RequireAtLeastOne<ScheduleSurvey> & { id: number };
}

export interface ScheduleSurvey {
  survey_re_entry_schedule?: NotificationFrequency;
  survey_human_experience_schedule?: NotificationFrequency;
  survey_transport_schedule?: NotificationFrequency;
}

export type NotificationFrequency = 
| "weekly"
| "fortnightly"
| "monthly"
| null;

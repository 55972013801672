<header
  class="header_root"
  [ngClass]="{ dashboard: findActive(['dashboard']) }"
>
  <div class="content_container no-vertical-padding no-margin">
    <a [routerLink]="'spaces'">
      <img src="../assets/logos/Topbar_logo.svg" />
    </a>
    <div *ngIf="currentUser$ | async as user">
      <a
        *ngIf="user?.role !== 'deployer'"
        [routerLink]="'spaces'"
        [ngClass]="{ active: findActive(['spaces', 'dashboard']) }"
        >Spaces</a
      >
      <a
        *ngIf="['super_user', 'manager'].includes(user?.role)"
        [routerLink]="'operations'"
        routerLinkActive="active"
        >Operations</a
      >
      <a
        *ngIf="['super_user', 'deployer'].includes(user?.role)"
        [routerLink]="'deployment'"
        routerLinkActive="active"
        >Deployment</a
      >
      <!-- Hide help page as long as it is not ready -->
      <!-- <a   *ngIf="['super_user', 'domestic'].includes((currentUser$ | async)?.role)" [routerLink]="'help'" routerLinkActive="active">Help</a> -->
      <a
        [matMenuTriggerFor]="accountMenu"
        class="clickable"
        [ngClass]="{ active: findActive(['settings', 'profile']) }"
        >Account</a
      >
      <mat-menu #accountMenu xPosition="before">
        <app-account-dropdown></app-account-dropdown>
      </mat-menu>
    </div>
  </div>
</header>
<ng-template #hiddenActiveRoutes>
  <a [routerLink]="'settings'" settingsActive="routerLinkActive"></a>
  <a [routerLink]="'profile'" profileActive="routerLinkActive"></a>
</ng-template>

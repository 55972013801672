import * as PasswordValidator from 'password-validator';

import { ValidatorFn } from '@angular/forms';

var passwordSchema = new PasswordValidator();
passwordSchema.min(10).uppercase().lowercase().digits().symbols();

export class ArbnValidators {
  static password: ValidatorFn = (control): { strength: true } | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValid = passwordSchema.validate(value);
    if (isValid) {
      return null;
    }

    return {
      strength: true,
    };
  };

  static match(matchControl: string): ValidatorFn {
    return (control): { match: true } | null => {
      const value1 = control.value;

      if (!value1) {
        return null;
      }

      const value2 = control.parent.get(matchControl).value;

      if (value1 === value2) {
        return null;
      }

      return { match: true };
    };
  }
}

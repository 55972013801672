import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterParameterComponent} from './filter-parameter.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatMenuModule} from '@angular/material/menu';
import {ReadableParameterModule} from '../../../pipes/readable-parameter/readable-parameter.module';
import {MatButtonModule} from '@angular/material/button';
import {ResourceStatusModule} from '../../../pipes/resource-status/resource-status.module';
import { TooltipModule } from '../../modules/tooltip/tooltip.module';


@NgModule({
  declarations: [FilterParameterComponent],
  exports: [
    FilterParameterComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatMenuModule,
    ReadableParameterModule,
    MatButtonModule,
    ResourceStatusModule,
    TooltipModule
  ]
})
export class FilterParameterModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipMoreInfoComponent } from './tooltip-more-info/tooltip-more-info.component';
import { popperVariation, TippyModule, tooltipVariation, withContextMenuVariation } from '@ngneat/helipopper';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipDirective } from './tooltip-directive/tooltip.directive';
import { Placement } from 'tippy.js';

const tooltipCustomVariation = { 
  ...tooltipVariation,
  arrow: true,
  interactive: true,
  placement: 'bottom' as Placement,
  maxWidth: 400
};

@NgModule({
  declarations: [
    TooltipComponent,
    TooltipMoreInfoComponent,
    TooltipDirective
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    // solution based on the library helipopper:
    // https://github.com/ngneat/helipopper
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipCustomVariation,
        popper: popperVariation, 
        contextMenu: withContextMenuVariation(popperVariation)       
      }
    })
  ],
  exports: [
    TippyModule,
    TooltipComponent,
    TooltipDirective
  ]
})
export class TooltipModule { }

import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { DataRequestStatus } from 'src/app/shared/interfaces/data-request-status';
import { ResourceSummary, WellResource } from 'src/app/shared/services/api-calls-interfaces';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';

@Injectable({
  providedIn: 'root'
})
export class SideMenuDataService {

  private sideMenuMessageSubject = new ReplaySubject<DataRequestStatus>(1);
  sideMenuMessageSubject$ = this.sideMenuMessageSubject.asObservable();
  defaultStatus: DataRequestStatus = {status: 'Loading', message: 'Loading'};
  private sideMenuDataSubject = new ReplaySubject<SideMenuItem>(1);
  sideMenuDataObs$ = this.sideMenuDataSubject.asObservable();
  private sideMenuTypeSubject = new ReplaySubject<string>(1)
  sideMenuTypeObs$ = this.sideMenuTypeSubject.asObservable();
  cancelSubs$: Subject<void> = new Subject<void>();
  destroySubs$: Subject<null> = new Subject<null>();

  constructor(private apiCallService: ApiCallsService) { }

  getSideMenuData(id: number, index: number, heirachy: string[]): void {
    this.sideMenuMessageSubject.next({
      status: 'Loading',
      message: 'Loading Menu'
    });

    let pluralAddition = 's';
    const type = heirachy[index].toLowerCase();

    if(type.slice(-1) === 's'){
      pluralAddition = 'es';
    }

    const typePlural = type + pluralAddition;

    this.apiCallService.getDetailedStatus(typePlural as WellResource, id)
      .subscribe({
        next: (data: SideMenuData) => {
          const res = { data } as SideMenuItem;
          this.sideMenuDataSubject.next(res);
          this.sideMenuMessageSubject.next({
            status: 'Complete',
            message: 'Menu Complete'
          });

          this.sideMenuTypeSubject.next(this.getObjectKey(res.data));
        },
        error: err => {
          this.sideMenuMessageSubject.next({
            status: 'Error',
            message: 'Error',
            error: err
          });
        }
      })
  }

  getObjectKey(obj: SideMenuData): string{
    const key =  Object.keys(obj)[0];
    return key;
  }

  empty_current_side_menu(): void {
    this.cancelSubs$.next();
    this.sideMenuMessageSubject.next(this.defaultStatus);
    this.sideMenuDataSubject.next(null);
    this.sideMenuTypeSubject.next(null);
  }
}

export type SideMenuItem = { data: ResourceSummary };

export type SideMenuData = ResourceSummary;

import {
  AllBuildingResponse,
  AllCampusResponse,
  AllDeviceResponse,
  AllFloorResponse,
  AllPortfolioResponse,
  AllRoomsResponse,
  UpdateCreateBuildingItemResponse,
} from "../../modules/deployment/services/deployment-api.service";
import {
  AnonymiseDataService,
  numberFromName,
  numberFromOneCharture,
  singleDigitFrom
} from "./anonymise-data.service";
import {
  AnsweredSurvey,
  BuildingSurveys,
  BuildingSurveysListResponse,
  BuildingSurveySummary,
  LockChangeResponse,
} from "../../modules/spaces/data/building-data.service";
import {
  ApiTokenItem,
  ApiTokensResponse,
  BuildingSurveySettingsResponse,
  CreateApiTokenResponse,
  anonymousDeviceHash,
  anonymousRoomHash,
} from "../interfaces/shared-services-interfaces";
import {
  AsyncWrapInterface,
  EnvironmentFeedbackTicket,
  FacilityFeedbackTicket,
  FeedbackTicketItem,
  FloorStandardAllResponse,
  FloorStandardGroup,
  FloorStandardResponse,
  GetReadingsResponse,
  GroupedReadingResponse,
  PostTicketResponse,
  ReadingTypes,
  ResourceInput,
  RoomDeviceReadings,
  RoomWithEdges,
  RoomsListEdgesResponse,
  deviceSingleReadingWithRange,
  getDataRangeResponse,
  getResourceURL,
  getResourceURLParams,
  WellnessScoreResponse,
  ScoreDataTrend,
  ScoreTrendResponse,
  ParamsInput,
  WellResource,
  ResourceSummary,
  PlatformStatusI,
  ScheduleSurveyPayload,
} from "./api-calls-interfaces";
import {
  AvailableResourcesResponse,
  GetTicketsResponse,
  ResourceStatus,
  ResourceStatusAll,
  ResourceStatusAllStatus,
  ResourceStatusAllStatusObs,
  ResourceStatusGroup,
  ResourceStatusResponse,
  WorstCategoryInput,
} from "../interfaces/data-interfaces";
import {
  BuildingItem,
  CampusItem,
  DeviceItem,
  DeviceItemWithHealth,
  FloorItem,
  PortfolioItem,
  RoomItem,
  WellI,
  WorkingTimeGroup,
} from "../interfaces/interfaces.shared";
import {
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  iif,
  of,
  throwError,
  EMPTY,
} from "rxjs";
import {
  ResourceStatusEnum,
  ResourceTypes,
} from "../../pipes/resource-status/resource-status.pipe";
import {
  catchError,
  concatAll,
  concatMap,
  delay,
  map,
  mergeMap,
  retryWhen,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { AlertResponseItem } from "../../modules/spaces/data/alert-api.service";
import { DataRequestStatus } from "../interfaces/data-request-status";
import { UntypedFormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginDataService } from "../../modules/login/services/login-data.service";
import { StandardItem } from "src/app/modules/spaces/2-building/building-settings-tab/standards-settings/standards-api.service";
import { TicketsReturnItem } from "../../modules/spaces/data/ticket-api.service";
import {
  PortfolioTiers,
  PortfolioTiersData,
  WellnessItem
} from "src/app/modules/spaces/data/tree-view-data.service";
import { environment } from "../../../environments/environment";
import { parameterLimits } from "../../modules/spaces/2-building/building-settings-tab/standards-settings/standard-histogram/standard-histogram.component";
import { tableCompare } from "./table-functions.shared";
import Utils from "../utils/utils";
import { SensorHealthDevice, SensorHealthResponse } from "src/app/modules/operations/operations-api.service";

@Injectable({
  providedIn: "root",
})
export class ApiCallsService {
  constructor(
    private loginDataService: LoginDataService,
    private httpClient: HttpClient,
    private anon: AnonymiseDataService
  ) {}

  getAllApiTokens(): Observable<ApiTokenItem[]> {
    const url = `${environment.apiUrl}/api_tokens`;
    return this.httpClient
      .get<ApiTokensResponse>(url)
      .pipe(map((data) => data.data.api_tokens));
  }

  createNewApiToken(description: string): Observable<ApiTokenItem> {
    const url = `${environment.apiUrl}/api_tokens`;
    return this.httpClient
      .post<CreateApiTokenResponse>(url, {
        api_access_token: {
          description,
        },
      })
      .pipe(map((data) => data.data.api_token));
  }

  getPlatformStatus(): Observable<PlatformStatusI> {
    const url = `${environment.mainUrl}/check?format=json`;
    return this.httpClient.get<PlatformStatusI>(url)
      .pipe(catchError(err => {
        return of(err.error as PlatformStatusI);
      }));
  }

  getResource<T extends WellI>(resources: ResourceInput, params?: ParamsInput): Observable<T> {
    
    if (
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id &&
      !resources.device_id
    ) {
      throwError(() => "Need at least one resource ID");
    }

    const url = getResourceURLParams(resources, "", params);

    return this.httpClient.get<{ data: { [key: string]: T }}>(url)
      .pipe(
        map(res => {
          const key = Object.keys(res.data)[0];
          return res.data[key];
        })
      );
  }

  setSurveyLock(
    siteId: number,
    surveyId: number,
    lock: boolean
  ): Observable<AnsweredSurvey> {
    const lockEndpoint = lock ? "lock" : "unlock";
    return this.httpClient
      .put<LockChangeResponse>(
        `${environment.apiUrl}/sites/${siteId}/surveys/${surveyId}/${lockEndpoint}`,
        {}
      )
      .pipe(map((data) => data.data.survey));
  }

  getBuildingSurveys(buildingId: number): Observable<AnsweredSurvey[]> {
    return this.httpClient
      .get<BuildingSurveysListResponse>(
        `${environment.apiUrl}/sites/${buildingId}/surveys`
      )
      .pipe(map((data) => data.data.surveys));
  }

  getBuildingSurveysSettings(buildingId: number): Observable<BuildingSurveys> {
    return this.httpClient
      .get<BuildingSurveySettingsResponse>(
        `${environment.apiUrl}/sites/${buildingId}/surveys/settings`
      )
      .pipe(
        map((data) => {
          data.data.surveys.user.sort((a, b) =>
            tableCompare(a.label, b.label, true)
          );
          data.data.surveys.manager.sort((a, b) =>
            tableCompare(a.label, b.label, true)
          );
          return data.data.surveys;
        })
      );
  }

  getBuildingSurveySchedulingSummary(buildingId: number): Observable<BuildingSurveySummary> {
    const url = `${environment.apiUrl}/sites/${buildingId}/surveys/scheduling_summary`;

    return this.httpClient.get<{ data: { surveys: BuildingSurveySummary } }>(url)
      .pipe(map(res => res.data.surveys));
  }

  updateSurveyStatus(
    buildingId: number,
    arcId: number,
    surveyKey: string,
    value: boolean
  ): Observable<BuildingItem> {
    const payload = {
      site: {
        arc_auth_attributes: {
          id: arcId,
        },
      },
    };
    payload.site.arc_auth_attributes[surveyKey] = value;
    return this.httpClient
      .put<UpdateCreateBuildingItemResponse>(
        `${environment.apiUrl}/sites/${buildingId}`,
        payload
      )
      .pipe(map((data) => data.data.site));
  }

  saveBuildingWorkingTime(
    input: UntypedFormGroup,
    buildingId: number,
    status?: Subject<DataRequestStatus>
  ): Observable<WorkingTimeGroup> {
    if (status) {
      status.next({
        status: "Loading",
        message: "Loading Working Time",
      });
    }
    return this.httpClient
      .put<BuildingWorkingHourUpdateResponse>(
        `${environment.apiUrl}/sites/${buildingId}/working_time`,
        { working_time: input.value }
      )
      .pipe(
        map((data) => data.data.working_time),
        tap((data) => {
          if (status) {
            status.next({
              status: "Complete",
              message: "Updated Working Time",
            });
          }
        })
      );
  }

  getBuildingRoomNamesWithIdKey(
    building_id: number
  ): Observable<{ [key: string]: string }> {
    const url = getResourceURL({ building_id }, "floors");
    return this.httpClient.get<AllFloorResponse>(url).pipe(
      switchMap((floorResponse: AllFloorResponse) => {
        const callsToMake: Observable<RoomWithEdges[]>[] = [];
        for (const floor of floorResponse.data.floors) {
          const roomUrl = getResourceURL({ floor_id: floor.id }, "rooms");
          const roomsAtFloor: Observable<RoomWithEdges[]> = this.httpClient
            .get<RoomsListEdgesResponse>(roomUrl)
            .pipe(map((data) => data.data.rooms));
          callsToMake.push(roomsAtFloor);
        }
        return combineLatest(callsToMake).pipe(
          map((data: RoomWithEdges[][]) => {
            let allRoomEdges: RoomWithEdges[] = [];
            for (const roomList of data) {
              allRoomEdges = allRoomEdges.concat(...roomList);
            }
            const roomIds: { [key: string]: string } = {};
            for (const room of allRoomEdges) {
              roomIds[room.id] = room.label;
            }
            return roomIds;
          })
        );
      })
    );
  }

  getBuildingStandards(building_id: number): Observable<FloorStandardGroup> {
    
    return combineLatest([
      this.httpClient.get<FloorStandardResponse>(
        `${environment.apiUrl}/sites/${building_id}/reading_standards`
      ),
      this.httpClient.get<FloorStandardAllResponse>(
        `${environment.apiUrl}/reading_standards`
      ),
    ]).pipe(
      map((res: [FloorStandardResponse, FloorStandardAllResponse]) => {
        for (const key of Object.keys(res[0].data)) {
          // could maybe use indexing if I could assure that v1/reading_standards always returns in same order / no missing indexes
          // similarily, could re-assign to a indexed hash table but unlikely it'll get heavy enough to justify rework
          const standard = res[0].data[key];
          if (!standard) {
            continue;
          }
          if (standard.LTA === undefined) {
            const replacement = res[1].data[key]?.find(
              (x) => (x.id = standard.reading_standard_id)
            );
            if (replacement) {
              standard.LTA = replacement?.LTA;
              standard.UTA = replacement?.UTA;
              standard.LTI = replacement?.LTI;
              standard.UTI = replacement?.UTI;
            }
          }
        }
        return res[0].data;
      })
    );
  }

  getCampusData(campus_id: number): Observable<CampusItem> {
    return this.getResource<CampusItem>({ campus_id })
      .pipe(
        switchMap(campus =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              if (user?.anonymous) {
                campus.label = `Campus ${numberFromName(campus.label)}`;
              }
              return campus;
            })
          )
        )
      );
  }

  getFloorData(floor_id: number): Observable<FloorItem> {
    return this.getResource<FloorItem>({ floor_id })
      .pipe(
        switchMap(floor =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              if (user?.anonymous) {
                floor.label = `Floor ${numberFromOneCharture(floor.label)}`
              }
              return floor;
            })
          )
        ),
        map((floor) => {
          if (floor.background_image_properties) {
            floor.background_image_properties.x_offset = Number(
              floor.background_image_properties.x_offset
            );
            floor.background_image_properties.y_offset = Number(
              floor.background_image_properties.y_offset
            );
            floor.background_image_properties.xy_scale = Number(
              floor.background_image_properties.xy_scale
            );
          }
          return floor;
        })
      );
  }

  getFloorRoomsEdgesData(floor_id: number): Observable<RoomWithEdges[]> {
    return this.httpClient
      .get<RoomsListEdgesResponse>(
        `${environment.apiUrl}/floors/${floor_id}/rooms/edges`
      )
      .pipe(
        map((data) =>
          data.data.rooms.sort((a, b) =>
            a.label.toLowerCase() === "outline"
              ? b.label.toLowerCase() === "outline"
                ? -1
                : -1
              : 1
          )
        )
      );
  }

  getReadingsAtResource(
    from: number,
    to: number,
    resources: ResourceInput,
    parameters?: string[]
  ): Observable<GetReadingsResponse> {
    if (
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id &&
      !resources.device_id
    ) {
      throwError("Need at least one resource ID for get readings");
    }
    const url = getResourceURL(
      resources,
      "readings",
      from - 1,
      to + 1,
      parameters
    );
    return this.httpClient.get<GetReadingsResponse>(url);
  }

  getGroupedReadingsAtResource(
    from: number,
    to: number,
    resources: ResourceInput,
    page: number = 1,
    page_size: number = 4096,
  ): Observable<TimedGroupedReadingResponse> {
    if (
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id &&
      !resources.device_id
    ) {
      throwError("Need at least one resource ID for get readings");
    }
    const url = getResourceURL(resources, "readings", from - 1, to + 1, [
      "grouped_by_devices=true",
      `page=${page}`,
      "request_cache=true",
      `per_page=${page_size}`
    ]);
    return this.httpClient.get<GroupedReadingResponse>(url).pipe(
      map((data) => {
        return ({
        data,
        fromTime: from,
        toTime: to,
      })}
   
      )
    );
  }

  getDataRangeAtResource(
    resources: ResourceInput
  ): Observable<getDataRangeResponse> {
    if (
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id &&
      !resources.device_id
    ) {
      throwError("Need at least one resource ID for get readings");
    }
    const url = getResourceURL(resources, "readings/data_range");
    return this.httpClient
      .get<getDataRangeResponse>(url)
      .pipe(map((res: getDataRangeResponse) => res));
  }

  getBuildingData(building_id: number): Observable<BuildingItem> {
    return this.getResource<BuildingItem>(
      { building_id },
      { parameters: ['include_building_area_from_arc=true']}
    )
    .pipe(
      switchMap(building =>
        this.loginDataService.getUserObs().pipe(
          take(1),
          map((user) => {
            if (user?.anonymous) {
              building.label = `Building ${singleDigitFrom(building.label)}`;
            }
            return building;
          })
        )
      )
    );
  }

  getPortfolioTiers(): Observable<PortfolioTiers> {
    const url = `${environment.apiUrl}/portfolios/authorized_tiers`;
    
    return this.httpClient
      .get<PortfolioTiersData>(url)
      .pipe(map(data => data.data.tiers));
  }

  getTreeViewData(): Observable<PortfolioTiers> {
    return this.getPortfolioTiers()
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map(user => {
              return this.traverseAnonymousTree(data, user?.anonymous);
            })
          )
        )
      );
  }

  // TODO: make this a bit more efficient
  private traverseAnonymousTree(tiers: PortfolioTiers, isAnonymous: boolean = false): PortfolioTiers {
    const portfolios = tiers;
    for (let i = 0; i < portfolios.length; i++) {
      const portfolio = portfolios[i].Portfolio;
      portfolio.isOpen = false;
      if (isAnonymous) {
        const portfolioName = this.anon.portfolioNameType(portfolio.label);
        const portfolioNumber = numberFromName(portfolio.label);
        portfolio.label =  `Company ${portfolioNumber} ${portfolioName}`;
      }

      for (let j = 0; j < portfolio.Campus.length; j++) {
        const campus = portfolio.Campus[j];
        campus.isOpen = false;
        let idString1: number;
        if (isAnonymous) {
          const campusNumber = numberFromName(campus.label);
          idString1 = campusNumber;
          campus.label = 'Campus ' + idString1;
        }

        for (let k = 0; k < campus.Site.length; k++) {
          const site = campus.Site[k];
          site.isOpen = false;
          let idString2: string;
          if (isAnonymous && idString1 !== undefined) {
            const siteNumber = singleDigitFrom(site.label);
            idString2 = idString1 + '-' + siteNumber;
            site.label = "Building " + idString2;
          }

          for (let l = 0; l < site.Floor.length; l++) {
            const floor = site.Floor[l];
            if (isAnonymous && idString2 !== undefined) {
              floor.label = "Floor " + idString2 + '-' + numberFromOneCharture(floor.label);
            }
          }
        }
      }
    }

    return portfolios;
  }

  getWellnessScore(resources: ResourceInput): Observable<WellnessItem> {
    if (
      !resources.portfolio_id &&
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id
    ) {
      return throwError(() => new Error("Need at least one resource ID to get readings"));
    }

    const url = getResourceURLParams(resources, "wellness_score");
    return this.httpClient
      .get<WellnessScoreResponse>(url)
      .pipe(map(res => res.data.wellness_score));
  }

  getScoreDataTrend(resources: ResourceInput, opts?: ParamsInput): Observable<ScoreDataTrend> {

    if (
      !resources.portfolio_id &&
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id
    ) {
      return throwError(() => new Error("Need at least one resource ID to get data"));
    }

    const url = getResourceURLParams(resources, "wellness_scores", opts);

    return this.httpClient.get<ScoreTrendResponse>(url).pipe(
      map(res => res.data.scores)
    )
  }

  getDetailedStatus(endpoint: WellResource, id: number): Observable<ResourceSummary> {
    const url = `${environment.apiUrl}/${endpoint}/${id}/detailed_status`;
    
    return this.httpClient.get<{ data: ResourceSummary }>(url)
      .pipe(map(data => data.data));
  }

  getDeviceData(deviceId: number): Observable<DeviceItem> {
    return this.httpClient
      .get<{ data: { device: DeviceItem } }>(
        `${environment.apiUrl}/devices/${deviceId}`
      )
      .pipe(map((data) => data.data.device));
  }

  getDeviceStandards(deviceId: number): Observable<FloorStandardGroup> {
    return this.httpClient
      .get<FloorStandardResponse>(
        `${environment.apiUrl}/devices/${deviceId}/reading_standards`
      )
      .pipe(
        map((data) => {
          if (!data || !data.data || !Object.keys(data.data).length) {
            throw new Error("Device not configured - no standards attached");
          }
          applyFailThresholdToStandardGroup(data.data);

          return data.data;
        })
      );
  }

  getFloorStandards(floorId: number): Observable<FloorStandardGroup> {
    return this.httpClient
      .get<FloorStandardResponse>(
        `${environment.apiUrl}/floors/${floorId}/reading_standards`
      )
      .pipe(
        map((data) => {
          if (!data || !data.data || !Object.keys(data.data).length) {
            throw new Error("Floor not configured - no standards attached");
          }
          applyFailThresholdToStandardGroup(data.data);

          return data.data;
        })
      );
  }

  getLatestDeviceReading(
    deviceId: number
  ): Observable<deviceSingleReadingWithRange> {
    return this.getDataRangeAtResource({ device_id: deviceId }).pipe(
      mergeMap((data) => {
        const toDate = data.data_range.maximum_date;
        const toTime = Utils.toUnixTimestamp(new Date(toDate)) + 1;
        const fromTime = Utils.toUnixTimestamp(new Date(toDate)) - 1;
        return this.getLatestDeviceReadingAtDate(
          deviceId,
          fromTime,
          toTime
        ).pipe(
          map((dataReadings) => ({
            readings: dataReadings,
            dateRange: data,
          }))
        );
      })
    );
  }

  getCurrentDeviceReading(
    deviceId: number,
    period: number = 24 * 60
  ): Observable<deviceSingleReadingWithRange> {
    return this.getDataRangeAtResource({ device_id: deviceId }).pipe(
      mergeMap((data) => {
        // ignore the date range from the endpoint, but still return it in order to see a "last reported" timestamp
        const toTime = Utils.toUnixTimestamp() + 1;
        const fromTime = Utils.toUnixTimestamp() - period * 60;
        return this.getLatestDeviceReadingAtDate(
          deviceId,
          fromTime,
          toTime
        ).pipe(
          map((dataReadings) => ({
            readings: dataReadings,
            dateRange: data,
          }))
        );
      })
    );
  }

  getCurrentFloorReading(
    floorId: number
  ): Observable<deviceSingleReadingWithRange> {
    return this.getDataRangeAtResource({ floor_id: floorId }).pipe(
      mergeMap((data) => {
        // ignore the date range from the endpoint, but still return it in order to see a "last reported" timestamp
        const toTime = Utils.toUnixTimestamp() + 1;
        const fromTime = Utils.toUnixTimestamp() - 300 * 60;
        return this.getLatestFloorReadingAtDate(floorId, fromTime, toTime).pipe(
          map((dataReadings) => ({
            readings: dataReadings,
            dateRange: data,
          }))
        );
      })
    );
  }

  getLatestDeviceReadingAtDate(
    deviceId: number,
    fromDate,
    toDate
  ): Observable<RoomDeviceReadings> {
    return this.getReadingsAtResource(fromDate, toDate, {
      device_id: deviceId,
    }).pipe(
      map((sensorData) => {
        const timeList = Object.keys(sensorData.data);
        if (!timeList.length) {
          return undefined;
        }
        // timeList.length-1 used for the last entry, as they come in chronological order
        sensorData.data[timeList[timeList.length - 1]].date = new Date(
          timeList[timeList.length - 1]
        );
        return sensorData.data[timeList[timeList.length - 1]];
      })
    );
  }

  getLatestFloorReadingAtDate(
    floorId: number,
    fromDate,
    toDate
  ): Observable<RoomDeviceReadings> {
    return this.getReadingsAtResource(fromDate, toDate, {
      floor_id: floorId,
    }).pipe(
      map((sensorData) => {
        const timeList = Object.keys(sensorData.data);
        if (!timeList.length) {
          return undefined;
        }
        // timeList.length-1 used for the last entry, as they come in chronological order
        sensorData.data[timeList[timeList.length - 1]].date = new Date(
          timeList[timeList.length - 1]
        );
        return sensorData.data[timeList[timeList.length - 1]];
      })
    );
  }

  postFeedbackTicketAtDevice(
    deviceId: number,
    ticket: EnvironmentFeedbackTicket | FacilityFeedbackTicket
  ): Observable<FeedbackTicketItem> {
    return this.httpClient
      .post<PostTicketResponse>(
        `${environment.apiUrl}/devices/${deviceId}/tickets`,
        { ticket }
      )
      .pipe(map((data) => data.data.ticket));
  }

  fillAllBuildingsIntoStream(
    updateStream: Subject<BuildingItem[]>,
    cancelStream: Observable<null>,
    messageStream: Subject<DataRequestStatus>
  ): Observable<BuildingItem[]> {
    messageStream.next({ status: "Loading", message: "Loading Sites" });
    return this.httpClient
      .get<AllBuildingResponse>(`${environment.apiUrl}/sites`)
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              const nextData = data.data.sites;
              updateStream.next(nextData);
              let toComplete = nextData.length;
              for (const site of data.data.sites) {
                if (user?.anonymous) {
                  site.label = `Building ${singleDigitFrom(site.label)}`;
                }
                this.getBuildingWellStatusAndTicketsCount(site)
                  .pipe(takeUntil(cancelStream))
                  .subscribe(() => {
                    if ((toComplete -= 1) === 0) {
                      messageStream.next({
                        status: "Complete",
                        message: "My Buildings",
                      });
                    }
                  });
              }

              return nextData;
            })
          )
        ),
        catchError((err) => {
          messageStream.next({
            status: "Error",
            message: "Error",
            error: err,
          });
          return throwError(err);
        })
      );
  }

  fillAllFloorsIntoStream(
    updateStream: Subject<FloorItem[]>,
    cancelStream: Observable<null>,
    messageStream: Subject<DataRequestStatus>
  ): Observable<FloorItem[]> {
    messageStream.next({ status: "Loading", message: "Loading Floors" });
    return this.httpClient
      .get<AllFloorResponse>(`${environment.apiUrl}/floors`)
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              messageStream.next({
                status: "Loading",
                message: "Loading Floors",
              });
              const nextData = data.data.floors;
              updateStream.next(nextData);
              let toComplete = nextData.length;
              for (const floor of nextData) {
                if (user?.anonymous) {
                  floor.label = `Floor ${numberFromOneCharture(floor.label)}`
                }
                const resource: ResourceInput = { floor_id: Number(floor.id) };
                combineLatest([
                  this.assignIsFloorWellValuesThenReturn(floor, resource),
                  this.getFloorTicketsCount(Number(floor.id)),
                ])
                  .pipe(takeUntil(cancelStream))
                  .subscribe((floorData) => {
                    floor.tickets = floorData[1];
                    if ((toComplete -= 1) === 0) {
                      messageStream.next({
                        status: "Complete",
                        message: "My Floors",
                      });
                    }
                  });
              }
              return nextData;
            })
          )
        ),
        catchError((err) => {
          messageStream.next({
            status: "Error",
            message: "Error",
            error: err,
          });
          return throwError(err);
        })
      );
  }

  getAllCampusesForPortfolio(): Observable<CampusItem[]> {
    return this.httpClient
      .get<AllCampusResponse>(
        `${environment.apiUrl}/campuses?last_alert=true&last_ticket=true&buildings_count=true`
      )
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              if (user?.anonymous) {
                for (const campus of data.data.campuses) {
                  campus.label = `Campus ${numberFromName(campus.label)}`
                  const portfolioName = this.anon.portfolioNameType(campus.portfolio.label);
                  const portfolioNumber = numberFromName(campus.portfolio.label);
                  campus.portfolio.label =  `Company ${portfolioNumber} ${portfolioName}`;
                }
              }
              return data.data.campuses;
            })
          )
        )
      );
  }

  getAllCampuses(): Observable<CampusItem[]> {
    return this.httpClient
      .get<AllCampusResponse>(`${environment.apiUrl}/campuses`)
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              if (user?.anonymous) {
                for (const campus of data.data.campuses) {
                  campus.label = `Campus ${numberFromName(campus.label)}`;
                }
              }
              return data.data.campuses.sort((a, b) => tableCompare(a.label, b.label));
            })
          )
        )
      );
  }

  getAllBuildingsAtCampus(campusId: string): Observable<BuildingItem[]> {
    return this.httpClient
      .get<AllBuildingResponse>(
        `${environment.apiUrl}/campuses/${campusId}/sites`
      )
      .pipe(map((data) => data.data.sites));
  }

  getBuildingWorkingTime(campusId: string): Observable<BuildingItem[]> {
    return this.httpClient
      .get<AllBuildingResponse>(
        `${environment.apiUrl}/campuses/${campusId}/sites`
      )
      .pipe(map((data) => data.data.sites));
  }

  getAllFloorsAtBuilding(siteId: string): Observable<FloorItem[]> {
    return this.httpClient
      .get<AllFloorResponse>(`${environment.apiUrl}/sites/${siteId}/floors`)
      .pipe(
        switchMap((data) =>
          this.loginDataService.getUserObs().pipe(
            take(1),
            map((user) => {
              if (user?.anonymous) {
                for (const floor of data.data.floors) {
                  floor.label = `Floor ${numberFromOneCharture(floor.label)}`;
                }
              }
              return data.data.floors;
            })
          )
        )
      );
  }

  getAllBuildings(): Observable<BuildingItem[]> {
    return this.httpClient
      .get<AllBuildingResponse>(`${environment.apiUrl}/sites`)
      .pipe(map((data) => data.data.sites));
  }

  getAllFloors(): Observable<FloorItem[]> {
    return this.httpClient
      .get<AllFloorResponse>(`${environment.apiUrl}/floors`)
      .pipe(map((data) => data.data.floors));
  }

  getAllRooms(
    campus: string,
    site?: string,
    floor?: string
  ): Observable<RoomItem[]> {
    let url = `${environment.apiUrl}/campuses/${campus}`;
    if (site && floor) {
      url += `/sites/${site}/floors/${floor}`;
    } else if (site) {
      url += `/sites/${site}`;
    }
    url += "/rooms";
    return this.httpClient
      .get<AllRoomsResponse>(url)
      .pipe(map((data) => data.data.rooms));
  }

  /**
   * Gets all the devices for the resource.
   * It can load more information to the devices, such as
   * the health status (though this could be quite expensive).
   * @param resource it can be any of the defined resources
   * @param showUnmapped flag that includes devices not mapped
   * @param includeHealthStatus flag that includes health status to devices
   * @returns 
   */
  getAllDevicesForResource(
    resource: ResourceInput,
    showUnmapped: boolean = false,
    includeHealthStatus: boolean = false
  ): Observable<DeviceItemWithHealth[]> {
    const url = getResourceURL(
      resource,
      "devices",
      undefined,
      undefined,
      showUnmapped ? ["include_unmapped_devices=true"] : undefined
    );

    return this.httpClient.get<AllDeviceResponse>(url).pipe(
      map(data => data.data.devices as DeviceItemWithHealth[]),
      switchMap(devices => {

        let health$: Observable<SensorHealthDevice[]>;

        if (includeHealthStatus) {
          health$ = this.httpClient
            .get<SensorHealthResponse>(
              getResourceURLParams(resource, 'devices/health', { per_page: 200 })
            )
            .pipe(map(data => data.data.devices));
        } else {
          health$ = of(null);
        }
        
        return this.getLatestHealthAndSort(devices, health$);
      })
    );
  }

  /**
   * Returns the combination of the devices and their health status.
   * @param devices 
   * @param health$ 
   * @returns 
   */
  private getLatestHealthAndSort(
    devices: DeviceItemWithHealth[],
    health$: Observable<SensorHealthDevice[]>
  ) {
    return combineLatest([
      health$,
      this.loginDataService.getUserObs()
    ]).pipe(
      take(1),
      map(([health, user]) => {

        if (user?.anonymous) {
          applyFakeNamesToDeviceList(devices);
        }

        devices.sort((a, b) => tableCompare(a.label, b.label));

        if (health) {
          const healthMap = new Map(health.map(h => ([h.id, h])))
          devices.forEach(d => d.health = healthMap.get(d.id));
        }

        return devices;
      })
    );
  }

  getAllPortfolios(): Observable<PortfolioItem[]> {
    return this.httpClient
      .get<AllPortfolioResponse>(`${environment.apiUrl}/portfolios`)
      .pipe(map((data) => data.data.portfolios));
  }

  getBuildingWellStatusAndTicketsCount(
    site: BuildingItem
  ): Observable<BuildingItem> {
    const resource: ResourceInput = {
      building_id: site.id,
      campus_id: site.campus_id,
    };
    return combineLatest([
      this.isBuildingWell(site, resource),
      this.getBuildingTicketsCount(Number(site.id)),
    ]).pipe(
      map((data: [BuildingItem, number]) => {
        data[0].tickets = data[1];
        return data[0];
      })
    );
  }

  getFloorTicketsCount(floor_id: number): Observable<number> {
    return this.httpClient
      .get<GetTicketsResponse>(
        `${environment.apiUrl}/floors/${floor_id}/tickets`
      )
      .pipe(map((res) => res.data.length));
  }

  isBuildingWell(
    site: BuildingItem,
    resource: ResourceInput
  ): Observable<BuildingItem> {
    return this.getAllCategoryStatus(resource).pipe(
      map((value) => {
        site.statuses = value;
        site.comfort = ResourceStatusEnum[value.comfort.status]?.valueOf();
        site.air_quality =
          ResourceStatusEnum[value.air_quality.status]?.valueOf();
        site.ventilation =
          ResourceStatusEnum[value.ventilation.status]?.valueOf();
        site.well = this.isBuildingWellOrNot(site);
        site.wellValue = site.comfort + site.air_quality + site.ventilation;
        return site;
      })
    );
  }

  getAllCategoryStatus(
    resource: ResourceInput
  ): Observable<ResourceStatusGroup> {
    return this.getAllCategoryStatuses(resource).pipe(
      map((data) => {
        const toReturn: ResourceStatusGroup = {
          out_of_hours:
            data.air_quality.status ===
              ResourceStatusEnum[ResourceStatusEnum.out_of_hours] ||
            data.comfort.status ===
              ResourceStatusEnum[ResourceStatusEnum.out_of_hours] ||
            data.ventilation.status ===
              ResourceStatusEnum[ResourceStatusEnum.out_of_hours],
          air_quality: data.air_quality,
          comfort: data.comfort,
          ventilation: data.ventilation,
        };
        return toReturn;
      })
    );
  }

  createResourceStatusObservableArrayInfo(resource:ResourceInput, parameters: string[]): [ResourceStatusAllStatusObs, boolean]{
    const availParameters: ResourceStatusAllStatusObs = {};
//while we are getting an endpiont with available parameters it contains more that what we actually display hence this hardcode list
    const displayParameters = [
      "temperature",
      "humidity",
      "co2",
      "pm25",
      "pm10",
      "pm01",
      "pm04",
      "tvoc",
      "formaldehyde",
      "als",
      "current",
      "lighting"
    ]

    let hasCurrent: boolean;

     for (let step = 0; step < parameters.length; step++ ){
       if(displayParameters.find(parameter => parameter === parameters[step]) ) {
         if(parameters[step] !== 'current' ){
          availParameters[parameters[step]] = this.getCategoryCall(resource, parameters[step])
         } else {
           hasCurrent = true;
         }
       }
     }
     availParameters['standards']= this.getBuildingStandards(resource.building_id);
    
     return [availParameters, hasCurrent];
  }

  transformResourceStatus(data: ResourceStatusAllStatus, hasCurrent: boolean ): ResourceStatusAll{
   //Now we have all the data that we need, the point of this function is to include all the resources that we need and simplify the data to 
   //only show the resource and the status.

    //As current nas no standards the resource status endpoint does not work but if there are sensors detecting current then we need to
    //add it to the returned data
    if(hasCurrent){
      data.current = {parameter: 'current', status: 'pass'}
    };
           
    //As pm is made up of the four pm sizes we need to get the overall pm (i.e. the worst) status
       const pmResourceStatus: ResourceStatus = this.getPmResourceStatus(
         data.standards,
         [data.pm01, data.pm04, data.pm25, data.pm10]
       );

// we now need to calculate the status of the Categories 1.starting with the data that we have from the endpoint
       const getWorstData: WorstCategoryInput = {
         air_quality: [data.tvoc, data.formaldehyde],
         comfort: [data.temperature, data.humidity],
         ventilation: [data.co2],
         energy: [data.current]
       };

// 2.adding the calculated overall pm status
       if (pmResourceStatus) {
         getWorstData.air_quality = [...getWorstData.air_quality, pmResourceStatus]
         data.pm = pmResourceStatus.status;
       } else {
         data.pm = data.pm01?.status ? data.pm01.status : 'blank_state';
       }  
  //3.removing and statses that are undefined   
       for (const [key, value] of Object.entries(getWorstData)) {
        for (let step = 0; step < value.length; step++ ){
          if(!value[step]){
            value.splice(step, 1);
          } 
        }
      }

//4. get the worst status for each category
       for (const category in getWorstData) {
         data[category] = this.getSingleWorstCategoryStatus(
           getWorstData[category]
         );
       }

// delete standards as it is no longer needed
       delete data.standards;

// simplify each resource to only have the status
       for (const type of ReadingTypes) {
         if(data[type]){
           data[type] = data[type].status;
         }
   
       }
      
// return as the new data type.
      return data as unknown as ResourceStatusAll;
  }

  getAllCategoryStatuses(
    resources: ResourceInput
  ): Observable<ResourceStatusAll> {

   const toReturn = this.getAvailableResources(resources).pipe(
    map((list) =>{

     const [object, hasCurrent] = this.createResourceStatusObservableArrayInfo(resources, list);
     const innerReturnforkJoin: Observable<ResourceStatusAllStatus> = forkJoin(object);

     return innerReturnforkJoin.pipe(
       map((innerReturn)=> this.transformResourceStatus(innerReturn, hasCurrent))
     )
    }),
    concatAll()
    )
    return toReturn;
  }

  getPmResourceStatus(
    standards: FloorStandardGroup,
    resourceStatuses: ResourceStatus[]
  ): ResourceStatus {
    resourceStatuses = resourceStatuses.filter((x)=> {return x !== undefined});
    return resourceStatuses.find((pmResourceStatus) =>
      this.checkPmStandards(standards[pmResourceStatus.parameter])
    );
  }


  checkPmStandards(standard: StandardItem | null): boolean {
    if (standard) {
      return !(
        standard.LTA == null &&
        standard.LTI == null &&
        standard.UTA == null &&
        standard.UTI == null
      );
    } else {
      return false;
    }
  }

  getSingleWorstCategoryStatus(categoryStatuses: ResourceStatus[]) {
    const allResults: ResourceStatusAll = {};
    let worstResult: ResourceTypes;
    let worstValue: number = Number.POSITIVE_INFINITY;
    let worstParameter: string;
    for (const result of categoryStatuses) {
      const parameterKey = result?.parameter;
      allResults[parameterKey] = result?.status;
      if (ResourceStatusEnum[result?.status] < worstValue) {
        worstResult = result.status as ResourceTypes;
        worstParameter = result.parameter;
        worstValue = ResourceStatusEnum[result.status];
      }
    }
    // strong typing from return type not happening so force it
    const toReturn: ResourceStatus = {
      status: worstResult,
      parameter: worstParameter,
      data: allResults,
    };
    return toReturn;
  }

  getCategoryCall(
    resources: ResourceInput,
    parameter: string
  ): Observable<ResourceStatus> {
    if (
      !resources.campus_id &&
      !resources.building_id &&
      !resources.floor_id &&
      !resources.device_id
    ) {
      throwError("Need at least one resource ID for get readings");
    }
    const url = getResourceURL(
      resources,
      `resource_status?parameter=${parameter}`
    );
    return this.httpClient.get<ResourceStatusResponse>(url).pipe(
      map((data) => {
        const toReturn = { parameter, status: data.data.status, data: {} };
        toReturn.data[parameter] = data.data.status;
        return toReturn;
      })
    );
  }

  getBuildingAvailableResources(buildingId: number) {
    const url = getResourceURL({ building_id: buildingId},
      'expected_reading_kinds'
    );
    return this.httpClient.get<AvailableResourcesResponse>(url).pipe(
      map((data) => {
       
        return data.data.expected_reading_kinds;
      })
    );
  }

  getAvailableResources(
    resources: Pick<ResourceInput, 'floor_id'>
  ): Observable<string[]> {
    if (!resources.floor_id) {
      console.error("ERROR: Need floor id for get available Resources");
      return EMPTY;
    }

    const resourcesFloorIdOnly = { floor_id: resources.floor_id }
    const url = getResourceURL(resourcesFloorIdOnly,
      'expected_reading_kinds'
    );
    return this.httpClient.get<AvailableResourcesResponse>(url).pipe(
      map((data) => {

        return data.data.expected_reading_kinds;
      })
    );
  }

  getBuildingTicketsCount(site_id: number): Observable<number> {
    return this.httpClient
      .get<GetTicketsResponse>(`${environment.apiUrl}/sites/${site_id}/tickets`)
      .pipe(map((res) => res.data.length));
  }

  isBuildingWellOrNot(site: BuildingItem): boolean {
    const passingStatuses = ["pass", "out_of_hours"];
    return (
      passingStatuses.includes(site.statuses.ventilation.status) &&
      passingStatuses.includes(site.statuses.comfort.status) &&
      passingStatuses.includes(site.statuses.air_quality.status)
    );
  }

  assignIsFloorWellValuesThenReturn(
    floor: FloorItem,
    resource: ResourceInput
  ): Observable<FloorItem> {
    return this.getAllCategoryStatus(resource).pipe(
      map((value: ResourceStatusGroup) => {
        floor.statuses = value;
        floor.comfort = ResourceStatusEnum[value.comfort.status]?.valueOf();
        floor.air_quality =
          ResourceStatusEnum[value.air_quality.status]?.valueOf();
        floor.ventilation =
          ResourceStatusEnum[value.ventilation.status]?.valueOf();
        floor.well = this.isFloorWellOrNot(floor);
        floor.wellValue = floor.comfort + floor.air_quality + floor.ventilation;
        return floor;
      })
    );
  }

  isFloorWellOrNot(site: FloorItem): boolean {
    const passingStatuses = ["pass", "out_of_hours"];
    return (
      passingStatuses.includes(site.statuses.ventilation.status) &&
      passingStatuses.includes(site.statuses.comfort.status) &&
      passingStatuses.includes(site.statuses.air_quality.status)
    );
  }

  scheduleNotificationsForBuilding(buildingId: number, payload: ScheduleSurveyPayload): Observable<BuildingItem> {
    return this.httpClient
      .put<UpdateCreateBuildingItemResponse>(
        `${environment.apiUrl}/sites/${buildingId}`,
        { site: payload }
      )
      .pipe(map((data) => data.data.site));
  }
}

export function applyFailThresholdToStandardGroup(
  standard: FloorStandardGroup
): void {
  // Parag Rastogi 24 Apr at 16:38 (edited)
  // @robbo25
  //
  // I think we discussed attaching standards some weeks ago. The compromise was to roll out with one fixed standard set, and then later allow people to choose which standard they want to set.
  //
  // To your question about ranges, I like the min-max approach and remember it now. Let me modify my statements above as follows. Note that I've changed several of the mins to maxes and simplified things.
  //
  // Co2 lower = 400
  // Temp lower = max(0, roundup(1.5*(Inv_high - Inv_low),1) )
  // Rest is 0
  //
  // Temp upper = max(35, roundup(1.5(Inv_high - Inv_low),1) )
  // Humidity upper = 100
  // Lighting upper = min(2000, roundup(1.5(Inv_high - Inv_low), 100) )
  // Co2 upper = roundup(1.25 investigate upper, 100)
  // PM upper = roundup(1.25 investigate upper, 10)
  // TVOC upper = roundup(1.25 investigate upper, 10)
  if (standard.co2) {
    standard.co2.LTF = 400;
    standard.co2.UTF = standard.co2.UTI * 1.25;
  }
  if (standard.temperature) {
    standard.temperature.LTF = Math.ceil(
      Math.max(
        0,
        standard.temperature.LTI -
          (standard.temperature.UTI - standard.temperature.LTI) * 1.5
      )
    );
    standard.temperature.UTF = Math.ceil(
      Math.min(
        35,
        standard.temperature.UTI +
          (standard.temperature.UTI - standard.temperature.LTI) * 1.5
      )
    );
  }
  if (standard.als) {
    standard.als.LTF = 0;
    standard.als.UTF = Math.ceil(
      Math.min(
        2000,
        standard.als.UTI + (standard.als.UTI - standard.als.LTI) * 1.5
      )
    );
  }
  if (standard.humidity) {
    standard.humidity.LTF = 0;
    standard.humidity.UTF = 100;
  }
  if (standard.pm01) {
    standard.pm01.LTF = 0;
    standard.pm01.UTF = Math.max(
      standard.pm01.UTI * 1.25,
      parameterLimits.pm01.max
    );
  }
  if (standard.pm04) {
    standard.pm04.LTF = 0;
    standard.pm04.UTF = Math.max(
      standard.pm04.UTI * 1.25,
      parameterLimits.pm04.max
    );
  }
  if (standard.pm10) {
    standard.pm10.LTF = 0;
    standard.pm10.UTF = Math.max(
      standard.pm10.UTI * 1.25,
      parameterLimits.pm10.max
    );
  }
  if (standard.pm25) {
    standard.pm25.LTF = 0;
    standard.pm25.UTF = Math.max(
      standard.pm25.UTI * 1.25,
      parameterLimits.pm25.max
    );
  }
  if (standard.tvoc) {
    standard.tvoc.LTF = 0;
    standard.tvoc.UTF = Math.max(
      standard.tvoc.UTI * 1.25,
      parameterLimits.tvoc.max
    );
  }
  if (standard.formaldehyde) {
    standard.formaldehyde.LTF = 0;
    standard.formaldehyde.UTF = Math.max(
      standard.formaldehyde.UTI * 1.25,
      parameterLimits.formaldehyde.max
    );
  }
}

export function asyncWrap<T>(
  input: Observable<T>,
  retries = 2,
  delayPeriod = 1000,
  optional = false
): AsyncWrapInterface<T> {
  const stream = optional ? input.pipe(startWith(of(undefined))) : input;
  return stream.pipe(
    map((data) => ({ data: data as T, error: null })),
    retryWhen((error) =>
      error.pipe(
        concatMap((e, i) =>
          iif(
            () => i >= retries,
            throwError(e),
            of(e).pipe(delay(delayPeriod * i))
          )
        )
      )
    ),
    catchError((error) => {
      console.log("HTTP AW error", error);
      return of({ data: null as T, error });
    })
  );
}

export interface TimedGroupedReadingResponse {
  data: GroupedReadingResponse;
  fromTime: number;
  toTime: number;
}

export interface BuildingWorkingHourUpdateResponse {
  data: {
    working_time: WorkingTimeGroup;
  };
}

export const applyFakeNamesToTicketOrAlertList = (
  input: TicketsReturnItem[] | AlertResponseItem[]
): void => {
  for (const alert of input) {
    alert.campus.label = `Campus ${numberFromName(alert.campus.label)}`;
    alert.site.label = `Building ${singleDigitFrom(alert.site.label)}`;
    alert.floor.label = `Floor ${numberFromOneCharture(alert.floor.label)}`;
};
};

export const applyFakeNamesToDeviceList = (devices: DeviceItem[]): void => {
  let start = 1;
  let startRoom = 1;
  for (const device of devices) {
    if (!anonymousRoomHash[device.room_id]) {
      anonymousRoomHash[device.room_id] = `Room ${startRoom}`;
      startRoom += 1;
    }
    if (!anonymousDeviceHash[device.id]) {
      anonymousDeviceHash[device.id] = `Device ${start}`;
      start += 1;
    }
    device.label = anonymousDeviceHash[device.id];
  }
};

<div class="standard_histogram_parent">
  <div class="standard_histogram_graph">
    <ng-container *ngFor="let less of viewLess; let i = index;">
      <svg height="14px" width="14px" class="view_less" viewbox="0 0 21 14" [style.z-index]="100-((viewLess.length-1)-i)" [style.left.px]="-14-((viewLess.length-1)-i)*7" [tippy]="less.tooltip">
        <path [attr.fill]="less.colour" d="M14 0 L14 14 L7 14 L0 7 L 7 0"></path>
      </svg>
    </ng-container>
    <ng-container *ngFor="let piece of histogram">
      <div
        [class]="piece.colour+' histogram_bar'"
        [style.left.%]="piece.left"
        [style.width.%]="piece.width"
      ></div>
    </ng-container>
    <ng-container *ngFor="let more of viewMore; let i = index;">
      <svg height="14px" width="14px" class="view_more" viewbox="0 0 21 14" [style.z-index]="100-i" [style.right.px]="-14-i*7" [tippy]="more.tooltip">
        <path [attr.fill]="more.colour" d="M0 0 L7 0 L14 7 L7 14 L0 14"></path>
      </svg>
    </ng-container>
  </div>
</div>



import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './modules/home/header/header.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {ApikeyInterceptor} from './guards/apikey.interceptor';
import {AccountDropdownComponent} from './shared/components/account-dropdown/account-dropdown.component';
import {createErrorHandlerFactory} from '@appsignal/angular';
import Appsignal from '@appsignal/javascript';
import {LoginDialogComponent} from './guards/login-dialog/login-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {LayoutModalContainerModule} from './shared/layout/layout-modal-container/layout-modal-container.module';
import {LoginModule} from './modules/login/login.module';
import {AuthGuard} from './guards/auth-guard.service';
import {LoginLogicService} from './modules/login/services/login-logic.service';
import {MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions} from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';


const appSignal = new Appsignal({
  key: 'bdf1297a-945b-4213-8cda-fc42630f592c'
});

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AccountDropdownComponent,
    LoginDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    MatMenuModule,
    MatDialogModule,
    LayoutModalContainerModule,
    LoginModule
  ],
  providers: [
    CookieService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (lls: LoginLogicService) => () => lls.preloadUserIntoApp(),
      deps: [LoginLogicService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApikeyInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useFactory: createErrorHandlerFactory(appSignal)
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

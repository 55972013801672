import {Component, Inject, OnInit} from '@angular/core';
import {LoginDataService} from '../../modules/login/services/login-data.service';
import {takeUntilComponent} from '../../shared/services/takeUntilComponent';
import { MatDialogRef } from '@angular/material/dialog';
import {delay} from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { LoginApiService } from 'src/app/modules/login/services/login-api.service';
import { LoginLogicService } from 'src/app/modules/login/services/login-logic.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent extends takeUntilComponent implements OnInit {

  constructor(
    public lds: LoginDataService,
    private lls: LoginLogicService,
    private las: LoginApiService,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) private inputData: {source: string; }
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(`${this.inputData.source} opened a login dialog`);

    if (this.cookieService.check('tempToken')) {
      const tempToken = this.cookieService.get('tempToken');
      this.lls.loginPreAuthenication(tempToken);
   }
   
    this.lds.setLoginDialogStatus(true);
    this.connectToLoginStream();
  }

  connectToLoginStream(): void {
    this.lds.getUserTokenObs().pipe(this.takeUntilDestroyed(), delay(100)).subscribe(
      user => {
        if (!!user) {
          this.lds.setLoginDialogStatus(false);
          this.dialogRef.close(true);
        }
      }
    );
  }
}

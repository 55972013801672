<div class="tooltip-icon-container">  
  <span 
    class="tooltiptext" 
    [ngClass]="params.position"
  >
    <span [innerHTML]="params.message"></span>

    <ng-container *ngIf="params.showMore">
      <span class="see-more" (click)="seeMore()">More Info</span>
    </ng-container>
  </span>
</div>
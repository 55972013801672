<div>
  <div id="mySidenav" class="sidemenu">
    <ng-container *ngIf="sideMenuData$ | async as data; else loading">
      <div class="header">
        <div class="closebtn clickable" (click)="closeMenu()">&times;</div>

        <div class="label container">
          <div class="labels">
            <div class="tier">
              <img [src]="getIconSource(data.data)" />
              {{ typeName(sideMenuType$ | async)}}
            </div>
            <div class="name">
              {{ spaceName }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <ng-container *ngIf="sideMenuType$ | async as type">
          <ng-container *ngIf="type === 'portfolio'">
            <ng-container
              *ngTemplateOutlet="
                Portfolio;
                context: { data: data.data['portfolio'] }
              "
            >
            </ng-container>
          </ng-container>
          <ng-container *ngIf="type === 'campus'">
            <ng-container
              *ngTemplateOutlet="Campus; context: { data: data.data['campus'] }"
            >
            </ng-container>
          </ng-container>
          <ng-container *ngIf="type === 'site'">
            <ng-container
              *ngTemplateOutlet="Building; context: { data: data.data['site'] }"
            >
            </ng-container>
          </ng-container>

          <div class="notifications">
            <div class="row">
              <img src="../../../../assets/icons/darkgray/bell.png" />
              {{ "Notifications" }}
            </div>
            <div class="row data">
              <div>
                {{ "Active alerts" }}
              </div>
              <div>
                {{ data.data[type].active_alerts }}
              </div>
            </div>
            <div class="row data">
              <div>
                {{ "Active tickets" }}
              </div>
              <div class="result">
                {{ data.data[type].active_tickets }}
              </div>
            </div>
          </div>
          <div class="benchmarking">
            <div class="row">
              <img src="../../../../assets/icons/darkgray/circleWithTick.png" />
              {{ "Benchmarking" }}
            </div>
            <div class="row data">
              <div>
                {{ "Wellness score" }}
              </div>
              <div>
                {{ data.data[type].wellness.score | number: "1.0-0" }}
              </div>
            </div>
          </div>
          <div class="status">
            <div class="row_status">
              <div class="status_icon_container">
                <img src="../../../../assets/icons/darkgray/status.png" />
              </div>
              <div>
                {{ "Status" }}
              </div>
             
            </div>
            <ng-container *ngIf="(sideMenuType$ | async) !== 'site'; else site">
              <div class="row">
                <app-tri-colour-bar
                  [goodInput]="data.data[type].wellness.pass + '%'"
                  [averageInput]="data.data[type].wellness.acceptable + '%'"
                  [badInput]="data.data[type].wellness.investigate + '%'"
                ></app-tri-colour-bar>
              </div>
            </ng-container>
            <ng-template #site>
              <ng-container *ngFor="let parameter of parameters">
                <div class="row_site">
                  <div class="site_data_name"> {{formatTitle(parameter) | titlecase}} </div>
             <app-tri-colour-bar
               [goodInput]="data.data[type].wellness[parameter].pass + '%'"
               [averageInput]="data.data[type].wellness[parameter].acceptable + '%'"
               [badInput]="data.data[type].wellness[parameter].investigate + '%'"
             ></app-tri-colour-bar>
           </div>
              </ng-container>
               
            </ng-template>
         
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #Portfolio let-data="data">
  <div class="boxes">
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.campuses }}
        </div>
      </div>
      <div class="data_name">
        {{ "Campuses" }}
      </div>
    </div>
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.total_online_sites }}
        </div>
        <div class="second">
          {{ " / " + data.total_sites }}
        </div>
      </div>
      <div class="data_name">
        {{ "Buildings reporting" }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Campus let-data="data">
  <div class="boxes">
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.total_online_sites }}
        </div>
      </div>
      <div class="data_name">
        {{ "Buildings online" }}
      </div>
    </div>
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.total_sites - data.total_online_sites }}
        </div>
      </div>
      <div class="data_name">
        {{ "Buildings offline" }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Building let-data="data">
  <div class="boxes">
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.total_online_floors }}
        </div>
      </div>
      <div class="data_name">
        {{ "Floors online" }}
      </div>
    </div>
    <div class="box container">
      <div class="number container">
        <div class="main">
          {{ data.total_floors - data.total_online_floors }}
        </div>
      </div>
      <div class="data_name">
        {{ "Floors offline" }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingBoxes>
  <div class="boxes">
    <div class="box container">
      <div class="number container">
        <div class="main"></div>
      </div>
      <div class="data_name"></div>
    </div>
    <div class="box container">
      <div class="number container">
        <div class="main"></div>
      </div>
      <div class="data_name"></div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="header">
    <div class="closebtn" (click)="closeMenu()">&times;</div>

    <div class="label container">
      <div class="labels">
        <div class="tier">
          <div class="name">
            {{ "...Loading" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-container *ngTemplateOutlet="loadingBoxes"> </ng-container>
    <div class="notifications">
      <div class="row">
        <img src="../../../../assets/icons/darkgray/bell.png" />
        {{ "Notifications" }}
      </div>
      <div class="row data">
        <div>
          {{ "Active alerts" }}
        </div>
        <div></div>
      </div>
      <div class="row data">
        <div>
          {{ "Active tickets" }}
        </div>
        <div class="result"></div>
      </div>
    </div>
    <div class="benchmarking">
      <div class="row">
        <img src="../../../../assets/icons/darkgray/circleWithTick.png" />
        {{ "Benchmarking" }}
      </div>
      <div class="row data">
        <div>
          {{ "Wellness score" }}
        </div>
        <div></div>
      </div>
    </div>
    <div class="status">
      <div class="row">
        <img src="../../../../assets/icons/darkgray/status.png" />
        {{ "Status" }}
      </div>
      <div class="row">
        <app-tri-colour-bar
          goodInput="0%"
          averageInput="100%"
          badInput="0%"
        ></app-tri-colour-bar>
      </div>
    </div>
  </div>
</ng-template>

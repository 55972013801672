import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tri-colour-bar',
  templateUrl: './tri-colour-bar.component.html',
  styleUrls: ['./tri-colour-bar.component.scss']
})
export class TriColourBarComponent implements OnInit {

 @Input() set goodInput(value: string){
   this.good = value;
 }
 @Input() set averageInput(value: string){
   this.average = value;
 }
 @Input() set badInput(value: string){
   this.bad = value;
 }

 @Input() set numbers(value: string[]){
   this.good = value[0];
   this.average = value[1];
   this.bad = value[2];
 }

 good: string;
 average: string;
 bad: string;

  constructor() {
   }

  ngOnInit(): void {
 
  }

  hasValues(): boolean{
    const noValues =  '0%';
     if(this.good === ('0%' || 'null%')  && this.average === ('0%' || 'null%')  && this.bad === ('0%' || 'null%') ){
       return false;
     } else {
       return true;
     }

  }

}

<div class="account_container">
  <div class="horizontal-layout">
    <div class="horizontal-layout no-seperator-line">
      <div class="icon_div clickable" [routerLink]="'profile/password'">
        <fa-icon [icon]="passwordIcon"></fa-icon>
        <span>Change password</span>
      </div>
      <div class="icon_div clickable" [routerLink]="'settings'">
        <fa-icon [icon]="settingsIcon"></fa-icon>
        <span> Settings </span>
      </div>
      <div
        class="icon_div clickable"
        style="margin-bottom: 12px"
        [routerLink]="'profile'"
      >
        <fa-icon [icon]="accountIcon"></fa-icon>
        <span> Profile </span>
      </div>
    </div>
    <div class="horizontal-layout no-seperator-line">
      <div class="icon_div clickable" (click)="loginLogicService.logout()">
        <fa-icon [icon]="signOutIcon"></fa-icon>
        <span> Log out </span>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownMonitorService {

  currentDropdown = new BehaviorSubject<HTMLElement>(undefined);
  currentDropdownObs$ = this.currentDropdown.asObservable();

  // input = the #Ref for the html that is the parent
  setNewDropdown(input: HTMLElement): void {
    this.currentDropdown.next(input);
  }

  resetDropdown(): void {
    this.currentDropdown.next(undefined);
  }

  constructor() {
    this.currentDropdown.next(undefined);
  }
}

import {CustomHttpError} from '../../guards/apikey.interceptor';

export interface DataRequestStatus {
  status: 'Default' | 'Loading' | 'No Connection' | 'Complete' | 'Error' | 'Incomplete';
  message: string;
  error?: CustomHttpError;
}

export const dataDefaultStatus: DataRequestStatus = {
  status: 'Default',
  message: 'Default'
};
export const dataLoadingStatus: DataRequestStatus = {
  status: 'Loading',
  message: 'Loading'
};
export const dataCompleteStatus: DataRequestStatus = {
  status: 'Complete',
  message: 'Complete'
};

export const dataErrorStatus: DataRequestStatus = {
  status: 'Error',
  message: 'Error'
};

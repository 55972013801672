import {Pipe, PipeTransform} from '@angular/core';
import {HashTable} from '../modules/spaces/data/floor-data.service';

@Pipe({
  name: 'unitsPipe'
})
export class UnitsPipePipe implements PipeTransform {
  transform(value: number, ...args: [string, boolean?, boolean?, boolean?]): any {
    const parameterType = args[0];
    if (!parameterType) { return ''; }
    const isImperial: boolean = args[1] !== undefined ? args[1] : (localStorage.getItem('user_units_imperial') === 'true');
    const omitUnits = args[2];
    const noMarkup = args[3];
    return value !== undefined ? UnitsPipePipe.convertUnit(value, parameterType, isImperial, omitUnits, noMarkup) : UnitsPipePipe.returnUnits(parameterType, isImperial);
  }

  static roundValue(value: number, type: string): number {
    const factor = roundingFactors[type];
    return Math.ceil((value - (factor / 2.001)) / factor) * factor;
  }

  static significantValue(value: number, type: string): number {
    const sf = sfFactors[type];
    return Math.round(((value + Number.EPSILON) * sf) / sf);
  }

  static convertUnit(value: number, type: string, imperial: boolean, omitUnits: boolean, noMarkup: boolean): string {
    if (type === 'temperature') {
      // taken from https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
      // choosing this over toFixed due to the execution speed and accuracy
      return `${UnitsPipePipe.significantValue(imperial ? (value / 5 * 9 + 32) : value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type === 'als' || type === 'lighting') {
      return `${UnitsPipePipe.significantValue(imperial ? value * 0.09290304 : value, type)} ${omitUnits ? '' : `${UnitsPipePipe.returnUnits(type, imperial)}`}`;
    } else if (type === 'humidity') {
      return `${UnitsPipePipe.significantValue(value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type === 'co2') {
      return `${UnitsPipePipe.significantValue(value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type === 'tvoc' || type === 'formaldehyde') {
      return `${UnitsPipePipe.significantValue(value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type === 'current') {
      return `${UnitsPipePipe.significantValue(value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    // } else if (type.startsWith('pm')) {
    //   return noMarkup ? `${UnitsPipePipe.significantValue(value, 'pm')}${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}` : `<span style="position: relative; display: inline-block">${UnitsPipePipe.significantValue(value, 'pm')}${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type.startsWith('pm')) {
       return noMarkup ? `${UnitsPipePipe.significantValue(value, type)} ${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}` : `<span style="position: relative; display: inline-block">${UnitsPipePipe.significantValue(value, type)}${omitUnits ? '' : UnitsPipePipe.returnUnits(type, imperial)}`;
    } else if (type === 'clothing') {
      // take from -1 = 1 layer to 1, 0 => 2, 1 => 3
      switch (value) {
        case -1:
          return 'Light';
        case 0:
          return 'Medium';
        case 1:
          return 'Heavy';
        default:
          return 'Medium';
      }
    }
    return `${UnitsPipePipe.significantValue(value, type)}`;
  }

  static returnUnits(type: string, imperial: boolean): string {
    const unitKeyToDisplayName = {
      temperature: imperial ?  '°F' : '°C',
      als: imperial ? 'fc' : 'lux',
      humidity: '%',
      co2: 'ppm', 
      tvoc: 'ppb',
      formaldehyde: 'ppb',
      pm01: pmUnitDisplayName,
      pm25: pmUnitDisplayName,
      pm04: pmUnitDisplayName,
      pm10: pmUnitDisplayName,
      current: 'A',
      load_voltage: 'mV',
      light: imperial ? 'fc' : 'lux',
      ozone: 'ppm',
      no2: 'ppm',
      ammonia: 'ppm',
      so2: 'ppm', 
      motion: '%',
      sound: 'dBA', 
      co: 'ppm',
    };

    return unitKeyToDisplayName[type] || '';
  }
}

const pmUnitDisplayName = '<span style="position: relative; display: inline-block">μg /m<sup>3</sup></span>'


export const roundingFactors: HashTable<number> = {
  temperature : 0.1,
  co2 : 50,
  pm25 : 0.01,
  pm01 : 0.01,
  pm10 : 0.01,
  pm04 : 0.01,
  als : 10,
  humidity : 1,
  tvoc : 50,
  formaldehyde : 50,
  current: 0.1
};
export const sfFactors: HashTable<number> = {
  temperature : 10,
  co2 : 100,
  pm25: 100,
  pm10: 100,
  pm01: 100,
  pm04: 100,
  als : 1,
  humidity : 1,
  tvoc : 100,
  formaldehyde : 100,
  current: 100
};

export enum aqiUK {
  // @ts-ignore
  'Healthy' = 1,
  // @ts-ignore
  'Healthy',
  // @ts-ignore
  'Healthy',
  // @ts-ignore
  'Moderate',
  // @ts-ignore
  'Moderate',
  // @ts-ignore
  'Unhealthy',
  // @ts-ignore
  'Unhealthy',
  // @ts-ignore
  'Unhealthy',
  'Very Unhealthy',
  'Hazardous'
}

export enum aqiUSA {
  'Healthy',
  'Moderate',
  // @ts-ignore
  'Unhealthy',
  // @ts-ignore
  'Unhealthy',
  'Very Unhealthy',
  'Hazardous'
}

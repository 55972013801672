import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { LoginApiService } from '../services/login-api.service';

@Component({
  selector: 'app-auth-confirm',
  templateUrl: './auth-confirm.component.html',
  styleUrls: ['./auth-confirm.component.scss'],
})
export class AuthConfirmComponent implements OnInit {
  form: UntypedFormGroup;
  requestIsDone: boolean = false;
  errorMessage: string | false = false;

  constructor(
    private fb: UntypedFormBuilder,
    private loginApiService: LoginApiService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {}

  handleSubmit() {
    this.loginApiService
      .confirmAccount(this.form.get('email').value)
      .subscribe({
        next: (data) => {
          if (data?.status === 'email_sent') {
            return (this.requestIsDone = true);
          }
        },
        error: (err) => {
          if (err.status === 404) {
            this.errorMessage = 'Incorrect email.';
          } else {
            this.errorMessage = 'An error has occured.';
          }
        },
      });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PortfolioDataService } from '../modules/spaces/data/portfolio-data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {

  constructor(
    private apis: PortfolioDataService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    
    return this.apis.loadPortfolioTiers().pipe(
      map(() => {
        
        if (!this.apis.isDashboardEntitled()) {
          return this.router.parseUrl('/spaces');
        }

        return true;
      })
    );
  }
  
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {DataRequestStatus} from '../../../shared/interfaces/data-request-status';
import {distinctUntilChanged} from 'rxjs/operators';
import {ApiSpacesService} from './api-spaces.service';
import {SpacesDataService, SpacesNavigation} from './spaces-data.service';
import {BuildingItem, CampusItem} from '../../../shared/interfaces/interfaces.shared';
import {ApiCallsService} from '../../../shared/services/api-calls.service';
import {takeUntilComponent} from '../../../shared/services/takeUntilComponent';

@Injectable({
  providedIn: 'root'
})
export class CampusDataService extends takeUntilComponent {
  campusDataSubject = new BehaviorSubject<CampusParentItem>(null);

  getCampusDataObs$(): Observable<CampusParentItem> {return this.campusDataSubject.asObservable(); }

  private campusTimezoneSubject$: ReplaySubject<CampusTimezone> = new ReplaySubject<CampusTimezone>(1);
  getCampusTimezoneObs$(): Observable<CampusTimezone> { return this.campusTimezoneSubject$.asObservable(); }

  defaultStatus: DataRequestStatus = {status: 'Loading', message: 'Loading'};
  campusQueryStatusSubject = new BehaviorSubject<DataRequestStatus>(this.defaultStatus);
  campusQueryStatusObs$ = this.campusQueryStatusSubject.asObservable();

  cancelSubs$: Subject<void> = new Subject<void>();
  destroySubs$: Subject<null> = new Subject<null>();

  constructor(
    private apiSpacesService: ApiSpacesService,
    private spacesDataService: SpacesDataService
  ) {
    super();
    this.connect_campus_data();
  }

  connect_campus_data(): void {
    this.spacesDataService.spacesIdsObs$
      .pipe(
        distinctUntilChanged((a: SpacesNavigation, b: SpacesNavigation) =>
          a?.campus?.id === b?.campus?.id && a?.building?.id === b?.building?.id && a?.floor?.id === b?.floor?.id)
      )
      .subscribe(navigation => {
          if (!navigation) { return; }
          // not filtering the stream as it needs to empty out
          // otherwise going back to undefined campus and forward onto original campus doesn't work properly.
          if (!navigation.campus.id) {
            return;
          }
          this.empty_current_campus();
          this.apiSpacesService.fillCampusStream(navigation.campus.id, this.campusDataSubject, this.campusTimezoneSubject$, this.cancelSubs$, this.campusQueryStatusSubject, !(navigation.building.id || navigation.floor.id));
        }
      );
  }

  empty_current_campus(): void {
    this.cancelSubs$.next();
    this.campusQueryStatusSubject.next(this.defaultStatus);
    this.campusDataSubject.next(null);
    this.campusTimezoneSubject$.next(null);
  }

}

export interface CampusParentItem {
  campus?: CampusItem;
  campusId: number;
  created_at: string;
  id: number;
  label: string;
  update_at: string;

  building_count: number;
  well_data: BuildingItem[];
}

export interface CampusTimezone {
  timezone: string;
  offset: number;
}



import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {

  constructor(private httpClient: HttpClient) {
  }

  getAlertSettings(): Observable<AlertSettings> {
    return this.httpClient.get<GetAlertSettingsResponse>(`${environment.apiUrl}/alerts_settings`).pipe(
      map(
        data => data.data.alerts_settings
      )
    );
  }

  putAlertSettings(input: AlertSettings): Observable<AlertSettings> {
    return this.httpClient.put<PutAlertSettingsResponse>(`${environment.apiUrl}/alerts_settings`, {}).pipe(
      map(
        data => data.data.alerts_settings
      )
    );
  }

  patchAlertSettings(input: Partial<AlertSettings>): Observable<AlertSettings> {
    return this.httpClient.patch<PatchAlertSettingsResponse>(`${environment.apiUrl}/alerts_settings`, {alerts_settings: input}).pipe(
      map(
        data => data.data.alerts_settings
      )
    );
  }
}

export interface AlertSettings {
  receive_operational_email_alerts?: boolean;
  operational_sensitivity?: 'high' | 'medium' | 'low';
  aqi_sensitivity: string;
  co2_sensitivity: string;
  comfort_sensitivity: string;
  humidity_sensitivity: string;
  temperature_sensitivity: string;
  tickets: boolean;
  receive_email: boolean;
  receive_sms: boolean;
  report_frequency: string;
  created_at?: string;
  id?: number;
  updated_at?: string;
  user_id?: number;
  aqi_region?: 'UK' | 'US';
  preferable_units?: 'metric' | 'us_units';
}

export interface GetAlertSettingsResponse {
  data: {
    alerts_settings: AlertSettings
  }
}

export interface PutAlertSettingsResponse {
  data: {
    alerts_settings: AlertSettings
  }
}

export interface PatchAlertSettingsResponse {
  data: {
    alerts_settings: AlertSettings
  }
}

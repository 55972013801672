import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LoginDataService} from '../modules/login/services/login-data.service';
import {flatMap, map, take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from './login-dialog/login-dialog.component';
import {LoginLogicService} from '../modules/login/services/login-logic.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private lds: LoginDataService,
    private lls: LoginLogicService,
    private router: Router,
    private matDialog: MatDialog
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!['kiosk', 'feedback'].includes(next.routeConfig.path) && localStorage?.getItem('kiosk_token_authorisation') === 'true') {
      localStorage.removeItem('kiosk_token_authorisation');
      this.lls.logout(false);
    }
    if (next.queryParams.token) {
      localStorage.setItem('kiosk_token_authorisation', 'true');
      this.lds.setUserToken(next.queryParams.token);
      this.lls.tryAutoLogin();
      return this.lds.getUserTokenObs().pipe(map(data => !!data));
    }
    const token = this.lds.getUserToken();
    if (!token) {
      return this.lds.getLoginDialogObs().pipe(
        take(1),
        flatMap(
          dialogOpen => {
            if (dialogOpen) {
              return this.lds.getUserObs().pipe(
                take(1)
              );
            } else {
              return this.matDialog.open(LoginDialogComponent, {disableClose: true, data: {source: 'auth-guard.service'}}).afterClosed();
            }
          }
        )
      ).pipe(
        map(
          user => !!user
        )
      );
    }
    return of(true);
  }
}

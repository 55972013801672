import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TicketData, TicketFilters} from '../../../modules/spaces/data/ticket-data.service';
import {tableCompare} from '../../services/table-functions.shared';
import * as moment from 'moment';
import {faChevronRight, faClock, faSortDown, faTimes} from '@fortawesome/free-solid-svg-icons';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';
import {AlertData} from '../../../modules/spaces/data/alert-data.service';
import {DropdownMonitorService} from '../../services/dropdown-monitor.service';
import {debounceTime, filter, map, pairwise, takeUntil} from 'rxjs/operators';
import {customSortFunctionType} from '../../layout/layout-table/layout-table.component';
import {CampusDataService} from '../../../modules/spaces/data/campus-data.service';
import {takeUntilComponent} from '../../services/takeUntilComponent';
import {DateRange} from '../../../modules/spaces/data/floor-data.service';
import { ResourceStatusAll } from '../../interfaces/data-interfaces';

@Component({
  selector: 'app-issues-filters',
  templateUrl: './issues-filters.component.html',
  styleUrls: ['./issues-filters.component.scss']
})

export class IssuesFiltersComponent extends takeUntilComponent implements OnInit {
  faChevronRight = faChevronRight;
  faClock = faClock;
  faSortDown = faSortDown;
  faTimes = faTimes;
  Math = Math;
  offset: number = 0;

  @Input() parameters: FilterParameter[];
  // @ToDo remove currentData with some sort of FilterSettings / AlertFilters object
  @Input() currentData: AlertData | TicketData;


  @Input() set currentRange(input: DateRange) {
    if (!input) { return; }
    this.minDate = new Date(input.firstDate);
    this.maxDate = new Date(input.lastDate);
    
    if (this.toValueLimit > this.maxDate) {
      this.toValueLimit = this.maxDate;
    }
    
    if (this.fromValueLimit < this.minDate) {
      this.fromValueLimit = this.minDate;
    }
  }

  @Input() set triggerDiscard(ticketFilter: TicketFilters) {
    if (ticketFilter === undefined) {
      return;
    }
    this.discardFilter(ticketFilter);
    this.discardDate();
    setTimeout(
      () => this.triggerDiscardChange.emit(undefined)
    );
  }

  currentSortSub$: ReplaySubject<FilterSettings>;
  destroyCurrentSortSub$ = new Subject<void>();
  
  @Input() set currentSort$(input: ReplaySubject<FilterSettings>) {
    this.destroyCurrentSortSub$.next();
    this.currentSortSub$ = input;
    this.currentSortSub$.pipe(
      takeUntil(this.destroyCurrentSortSub$),
      this.takeUntilDestroyed()
    ).subscribe(data => {
      this.ticketFromValue = data.date.fromDate;
      this.ticketToValue = data.date.toDate;
      this.activeFilterSettings = data;
      this.tempFilterSettings = data;
    });
  }

  @Output() readonly triggerDiscardChange = new EventEmitter<TicketFilters>();

  tfv = moment.utc(new Date()).subtract(1, 'weeks').valueOf();
  ticketFromValue: number = this.tfv;
  ticketToValue: number = new Date().getTime();
  calendarFromDate: moment.Moment = moment(this.ticketFromValue);
  calendarToDate: moment.Moment = moment(this.ticketToValue);
  fromValueLimit: Date = new Date(this.ticketFromValue - 2 * 24 * 60 * 60 * 1000);
  toValueLimit: Date = new Date(this.ticketFromValue - 24 * 60 * 60 * 1000);
  minDate: Date;
  maxDate: Date = new Date();

  maxDays = 105;
  diffDays = 0;
  timeFromPercent = 0;
  timeToPercent = 100;

  defaultDateFilter: DateFilter = {
    fromDate: this.ticketFromValue,
    toDate: this.ticketToValue,
    fromTime: 0,
    toTime: 24
  };
  activeFilterSettings: FilterSettings = {
    sort: {
      name: '',
      isAsc: undefined
    },
    campuses: [],
    buildings: [],
    floors: [],
    parameters: [],
    statuses: [],
    actives: [],
    parameter: 'temperature',
    date: {
      fromDate: this.defaultDateFilter.fromDate,
      toDate: this.defaultDateFilter.toDate,
      fromTime: Number(this.defaultDateFilter.fromTime),
      toTime: Number(this.defaultDateFilter.toTime),
      currentDate: new Date().getTime()
    },
    filters: [],
    currentDropdown: undefined,
    discardChanges: new Subject<boolean>()
  };

  tempFilterSettings: FilterSettings = {
    sort: {
      name: '',
      isAsc: undefined
    },
    campuses: [],
    buildings: [],
    floors: [],
    parameters: [],
    statuses: [],
    actives: [],
    parameter: 'temperature',
    date: {
      fromDate: this.defaultDateFilter.fromDate,
      toDate: this.defaultDateFilter.toDate,
      fromTime: Number(this.defaultDateFilter.fromTime),
      toTime: Number(this.defaultDateFilter.toTime),
      currentDate: this.defaultDateFilter.fromDate
    },
    filters: []
  };

  currentDropdown$ = this.dropdownService.currentDropdownObs$;

  @Input() resourceStatus: ResourceStatusAll;

  constructor(
    private dropdownService: DropdownMonitorService,
    private cds: CampusDataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.showDropdownSubject.next(undefined);
    this.connectDropDownStream();
    this.connectClearDropDown();
    setTimeout(() => {
      this.resetFilters();
      this.emitFilter();
    }, 0);
    this.connectTimezone();
  }

  connectTimezone(): void {
    this.cds.getCampusTimezoneObs$().pipe(
      this.takeUntilDestroyed()
    ).subscribe(
      data => {
        this.offset = data?.offset || 0;
      }
    );
  }

  emitFilter(): void {
    this.activeFilterSettings.date.currentDate = this.activeFilterSettings.date.fromDate;
    this.currentSortSub$.next(this.activeFilterSettings);
  }

  onDateRangeChange({ from, to }: { from: Date, to: Date }): void {
    this.ticketFromValue = from.getTime();
    this.ticketToValue = to.getTime();
    this.activeFilterSettings.date.fromTime = from.getHours();
    this.activeFilterSettings.date.toTime = to.getHours();
    this.activeFilterSettings.date.fromDate = this.ticketFromValue;
    this.activeFilterSettings.date.toDate = this.ticketToValue;
    this.activeFilterSettings.currentDropdown = undefined;
    this.emitFilter();
    this.dropdownService.resetDropdown();
  }

  showDropdownSubject = new ReplaySubject<[HTMLElement, string, TicketFilters]>(1);

  clickDropDownStream = combineLatest([
    this.showDropdownSubject.asObservable()
  ]).pipe(
    debounceTime(0),
    pairwise(),
    map(
      (data: [[[HTMLElement, string, TicketFilters]], [[HTMLElement, string, TicketFilters]]]) => [data[0], data[1]]
    )
  );

  showDropdown(newDropDown: HTMLElement, dropDownName: string, currentTickets: TicketFilters): void {
    this.showDropdownSubject.next([newDropDown, dropDownName, currentTickets]);
  }

  connectClearDropDown(): void {
    this.dropdownService.currentDropdownObs$.pipe(filter(data => data === undefined)).pipe(this.takeUntilDestroyed()).subscribe(
      () => this.showDropdownSubject.next(undefined)
    );
  }

  connectDropDownStream(): void {
    this.clickDropDownStream.pipe(this.takeUntilDestroyed()).subscribe(
      (data: [[HTMLElement, string, TicketFilters]][]) => {
        // because pairwise ; data[1] is the incoming data, data[0] is the previous data
        const oldData = data[0];
        const newData = data[1];
        if (newData[0] === undefined) {
          if (oldData[0]) {
            this.discardFilter(oldData[0][2]);
          }
          return;
        }
        this.discardFilter(newData[0][2]);

        if (oldData[0] && oldData[0][0] === newData[0][0]) {
          newData[0] = undefined;
          this.dropdownService.resetDropdown();
          return;
        }
        this.dropdownService.setNewDropdown(newData[0][0]);
      }
    );
  }

  swapFilter(item: string, bool: boolean, category: FilterList): void {
    if (bool) {
      category.false.splice(category.false.indexOf(item), 1);
      category.true.push(item);
      category.true.sort((a, b) => tableCompare(a, b, true));
    } else {
      category.true.splice(category.true.indexOf(item), 1);
      category.false.push(item);
      category.false.sort((a, b) => tableCompare(a, b, true));
    }
  }

  get isActiveFilter(): boolean {
    return this.activeFilterSettings.floors?.length
      + this.activeFilterSettings.campuses?.length
      + this.activeFilterSettings.actives?.length
      + this.activeFilterSettings.buildings?.length
      + this.activeFilterSettings.statuses?.length
      + this.activeFilterSettings.parameters?.length
      + (this.activeFilterSettings.parameter !== 'Parameter' ? 1 : 0)
      > 0;
  }

  clearAllFilter(ticketData: TicketData): void {
    for (const parameter of this.parameters) {
      if (parameter.type !== 'checkbox') {
        continue;
      }
      this.clearFilter(ticketData.filters[parameter.name], parameter.name, true);
    }

    this.clearParameter();
    this.clearDate();
    this.resetFilters();
    this.emitFilter();
  }

  clearFilter(filterList: FilterList, categoryName: string, supressEmit?: boolean): void {
    filterList.false = filterList.false.concat(filterList.true).sort((a, b) => tableCompare(a, b, true));
    filterList.true = [];
    this.activeFilterSettings[categoryName] = [];
    this.tempFilterSettings[categoryName] = [];
    this.resetFilters();

    if (!supressEmit) {
      this.emitFilter();
    }
  }

  discardFilterCategory(category: FilterList, categoryName: string): void {
    category.false = category.false.concat(category.true);
    category.true = this.tempFilterSettings[categoryName].slice();
    category.false = category.false.filter(a => !category.true.includes(a)).sort((a, b) => tableCompare(a, b, true));
  }

  discardDate(): void {
    this.timeFromPercent = this.activeFilterSettings.date.fromTime / 24 * 100;
    this.timeToPercent = this.activeFilterSettings.date.toTime / 24 * 100;
    this.ticketFromValue = this.activeFilterSettings.date.fromDate;
    this.ticketToValue = this.activeFilterSettings.date.toDate;
    this.calendarFromDate = moment(this.activeFilterSettings.date.fromDate + this.offset);
    this.calendarToDate = moment(this.activeFilterSettings.date.toDate + this.offset);
  }

  clearParameter(): void {
    this.activeFilterSettings.parameter = 'temperature';
  }

  clearDate(): void {
    this.timeFromPercent = 0;
    this.timeToPercent = 100;
    this.ticketFromValue = this.defaultDateFilter.fromDate;
    this.ticketToValue = this.defaultDateFilter.toDate;
    this.activeFilterSettings.date.fromTime = 0;
    this.activeFilterSettings.date.toTime = 24;
    this.activeFilterSettings.date.fromDate = this.defaultDateFilter.fromDate;
    this.activeFilterSettings.date.toDate = this.defaultDateFilter.toDate;
    this.calendarFromDate = moment(this.ticketFromValue);
    this.calendarToDate = moment(this.ticketToValue);
    this.activeFilterSettings.currentDropdown = undefined;
    this.dropdownService.resetDropdown();

    this.emitFilter();
  }

  discardFilter(currentTickets: TicketFilters): void {
    for (const parameter of this.parameters) {
      if (parameter.type === 'checkbox') {
        this.tempFilterSettings[parameter.name] = this.activeFilterSettings[parameter.name].slice();
        this.discardFilterCategory(currentTickets[parameter.name], parameter.name);
      }
    }
    this.resetFilters();
  }

  setParameter(parameter: string): void {
    this.activeFilterSettings.parameter = parameter;
    this.resetFilters();
    this.emitFilter();
    this.dropdownService.resetDropdown();
  }

  saveFilter(filterList: FilterList, category: string): void {
    this.activeFilterSettings[category] = filterList.true.slice();
    this.resetFilters();
    this.activeFilterSettings.currentDropdown = undefined;
    this.emitFilter();
    this.dropdownService.resetDropdown();
  }

  resetFilters(): void {
    for (const parameter of this.parameters) {
      const findFilterForParameter = this.activeFilterSettings.filters?.findIndex(x => x.category === parameter.name);
      if (findFilterForParameter && findFilterForParameter !== -1) {
        this.activeFilterSettings.filters.splice(findFilterForParameter, 1);
      }
      if (parameter.lockedTo) {
        this.activeFilterSettings.filters.push({
          includes: [parameter.lockedTo],
          category: parameter.name,
          propertyName: parameter.propertyName ? parameter.propertyName : parameter.name
        });
        continue;
      }
      if (this.activeFilterSettings[parameter.name]?.length) {
        this.activeFilterSettings.filters.push({
          includes: this.activeFilterSettings[parameter.name],
          category: parameter.name,
          propertyName: parameter.propertyName ? parameter.propertyName : parameter.name
        });
      }
    }
  }

}

export interface FilterSettings {
  sort?: {
    name?: string,
    isAsc?: boolean,
    customSortFunction?: customSortFunctionType
  };
  campuses?: string[];
  buildings?: string[];
  floors?: string[];
  parameters?: string[];
  statuses?: string[];
  actives?: string[];
  parameter?: string;
  date?: DateFilter;
  filters?: FilterItem[];
  search?: string;
  currentDropdown?: string;
  discardChanges?: Subject<boolean>;
}

export interface FilterParameter {
  name: string;
  propertyName?: string;
  type: 'checkbox' | 'date' | 'readingtree' | 'datetime';
  lockedTo?: string;
}

export interface FilterItem {
  category: string;
  propertyName: string;
  includes: string[];
}

export interface DateFilter {
  fromDate: number;
  toDate: number;
  currentDate?: number;
  fromTime: number;
  toTime: number;
}

export interface FilterList {
  true: Array<string>;
  false: Array<string>;
}

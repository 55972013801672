import { Component, OnInit } from '@angular/core';
import {
  faCircleUser,
  faSignOutAlt,
  faSliders,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';

import { LoginLogicService } from '../../../modules/login/services/login-logic.service';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss'],
})
export class AccountDropdownComponent implements OnInit {
  signOutIcon = faSignOutAlt;
  passwordIcon = faUnlock;
  settingsIcon = faSliders;
  accountIcon = faCircleUser;

  constructor(public loginLogicService: LoginLogicService) {}

  ngOnInit(): void {}
}

<div class="content_container border white-bg flush-top no-margin padding-xlarge">
  <ng-content></ng-content>
</div>

<ng-container *ngIf="showExit">
  <span class="clickable exit" (click)="emitExit()">
    <span><fa-icon [icon]=faTimes></fa-icon></span>
  </span>
</ng-container>

<ng-container *ngIf="showBack">
  <span class="clickable back" (click)="emitBack()">
    <span><fa-icon [icon]=faArrowLeft></fa-icon></span>
  </span>
</ng-container>

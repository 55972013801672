<ng-container *ngIf="resourceStatusInput as resourceStatus">
    <div
      class="input_style"
    >
      <div class="clickable" [matMenuTriggerFor]="parentMenu" [class]="resourceStatus[activeFilterSettings?.parameter]">
    <span>
      <span [innerHTML]="activeFilterSettings?.parameter | readableParameter : undefined : undefined : true"></span>
      <fa-icon [icon]="faSortDownIcon"></fa-icon>
    </span>
      </div>
    </div>

    <mat-menu #parentMenu='matMenu'>
      <div mat-menu-item class="clickable filter-parameter-component" [matMenuTriggerFor]="comfort" [disabled]="!resourceStatus.comfort">
        <div [class]="'status ' + resourceStatus.comfort?.status">
          <span [innerHTML]="'comfort' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.comfort?.status | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
      <div mat-menu-item class="clickable filter-parameter-component" [matMenuTriggerFor]="ventilation" [disabled]="!resourceStatus.ventilation">
        <div [class]="'status ' + resourceStatus.ventilation?.status">
          <span [innerHTML]="'ventilation' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.ventilation?.status | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
      <div mat-menu-item class="clickable filter-parameter-component" [matMenuTriggerFor]="air_quality" [disabled]="!resourceStatus.air_quality">
        <div [class]="'status ' + resourceStatus.air_quality?.status">
          <span [innerHTML]="'air_quality' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.air_quality?.status | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
      <div mat-menu-item class="clickable filter-parameter-component" [matMenuTriggerFor]="energy" [disabled]="!resourceStatus.energy">
        <div [class]="'status ' + resourceStatus.energy?.status">
          <span [innerHTML]="'energy' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.energy?.status | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
    </mat-menu>

    <mat-menu #comfort='matMenu'>
      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('temperature')" [disabled]="!resourceStatus.temperature">
        <div [class]="'status ' + resourceStatus.temperature">
          <span [innerHTML]="'temperature' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.temperature | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('humidity')" [disabled]="!resourceStatus.humidity">
        <div [class]="'status ' + resourceStatus.humidity">
          <span [innerHTML]="'humidity' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.humidity | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('als')" [disabled]="!resourceStatus.als">
        <div [class]="'status ' + resourceStatus.als">
          <span [innerHTML]="'als' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.als | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
    </mat-menu>

    <mat-menu #ventilation='matMenu'>
      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('co2')" [disabled]="!resourceStatus.co2">
        <div [class]="'status ' + resourceStatus.co2">
          <span [innerHTML]="'co2' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.co2 | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
    </mat-menu>

    <mat-menu #air_quality='matMenu'>
      <ng-container *ngIf="(resourceStatus.pm25 || resourceStatus.pm10 || resourceStatus.pm01 || resourceStatus.pm04)">
        <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('pm')" [disabled]="!(resourceStatus.pm25 || resourceStatus.pm10 || resourceStatus.pm01 || resourceStatus.pm04 )">
          <div [class]="'status ' + resourceStatus.pm">
            <span [innerHTML]="'pm' | readableParameter"></span>
            <div class="tooltipOverlay" [tippy]="resourceStatus.pm | resourceStatus" placement="left" className="wellTooltip"></div>
          </div>
        </div>
      </ng-container>
      



      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('tvoc')" [disabled]="!resourceStatus.tvoc">
        <div [class]="'status ' + resourceStatus.tvoc">
          <span [innerHTML]="'tvoc' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.tvoc | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>

      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('formaldehyde')" [disabled]="!resourceStatus.formaldehyde">
        <div [class]="'status ' + resourceStatus.formaldehyde">
          <span [innerHTML]="'formaldehyde' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.formaldehyde | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>

    </mat-menu>
    <mat-menu #energy='matMenu'>
      <div mat-menu-item class="clickable filter-parameter-component" (click)="setParameter('current')" [disabled]="!resourceStatus.current">
        <div [class]="'status ' + resourceStatus.current">
          <span [innerHTML]="'current' | readableParameter"></span>
          <div class="tooltipOverlay" [tippy]="resourceStatus.current | resourceStatus" placement="left" className="wellTooltip"></div>
        </div>
      </div>
    </mat-menu>
</ng-container>

import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  Subject,
  tap
} from "rxjs";
import { ApiCallsService } from "../../../shared/services/api-calls.service";
import {
  CampusItem,
  FloorItem,
} from "../../../shared/interfaces/interfaces.shared";
import { DataRequestStatus } from "../../../shared/interfaces/data-request-status";
import { PortfolioTier } from "./tree-view-data.service";
import { LoginDataService, User } from "../../login/services/login-data.service";
import { takeUntilComponent } from "src/app/shared/services/takeUntilComponent";

@Injectable({
  providedIn: "root",
})
export class PortfolioDataService extends takeUntilComponent {

  preventRedirect = false;
  
  private user: User;
  private tiers: Array<PortfolioTier>;
  private portfolioMessageSubject = new ReplaySubject<DataRequestStatus>(1);
  portfolioMessageSubject$ = this.portfolioMessageSubject.asObservable();
  cancelSub$ = new Subject<void>();
  
  private currentPortfolioType = new BehaviorSubject<
    "Campuses" | "Sites" | "Floors" | "None"
  >("Campuses");
  currentPortfolioType$ = this.currentPortfolioType.asObservable();

  portfolioDataSubject = new ReplaySubject<CampusItem[]>(1);
  portfolioDataObs$ = this.portfolioDataSubject.asObservable();
  floorPortfolioDataSubject = new ReplaySubject<FloorItem[]>(1);
  floorPortfolioDataObs$ = this.floorPortfolioDataSubject.asObservable();

  constructor(
    private apiCallsService: ApiCallsService,
    private loginDataService: LoginDataService
  ) {
    super()

    this.loginDataService
    .getUserObs()
    .pipe(this.takeUntilDestroyed())
    .subscribe(data => this.user = data);
  }

  empty_current_portfolio(): void {
    this.portfolioDataSubject.next(undefined);
    this.tiers = undefined;
    this.cancelSub$.next();
  }

  loadPortfolioTiers(force: boolean = false): Observable<PortfolioTier[]> {

    if (this.tiers && !force) {
      return of(this.tiers);
    }

    return this.apiCallsService.getPortfolioTiers()
      .pipe(tap(data => this.tiers = data));
  }

  isDashboardEntitled(): boolean {
    if (!this.user.enable_dashboard_view) {
      return false;
    }

    return this.canDashboardBeShown()
  }

  canDashboardBeShown(): boolean {
    if (this.tiers) {

      const portfolios = this.tiers;
      
      if (portfolios.length !== 1) {
        return false;
      }
    
      const portfolio = portfolios[0].Portfolio;
      const campusesSize = portfolio.Campus.length;
      
      if (campusesSize > 1) {
        return false;
      }
    
      const buildingSize = portfolio.Campus[0].Site.length;
      
      return buildingSize === 1;
    }

    return false;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnonymiseDataService {

  companyNames = ['Inc', 'Ltd', 'Corp'];

  constructor() { }

  portfolioNameType(name: string):string {
    name = name.toLocaleLowerCase();
    const first= name[0].charCodeAt(0)-97;
    const companyName = first<7 ? this.companyNames[0] : first> 15 ? this.companyNames[2] : this.companyNames[1];
    const anonCompanyName = companyName;
    return anonCompanyName;

  }
}

  export const numberFromOneCharture = (name: string):number =>{
    //for floor and sensors
    if(name) {
      name = name.toLocaleLowerCase();
    const namelength = name.length;
    const last= name[namelength-1].charCodeAt(0);
    return last;
    }
    

  }

  export const singleDigitFrom = (name: string):number =>{
    //for buiilding
    name = name.toLocaleLowerCase();
    const first= name[0].charCodeAt(0)-97;
    let numberToArray =  Array.from(String(first), Number);
    while (numberToArray.length>1){
      const lastIndex = numberToArray.length -1;
      const lastTwoNumbersAdded = numberToArray[lastIndex-1] + numberToArray[lastIndex];
      const lastTwoArray = Array.from(String(lastTwoNumbersAdded), Number);
      numberToArray.splice(lastIndex-1, 2);
      numberToArray = numberToArray.concat(lastTwoArray);
    }
    return numberToArray[0];
  }

  export const numberFromName = (name:string):number =>{
    //portfolio and campus
    name = name.toLocaleLowerCase();
    const namelength = name.length;
   const first= name[0].charCodeAt(0)-97;
   let second: number = 0;
   let last: number = 0;
   if(namelength > 1){
     second= name[1].charCodeAt(0)-97;
     last= name[namelength-1].charCodeAt(0)-97;
   }
   return first + second + last;
  }


import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipMoreInfoComponent } from './tooltip-more-info/tooltip-more-info.component';
import { TippyInstance, TIPPY_REF } from '@ngneat/helipopper';
import { Placement } from 'tippy.js';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  
  darkBackground = false;
  
  @Input() params: ToolTipParams;

  constructor(
    private matDialog: MatDialog,
    @Optional() @Inject(TIPPY_REF) private tippy: TippyInstance
  ) { }

  ngOnInit(): void {
    if (this.tippy) {
      this.params = this.tippy.data ?? {};
      if (!this.params.message) {
        this.params.message = '';
      }
      this.tippy.props.placement = this.params.position;
    }

    this.darkBackground = !!this.params.darkBackground;
  }

  getTooltipTextLength(): number {
    return this.params.message.length;
  }

  seeMore(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.matDialog.open(
      TooltipMoreInfoComponent, 
      {
        maxWidth: '500px',
        data: { 
          title: this.params.showMore.title,
          message: this.params.showMore.message 
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => { });

  }
}

export interface ToolTipParams {
  // the initial message when you hover over the tooltip
  message: string;
  // parent element the tooltip will append to
  appendTo?: Element;
  // where you want the tooltip to show up relative to the icon
  position?: ToolTipPostion;
  // include if there is a longer message to show otherwise do not include
  showMore?: ShowMoreToolTip;
  // true is the background the tooltip is to show against is dark
  darkBackground?: boolean;
}

export interface ShowMoreToolTip {
  title: string;
  message: string;
}

export type ToolTipPostion = Placement;

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faArrowLeft, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-layout-modal-container',
  templateUrl: './layout-modal-container.component.html',
  styleUrls: ['./layout-modal-container.component.scss']
})
export class LayoutModalContainerComponent implements OnInit {
  @Output() readonly exit = new EventEmitter<boolean>();
  @Output() readonly back = new EventEmitter<boolean>();
  @Input() showExit = true;
  @Input() showBack = false;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  constructor() { }

  ngOnInit(): void {
  }

  emitExit(): void {
    this.exit.next(false);
  }

  emitBack(): void {
    this.back.next(false);
  }

}

<div class="container">
    <ng-container *ngIf="hasValues(); else noValues">
        <div class="good" [ngStyle]="{'width': good }" ></div>
        <ng-container *ngIf="average !== '0%'">
            <div class="spacer"></div>
        </ng-container> 
        <div class="average" [ngStyle]="{'width': average }"></div>
        <ng-container *ngIf="bad !== '0%' && !(good === '0%' && average ==='0%')">
            <div class="spacer"></div>
        </ng-container>
        <div class="bad" [ngStyle]="{'width': bad }"></div>

    </ng-container>
    <ng-template #noValues>
        <div class="noValues">
 
        </div>
        
    </ng-template>
   
</div>

import {DateFilter, FilterItem, FilterSettings} from '../inputs/issues-filters/issues-filters.component';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface TableSortInterface {
  name: string;
  isAsc: boolean;
}

export interface TableSearchParameters {
  category: string;
  isAsc: boolean;
  search?: string;
  filters?: FilterItem[];
  dateRange?: DateFilter;
}

export const tableCompare: Function = (a: number | string, b: number | string, isAsc: boolean = true): number => {
  const regexAlpha = /[^a-zA-Z]/g;
  const regexNumeric = /[^0-9]/g;
  if (typeof (a) === 'string') {
    a = a.toLowerCase();
  }
  if (typeof (b) === 'string') {
    b = b.toLowerCase();
  }
  if (typeof (a) === 'string' && typeof (b) === 'string') {
    // compare by the text in the string first,
    const aA = a.replace(regexAlpha, '');
    const bA = b.replace(regexAlpha, '');
    if (aA === bA) {
      // if removing numbers left the text being equal...
      // take the remaining numbers and pad them up to be same length
      // so that Property 6 - 7 is organised properly amongst Property 4 - 48 and Property 7-27
      a = a.replace(regexNumeric, '');
      while (a.length < 40) { a = `${a}0` }
      b = b.replace(regexNumeric, '');
      while (b.length < 40) { b = `${b}0` }
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // so in a table a -1 value is not really a thing (so far)
  // therefore, if -1 or undefined, push to the bottom of the list regardless
  // or if one is undefined/null then push that down
  // else if same
  if (b > 0 && (a < 0 || a === undefined || a === null) || (!(b === undefined || b === null) && (a === undefined || a === null))) {
    return 1;
  } else if (a > 0 && (b < 0 || b === undefined || b === null) || (!(a === undefined || a === null) && (b === undefined || b === null))) {
    return -1;
  } else if (a === b) {
    return 0;
  }
  // otherwise just compare the difference if one or both aren't strings
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const createSearchableStream = <T>(
  inputStream: Observable<T[]>,
  searchStream: Observable<FilterSettings>,
  searchField: string
): Observable<T[]> => combineLatest([ inputStream, searchStream ])
  .pipe(
    map(
      ([input, search]: [T[], FilterSettings]) => {
        if (!search) {
          return input;
        }
        let dataList = input ? input.slice() : [];
        if (search.search) {
          dataList = dataList.filter(item => runSearchFilterForSearchStream(search, searchField, item));
        }
        if (search.sort?.name) {
          dataList = dataList.sort((a, b) => runSortForSearchStream(a, b, search));
        }
        // @Todo add a type to this, e.g range, exact, date, number, string, etc
        if (search.filters) {
          for (const filter of search.filters) {
            if (!filter.includes.length) {
              continue;
            }
            dataList = dataList.filter(item => runSelectedFilterForSearchStream(item, filter));
          }
        }
        return dataList;
      }
    )
  );

const getCombinedValue = (item: Object, properties: string[]): string => {
  let fullValue = '';
  for (const property of properties) {
    fullValue += property.includes('.') ? getNestedValue(item, property.split('.')) : item[property] ? item[property] : '';
  }
  return fullValue;
};

const getNestedValue = (item: Object, properties: string[]): string => {
  let currentValue = item[properties[0]];
  for (let i = 1; i < properties.length; i++) {
    if (currentValue) {
      currentValue = currentValue[properties[i]];
    } else {
      return '';
    }
  }
  return currentValue;
};

const runSortForSearchStream = (a: Object, b: Object, searchTerms: FilterSettings): number => {
  if (searchTerms.sort.customSortFunction) {
    return searchTerms.sort.customSortFunction(a, b, searchTerms.sort.isAsc);
  }
  const aValue = getItemPropertyFromStringField(searchTerms.sort.name, a);
  const bValue = getItemPropertyFromStringField(searchTerms.sort.name, b);
  return tableCompare(aValue, bValue, searchTerms.sort.isAsc);
};

const runSearchFilterForSearchStream = (searchTerms: FilterSettings, searchField: string, item: Object): boolean => {
  if (searchField.includes(',')) {
    let found = false;
    const searchFieldList: string[] = searchField.split(',');
    for (const field of searchFieldList) {
      if (getItemContainsSearch(searchTerms.search.toLowerCase(), field, item)) {
        found = true;
        break;
      }
    }
    return found;
  }
  return getItemContainsSearch(searchTerms.search.toLowerCase(), searchField, item);
};

const runSelectedFilterForSearchStream = (item: Object, filter: FilterItem): boolean => {
  const compareTo = item[filter.propertyName];
  for (const lookingFor of filter.includes) {
    if (lookingFor === compareTo) {
      return true;
    }
  }
  return false;
};

const getItemPropertyFromStringField = (propertyString: string, item: Object): string => {
  // propertyString will be something like site.label+campus.label+device.id
  let value: string;
  if (propertyString.includes('+')) {
    const properties = propertyString.split('+');
    value = getCombinedValue(item, properties);
  } else if (propertyString.includes('.')) {
    const properties = propertyString.split('.');
    value = getNestedValue(item, properties);
  } else {
    value = item[propertyString];
  }
  return value;
};

const getItemContainsSearch = (searchTerm: string, searchField: string, item: Object): boolean => {
  const value = getItemPropertyFromStringField(searchField, item);
  if (Array.isArray(value)) {
    const array = value as string[];
    let found = false;
    for (const arrayItem of array) {
      if (arrayItem.toLowerCase().includes(searchTerm)) {
        found = true;
        break;
      }
    }
    return found;
  }
  return value?.toLowerCase().includes(searchTerm);
};

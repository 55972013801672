import { Component, Input, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-sort-icon',
  templateUrl: './table-sort-icon.component.html',
  styleUrls: ['./table-sort-icon.component.scss'],
})
export class TableSortIconComponent implements OnInit {
  @Input() isAsc: boolean = false;
  @Input() isActive: boolean = false;
  @Input() isAlpha: boolean = false;
  @Input() color: string = 'white';

  iconSortAsc = faCaretUp;
  iconSortDesc = faCaretDown;

  constructor() {}

  ngOnInit() {}
}

import {Component, HostListener, OnInit} from '@angular/core';
import {DropdownMonitorService} from './shared/services/dropdown-monitor.service';
import {combineLatest, ReplaySubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {SettingsDataService} from './modules/settings/settings-data.service';
import {LoginDataService} from './modules/login/services/login-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'arbnwell';
  mouseClickSubject = new ReplaySubject<MouseEvent>();

  clickedDropdownStream = combineLatest([
    this.mouseClickSubject.asObservable(),
    this.dropdownService.currentDropdownObs$
  ]).pipe(
    map(
      (data: [MouseEvent, HTMLElement]) => {
        // otherwise check if the clicked element's parents ever equal the current open dropdown
        let count = 0;
        let currentEl: any = data[0].target;
        while (data[1] && currentEl !== data[1]) {
          count++;
          if (count > 200) {
            return false;
          }
          if (currentEl.parentElement !== null) {
            currentEl = currentEl.parentElement;
          }
        }
        return true;
      }
    )
  );

  showHeader = false;

  @HostListener('document:mousedown', ['$event']) onMouseClick($event): void {
    this.mouseClickSubject.next($event);
  }

  constructor(
    private dropdownService: DropdownMonitorService,
    private sds: SettingsDataService,
    private lds: LoginDataService,
    private router: Router
  ) {
    this.lds.getUserObs().pipe(
      filter(data => !!data)
    ).subscribe(
      () => this.sds.getSettings()
    );
    this.connectClickStream();
    this.connectShowHeaderStream();
  }

  ngOnInit(): void {
    this.showHeader = false;
    this.checkBrowserForMetricOrImperial();
  }

  checkBrowserForMetricOrImperial(): void {
    const isImperial = localStorage.getItem('user_units_imperial');
    // if there is already a saved thing, just ignore.
    if (isImperial !== undefined) {
      return;
    }
    const imperials = ['en-us', 'my', 'bur', ''];
    localStorage.setItem('user_units_imperial', `${imperials.includes(navigator.language.toLowerCase())}`);
  }

  connectShowHeaderStream(): void {
    this.router.events.subscribe(
      params => {
        if (params instanceof NavigationEnd) {
          this.showHeader = this.hideHeader(params);
        }
      }
    );
  }

  hideHeader(params): boolean {
    return params.url !== '/login' && !params.url.startsWith('/feedback') && !params.url.startsWith('/kiosk');
  }

  connectClickStream(): void {
    this.clickedDropdownStream.subscribe(
      clickedDropdown => {
        if (!clickedDropdown) {
          this.dropdownService.resetDropdown();
        }
      }
    );
  }

  
}

import { EMPTY, Observable, of, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, delay, filter, map, mergeMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoginDataService } from '../modules/login/services/login-data.service';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getError, httpServerError } from '../shared/constants/errors';
import { environment } from 'src/environments/environment';

const excludedUrls = [
  `${environment.mainUrl}/check?format=json`
];

@Injectable()
export class ApikeyInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private lds: LoginDataService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const sessionKey: string = this.lds.getSessionKey();
    if (sessionKey && sessionKey !== 'null' && sessionKey !== 'undefined') {
      return this.addAuthAndHandleCall(request, next, sessionKey);
    }

    return this.askForLoginThenContinueCall(request, next);
  }

  // add 0.5s delay here because authguard and interceptor can open a dialog at the exact same time
  checkIfUserNeedsToRelogAndOpenModal = (): Observable<boolean> =>
    of(undefined).pipe(
      delay(500),
      mergeMap(() =>
        this.lds.getLoginDialogObs().pipe(
          take(1),
          mergeMap((dialogOpen) => {
            if (dialogOpen) {
              return this.lds.getUserObs().pipe(
                filter((user) => !!user),
                map((user) => !!user)
              );
            } else {
              const isLoginPage = window.location.pathname.startsWith('/login');

              if (isLoginPage) {
                return EMPTY;
              }

              return this.matDialog
                .open(LoginDialogComponent, {
                  disableClose: true,
                  data: { source: 'apikey.interceptor' },
                })
                .afterClosed();
            }
          })
        )
      )
    );

  askForLoginThenContinueCall = (
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> => {
    if (request.url.match(ignoredAuthResourcesRegex)) {
      return next.handle(request);
    }
    return this.checkIfUserNeedsToRelogAndOpenModal().pipe(
      mergeMap(() =>
        this.addAuthAndHandleCall(request, next, this.lds.getSessionKey())
      )
    );
  };

  addAuthAndHandleCall = (
    request: HttpRequest<unknown>,
    next: HttpHandler,
    sessionKey: string
  ): Observable<HttpEvent<unknown>> => {
    const clone = request.clone({
      headers: request.headers.set('authorization', `${sessionKey}`),
    });
    return next.handle(clone).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = getErrorMessage(error);

        if (excludedUrls.includes(error.url)) {
          return throwError(() => error);
        }

        return this.lds.getLoginDialogObs().pipe(
          take(1),
          mergeMap((dialogOpen) => {
            if (errorMessage.message.includes('SessionExpired')) {
              // any of the access tokens are not valid anymore.
              // For safety reason, remove all of the left keys from
              // both session & local storages. 
              this.lds.removeUserToken();

              if (!dialogOpen) {
                this.lds.setUser(undefined);
              }

              return this.askForLoginThenContinueCall(request, next);
            }
            if (
              (error?.url && error?.status === 301) ||
              error?.error?.text?.startsWith('<!DOCTYPE html')
            ) {
              return of(
                new HttpResponse({
                  status: 200,
                  body: {
                    url: error.url,
                  },
                })
              );
            } else {
              return throwError(() => errorMessage);
            }
          })
        );
      })
    );
  };
}

const getErrorMessage = (error: HttpErrorResponse): CustomHttpError => {
  let errorMessage: string;
  if (error.error instanceof ErrorEvent) {
    // client-side error
    errorMessage = `${error.error.message}`;
  } else {
    // server-side error
    errorMessage = httpServerError[error.status];

    if (!errorMessage) {
      errorMessage = getError(error);

      if (
        errorMessage === 'Unauthorized' ||
        errorMessage === 'SessionExpired. You must authenticate again.'
      ) {
        errorMessage = 'SessionExpired';
      }

    } else {
      errorMessage = `${error.message}`;
    }
  }

  console.error('HTTP error', error);

  return {
    status: error.status,
    message: errorMessage,
    original: error,
    errors: error?.error?.errors,
    error: error?.error,
  };
};

export interface CustomHttpError {
  status: number;
  message: string;
  original: HttpErrorResponse;
  errors: { [key: string]: any };
  error: { [key: string]: any } | any[];
}

const ignoredAuthResourcesRegex =
  /\b(?:alerts_settings|login|users|sign_in|sign_out|sign_up)\b/gi;

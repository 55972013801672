import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { PortfolioDataService } from '../spaces/data/portfolio-data.service';
import { PortfolioItem } from '../spaces/data/tree-view-data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolver implements Resolve<PortfolioItem> {

  constructor(private apis: PortfolioDataService) {}
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PortfolioItem> {
    return this.apis.loadPortfolioTiers()
      .pipe(
        map(tiers => tiers[0]),
        map(tier => tier.Portfolio)
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDataService, User } from '../../login/services/login-data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser$: Observable<User>;

  constructor(
    private router: Router,
    private loginDataService: LoginDataService
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.loginDataService.getUserObs();
  }

  findActive(routes: string[]): boolean {
    for (const route of routes) {
      if (this.router.url.includes(route)) {
        return true;
      }
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {CustomHttpError} from '../../../guards/apikey.interceptor';

@Component({
  selector: 'app-query-error',
  templateUrl: './query-error.component.html',
  styleUrls: ['./query-error.component.scss']
})
export class QueryErrorComponent implements OnInit {
  @Input() error: CustomHttpError;
  errorsIsArray: boolean;

  isThisArray = (input: any) => Array.isArray(input);
  isThisObject = (input: any) => Object.keys(input).length > 0;

  constructor() {
  }

  ngOnInit(): void {
    this.errorsIsArray = Array.isArray(this.error?.error);
  }
}

import {Injectable} from '@angular/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {CampusParentItem, CampusTimezone} from './campus-data.service';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {BuildingParentItem} from './building-data.service';
import {ApiCallsService} from '../../../shared/services/api-calls.service';
import {BuildingItem, CampusItem, FloorItem} from '../../../shared/interfaces/interfaces.shared';
import { ResourceInput} from '../../../shared/services/api-calls-interfaces';
import {DataRequestStatus} from '../../../shared/interfaces/data-request-status';
import {LoginDataService, User} from '../../login/services/login-data.service';
import {tableCompare} from '../../../shared/services/table-functions.shared';
import { numberFromOneCharture, singleDigitFrom } from 'src/app/shared/services/anonymise-data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSpacesService {
  constructor(
    private loginDataService: LoginDataService,
    private apiCallsService: ApiCallsService
  ) {}

  fillBuildingStream(buildingId: number, updateStream: Subject<BuildingParentItem>, cancelStream: Observable<void>, messageStream: Subject<DataRequestStatus>, full = true, refreshBuilding$?: Observable<void>): void {
    messageStream.next({status: 'Loading', message: 'Loading'});

    const building$ = refreshBuilding$ ?  
      refreshBuilding$.pipe(switchMap(() => this.getBuilding(buildingId))) 
      : this.getBuilding(buildingId);

    combineLatest([
      building$,
      this.apiCallsService.getAllFloorsAtBuilding(`${buildingId}`),
      this.loginDataService.getUserObs().pipe(take(1)),
    ]).pipe(
      takeUntil(cancelStream)
    ).subscribe((data: [BuildingItem, FloorItem[], User]) => {
        const user = data[2];
        const nextData: BuildingParentItem = {
          building: data[0],
          well_data: data[1]
        };

        messageStream.next({status: 'Complete', message: `${nextData.building.label}`});
        nextData.well_data.sort((a, b) => tableCompare(a.label, b.label));
        updateStream.next(nextData);

        if (!full) {return; }
        let toComplete = data[1].length;
        for (const floor of data[1]) {
          if (user?.anonymous) {
            floor.label = `Floor ${numberFromOneCharture(floor.label)}`;
          }
          const resource: ResourceInput = {floor_id: Number(floor.id), building_id: Number(buildingId), campus_id: Number(nextData.building.campus_id) };
          combineLatest([
            this.apiCallsService.assignIsFloorWellValuesThenReturn(floor, resource),
            this.apiCallsService.getFloorTicketsCount(Number(floor.id))
          ])
            .pipe(takeUntil(cancelStream))
            .subscribe((floorData: [FloorItem, number]) => {
                floor.tickets = floorData[1];
                if ((toComplete -= 1) === 0) {
                  updateStream.next(nextData);
                  messageStream.next({status: 'Complete', message: nextData.building.label});
                }
              }
          );
        }

      },
      err => {
        messageStream.next({
          status: 'Error',
          message: 'Error',
          error: err
        });
      });
  }
  fillCampusStream(campusId: number, updateStream: Subject<CampusParentItem>, timezoneStream: Subject<CampusTimezone>, cancelStream: Observable<void>, messageStream: Subject<DataRequestStatus>, full = true): void {
    messageStream.next({status: 'Loading', message: 'Loading'});
    combineLatest([
      this.apiCallsService.getCampusData(campusId),
      this.apiCallsService.getAllBuildingsAtCampus(`${campusId}`),
      this.loginDataService.getUserObs().pipe(take(1))
    ]).pipe(
      takeUntil(cancelStream)
    ).subscribe(
      (data: [CampusItem, BuildingItem[], User]) => {
        const user = data[2];
        const nextData: CampusParentItem = {
          campus: data[0],
          label: `${data[0].label}`,
          campusId: Number(campusId),
          created_at: '',
          id: Number(campusId),
          update_at: '',
          building_count: data[1].length,
          well_data: data[1]
        };
        timezoneStream.next({
          timezone: nextData.campus.timezone,
          offset: getTimeOffset(nextData.campus.timezone)
        });
        updateStream.next(nextData);
        messageStream.next({status: 'Loading', message: nextData.label});
        nextData.well_data.sort((a, b) => tableCompare(a.label, b.label));

        if (!full) {return; }
        let toComplete = data[1].length;
        for (const site of data[1]) {
          if (user?.anonymous) {
            site.label = `Building ${singleDigitFrom(site.label)}`
          }
          this.apiCallsService.getBuildingWellStatusAndTicketsCount(site).pipe(
            takeUntil(cancelStream)
          ).subscribe(
            data => {
              if ((toComplete -= 1) === 0) {
                updateStream.next(nextData);
                messageStream.next({status: 'Complete', message: nextData.label});
              }
            }
          );
        }
      },
      err => {
        messageStream.next({
          status: 'Error',
          message: 'Error',
          error: err
        });
      }
    );
  }

  getBuilding(building_id: number): Observable<BuildingItem> {
    return this.apiCallsService.getBuildingData(building_id);
  }

}




export function getTimeOffset(timezone: string): number {
  const now = new Date().getTime();
  const nowRound = now - now % 1000;
  const nowDate = convertTimeWithTimezone(nowRound, timezone);
  return nowDate.getTime() - nowRound;
}

export function convertTimeWithTimezone(utcTime: number, timezone: string): Date {
  const dateString = new Date(utcTime).toLocaleString('en-UK', {timeZone: timezone});
  // since it's 'en-UK' it'll be "dd/mm/yyyy, hh:mm:ss"
  // and the format needs to become yyyy-mm-dd hh:mm:ss
  const dateTimeSplit = dateString.split(',');
  const date = dateTimeSplit[0];
  const dateSplit = date.split('/');
  let newDate = '';
  for (let i = (dateSplit.length); i--; i > 1) {
    newDate += `${(i !== dateSplit.length - 1 ? '-' : '')}${dateSplit[i]}`;
  }
  return new Date(`${newDate}${dateTimeSplit[1]}`);
}

// https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
export function stdTimezoneOffset(date: Date): number {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function isDstObserved(date: Date): boolean {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
}

import {Component, Input} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FilterSettings} from '../issues-filters/issues-filters.component';
import {takeUntilComponent} from '../../services/takeUntilComponent';
import {faSortDown} from '@fortawesome/free-solid-svg-icons/faSortDown';
import {AlertData} from '../../../modules/spaces/data/alert-data.service';
import {TicketData} from '../../../modules/spaces/data/ticket-data.service';
import {AsyncWrapInterface} from '../../services/api-calls-interfaces';
import { ResourceStatusAll } from '../../interfaces/data-interfaces';

@Component({
  selector: 'app-filter-parameter',
  templateUrl: './filter-parameter.component.html',
  styleUrls: ['./filter-parameter.component.scss']
})
export class FilterParameterComponent extends takeUntilComponent {
  
  faSortDownIcon = faSortDown;
  resourceStatus$: ResourceStatusAll;
  newResourceStatus$ : AsyncWrapInterface<ResourceStatusAll>;
  activeFilterSettings: FilterSettings;
  tempFilterSettings: FilterSettings;
  currentSortSub$: ReplaySubject<FilterSettings>;
  destroyCurrentSortSub$ = new Subject<void>();

  @Input() resourceStatusInput: ResourceStatusAll;
  @Input() currentData: AlertData | TicketData;
  
  @Input() set showStatuses(input: boolean) {
    this.resourceStatus$ = this.resourceStatusInput;
  }
  
  @Input() set currentSort$(input: ReplaySubject<FilterSettings>) {
    this.destroyCurrentSortSub$.next();
    this.currentSortSub$ = input;
    this.currentSortSub$.pipe(
      takeUntil(this.destroyCurrentSortSub$),
      this.takeUntilDestroyed()
    ).subscribe(data => {
      this.activeFilterSettings = data;
      this.tempFilterSettings = data;
    });
  }

  constructor() {
    super();
  }

  setParameter(parameter: string): void {
    this.activeFilterSettings.parameter = parameter;
    this.emitFilter();
  }

  private resetCurrentDate(): void {
    this.activeFilterSettings.date.currentDate = this.activeFilterSettings.date.fromDate;
  }

  emitFilter(): void {
    this.resetCurrentDate();
    this.currentSortSub$.next(this.activeFilterSettings);
  }
}

import { BuildingSurveyItem } from "src/app/modules/spaces/data/building-data.service";

export let anonymousDeviceHash: { [key: string]: string } = {};
export let anonymousRoomHash: { [key: string]: string } = {};

export interface BuildingSurveySettingsResponse {
  data: {
    surveys: {
      manager: BuildingSurveyItem[],
      user: BuildingSurveyItem[]
    }
  };
}

export interface ApiTokensResponse {
  data: {
    api_tokens: ApiTokenItem[]
  };
}

export interface CreateApiTokenResponse {
  data: { api_token: ApiTokenItem };
}

export interface ApiTokenItem {
  active: boolean;
  created_at: string;
  description: string;
  id: number;
  token: string;
  updated_at: string;
  user_id: number;
}

export enum ReadingTypesEnum {
  'als',
  'pm25',
  'pm01',
  'pm10',
  'pm04',
  'tvoc',
  'formaldehyde',
  'co2',
  'temperature'
}
// this can be connected directly into the scss file - but it's a whole extra package, imports, piping, etc.
export const tsColours = {
  pass: '#77c884',
  fail: '#e35b5c',
  warning: '#e3b45c',
  blank: '#7f7f7f',

  purpleArbnco: 'rgba(145, 49, 168, 1)',
  purpleArbnco50: 'rgba(145, 49, 168, 0.6)',
  // this need to be replaced with new Purple arbnco in the future
  purpleArbnwell: '#3b2748',
  purpleArbnwell10: 'rgba(94, 22, 110, 0.10)',
  purpleArbnwell50: 'rgba(94, 22, 110, 0.50)',
  paleGrayHalf: 'rgba(245,245,245,0.5)',
  darkBlack: '#404040',
  midGrey: '#e9e9ea',
  textDarkGrey: '#131415',

  // chart
  backgroundTooltip: 'rgb(21,10,10)',
  graphLine: 'rgba(81,70,68, 1)',
  graphLineFaded: 'rgba(81,70,68, .25)',
  graphMedian: 'rgba(60, 117, 172, 1)',
  graphRange: 'rgb(124, 181, 236)',

  buttons: {
    tabNotActive: '#F5F6F8',
  },

  table: {
    // this is connected to scss variable $table-background-pale-grey
    bgHeader: '#f5f5f5',
  },

  dashboard: {
    backgroundBar: '#F3F4F6',
    firstBar: '#8984F0',
    secondBar: '#2B3674',
    thirdBar: '#9131A8'
  }
};

import { Component, OnInit } from '@angular/core';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-password-tips',
  templateUrl: './password-tips.component.html',
  styleUrls: ['./password-tips.component.scss'],
})
export class PasswordTipsComponent implements OnInit {
  tooltipIcon = faInfoCircle;

  constructor() {}

  ngOnInit(): void {}
}

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoginDataService } from '../modules/login/services/login-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const rolesAllowed = next.data.roles_allowed as Array<string>;
    let redirectTo: string = next.data.redirect_to as string;
    return this.loginDataService.getUserObs().pipe(
      tap((user) => {
        if (user?.role === 'domestic') {
          redirectTo = 'help';
        }
        if (user?.role === 'deployer') {
          redirectTo = 'deployment';
        }
        if (user && !rolesAllowed.includes(user.role)) {
          this.router.navigate([redirectTo]).then();
        }
      }),
      map((user) => !user || !!rolesAllowed.includes(user?.role))
    );
  }

  constructor(
    private loginDataService: LoginDataService,
    private router: Router
  ) {}
}

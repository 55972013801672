import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { DataRequestStatus } from '../../../shared/interfaces/data-request-status';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginDataService {
  private loginDialogOpenSub$ = new BehaviorSubject<boolean>(false);
  private loginDialogOpenObs$ = this.loginDialogOpenSub$.asObservable();
  private userTokenRefreshSub$ = new BehaviorSubject<void>(undefined);
  private userToken$ = this.userTokenRefreshSub$
    .asObservable()
    // Make sure that the fresh token is always given.
    //
    // Be ware, that if you are debbuging and remove the token from Storage with
    // DevTools after a subscribe to this observable, it would still hold the
    // token.
    .pipe(map(() => this.getUserToken()));
  private userSub$ = new ReplaySubject<User>(1);
  private currentSavingStatus$: BehaviorSubject<DataRequestStatus> =
    new BehaviorSubject<DataRequestStatus>(undefined);
  private errorMessageSub$ = new BehaviorSubject<LoginErrorInterface>(
    undefined
  );

  constructor() {}

  connectSessionKey = (): void => {
    this.userTokenRefreshSub$.next();
  };

  getSessionKey = (): string | undefined => {
    return (
      // check the session storage first, as preferred
      // storage. Local storage will be used for
      // 'remember me' functionality.
      sessionStorage.getItem('session_key') ||
      localStorage.getItem('session_key')
    );
  };

  preloadUserToken() {
    this.userTokenRefreshSub$.next();
  }

  setLoginDialogStatus(status: boolean): void {
    this.loginDialogOpenSub$.next(status);
  }

  getLoginDialogObs(): Observable<boolean> {
    return this.loginDialogOpenObs$;
  }

  setUserToken(token: string, shouldRemember: boolean = false): void {
    if (shouldRemember) {
      localStorage.setItem('session_key', token ? token : '');
    } else {
      sessionStorage.setItem('session_key', token ? token : '');
    }

    this.userTokenRefreshSub$.next();
  }

  /**
   * Deletes the access token from both storage, if exists.
   */
  removeUserToken(): void {
    localStorage.removeItem('session_key');
    sessionStorage.removeItem('session_key');
    this.userTokenRefreshSub$.next();
  }

  getUserToken(): string {
    const token = this.getSessionKey();
    return token ? token : '';
  }

  getUserTokenObs(): Observable<string> {
    return this.userToken$;
  }

  setUser(user: User): void {
    if (localStorage.getItem('kiosk_token_authorisation') === 'true') {
      user.role = 'user';
      // user.gdpr_personal_data_usage_consent_at = '';
    }
    this.userSub$.next(user);
  }

  getUserObs(): Observable<User> {
    return this.userSub$.pipe(map((user) => this.checkAnonymousUser(user)));
  }

  checkAnonymousUser(user: User): User {
    if (!user) {
      return user;
    }
    if (anonymousEmails.includes(user.email)) {
      user.anonymous = true;
    }
    return user;
  }

  setSavingStatus(input: DataRequestStatus): void {
    this.currentSavingStatus$.next(input);
  }

  getSavingStatusObs(): Observable<DataRequestStatus> {
    return this.currentSavingStatus$.asObservable();
  }

  setErrorMessage(input: LoginErrorInterface): void {
    this.errorMessageSub$.next(input);
  }

  getErrorMessageObs(): Observable<LoginErrorInterface> {
    return this.errorMessageSub$.asObservable();
  }
}

export interface User {
  email: string;
  gdpr_personal_data_usage_consent_at: string;
  name: string;
  surname: string;
  session_key?: string;
  mobile_phone?: number;
  role?: 'super_user' | 'manager' | 'user' | 'domestic' | 'deployer';
  anonymous?: boolean;
  id?: number;
  created_at?: string;
  updated_at?: string;
  added_as_manager_by_id?: boolean;
  password_created_at?: string;
  enable_dashboard_view?: boolean;
  super_user?: boolean;
  require_password_rotation?: boolean;
  // Rotation lock datastring by the current manager, `null` if lock is not set.
  password_rotation_locked_at?: string | null;
  password_rotation_lock_by_higher_permission_level_manager?: {
    // If `true` current manager shouldn't be able to change the
    // `require_password_rotation` (nor `password_rotation_locked`) value and
    // the UI should display the `rotation_required` set by the higher manager.
    locked: boolean;
    // Higher manager info, present if `locked=true`.
    manager: string | null;
    // Use this value if `locked=true` because it's what the higher manager
    // wants, otherwise use the current user's `require_password_rotation`
    // value.
    rotation_required: boolean;
  };
}

export interface LoginErrorInterface {
  type:
    | 'loginUser'
    | 'signUpUser'
    | 'recoverPassword'
    | 'forgotPassword'
    | 'updateUser'
    | 'preAuthUser';
  email_errors?: string[];
  email_status?: boolean;
  password_errors?: string[];
  password_status?: boolean;
  name_errors?: string[];
  name_status?: boolean;
  surname_errors?: string[];
  surname_status?: boolean;
  mobilePhone_errors?: string[];
  mobilePhone_status?: boolean;
  recoveryErrors?: string[];
  recovery_status?: boolean;
  forgotErrors?: string[];
  forgot_status?: boolean;
  generic_errors?: any;
  responseData?: LoginResponseError;
}

export interface LoginResponseError {
  error: string;
  type: LoginErrors;
}

export type LoginErrors =
  | 'Arbnwell::Errors::Unauthorized::LockedDueToIdleness'
  | 'Arbnwell::Errors::Unauthorized::NewPasswordRequired';

const anonymousEmails = ['spreece2@arbnco.com', 'paragrastogi@arbnco.com'];

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { FilterSettings } from '../../inputs/issues-filters/issues-filters.component';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AsyncWrapInterface } from '../../services/api-calls-interfaces';
import { CustomHttpError } from '../../../guards/apikey.interceptor';
import { tsColours } from '../../colours';
import { ToolTipParams } from '../../modules/tooltip/tooltip.component';

@Component({
  selector: 'app-layout-table',
  templateUrl: './layout-table.component.html',
  styleUrls: ['./layout-table.component.scss'],
})
export class LayoutTableComponent implements OnInit, AfterViewInit {
  Math = Math;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faSearch = faSearch;
  faTimes = faTimes;
  faInfoIcon = faInfoCircle;
  currentColumnStyle: string;
  currentPage = 0;
  maxPage = 0;
  pages: Array<number> = [0];
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('outerContainer') outerContainer: ElementRef;

  // having multiple table parameters in the nested array at the start will mean that those columns are fixed.
  @Input() parameters: TableParameter[][];
  @Input() preventSorting: boolean;
  @Input() pageSize = 8;
  @Input() title: string;
  @Input() searchable: boolean;
  @Input() searchPlaceholder: string;
  @Input() headerColor: string = tsColours.table.bgHeader;
  @Input() headerTextColor: string = tsColours.textDarkGrey;
  // template that is used for each row
  @Input() rowTemplate: TemplateRef<any>;
  @Input() thSingleTemplate: TemplateRef<any>;
  @Input() headerActionsTemplate: TemplateRef<any>;
  // template that is added before the table but after title
  @Input() afterHeaderContent: TemplateRef<any>;
  currentData: Array<any>;
  currentAsyncData: AsyncWrapInterface<Array<any>>;
  loading = false;

  error: CustomHttpError;
  @Input() set asyncdata(input: AsyncWrapInterface<Array<any>>) {
    this.loading = true;
    this.currentAsyncData = input.pipe(
      tap((data) => {
        this.loading = false;
        this.data = data.data;
        this.error = data.error;
      })
    );
  }
  @Input() set data(input: Array<any>) {
    if (!input) {
      this.loading = true;
      return;
    }
    this.loading = false;
    this.currentData = input;
    this.currentPage = 0;
    this.maxPage =
      Math.floor(this.currentData.length / this.pageSize) +
      (this.currentData.length % this.pageSize === 0 ? -1 : 0);
    this.pages = new Array(this.maxPage + 1);
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i] = i;
    }
  }
  @Output() readonly searchOutput = new EventEmitter<FilterSettings>();

  constructor(private hostRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.searchable) return;
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        tap(($event) => {
          this.updateSearch();
        })
      )
      .subscribe();
  }

  updateSearch(): void {
    this.currentSort.search = this.searchInput.nativeElement.value;
    this.searchOutput.emit(this.currentSort);
  }

  currentSort: FilterSettings = {
    sort: {
      name: '',
      isAsc: false,
    },
    actives: [],
    buildings: [],
    floors: [],
    parameters: [],
    statuses: [],
    search: '',
    date: {
      fromDate: moment.utc(new Date()).subtract(1, 'weeks').valueOf(),
      toDate: moment.utc(new Date()).valueOf(),
      fromTime: 0,
      toTime: 0,
    },
    filters: [],
    currentDropdown: undefined,
  };

  sortTable(sortBy: string): void {
    if (this.currentSort.sort.name === sortBy) {
      this.currentSort.sort.isAsc = !this.currentSort.sort.isAsc;
    } else {
      const parameter: TableParameter = this.parameters
        .flat()
        .find((x) => x.dataName === sortBy);

      this.currentSort.sort = {
        name: sortBy,
        isAsc: parameter.defaultIsAsc,
        customSortFunction: parameter.customSortFunction,
      };
    }
    this.searchOutput.emit(this.currentSort);
    this.currentPage = 0;
  }

  changePage(input: number): void {
    this.currentPage = Math.min(
      Math.max(this.currentPage + input, 0),
      this.maxPage
    );
    this.outerContainer.nativeElement.scrollIntoView();
  }

  setPage(input: number): void {
    this.changePage(input - this.currentPage);
    this.outerContainer.nativeElement.scrollIntoView();
  }
}
// having multiple parametes in the same nested array at the start will mean that those columns are fixed
export interface TableParameter {
  label: string;
  dataName: string;
  isAlpha: boolean;
  colSize?: number;
  colSizePc?: number;
  colSpan?: number;
  defaultIsAsc: boolean;
  hidden?: boolean;
  noSort?: boolean;
  tooltip?: ToolTipParams;
  fixed?: boolean;
  
  useThTemplate?: boolean;
  customSortFunction?: customSortFunctionType;
}

export type customSortFunctionType = (a: any, b: any, isAsc: boolean) => number;

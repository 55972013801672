import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth-guard.service';
import { DashboardGuard } from './guards/dashboard.guard';
import { DashboardResolver } from './modules/dashboard/dashboard.resolver';
import { NgModule } from '@angular/core';
import { SpacesGuard } from './guards/spaces.guard';
import { UserResolverService } from './modules/help/services/user-resolver.service';
import { UserRoleGuard } from './guards/user-role-guard.service';

const routes: Routes = [
  {
    matcher: (urlSegment) => {
      if (!urlSegment?.length) {
        return null;
      }

      const path = urlSegment[0].path;
      const matched = ['login', 'sign_up', 'auth_confirm'].some(
        (v) => v === path
      );

      return matched ? { consumed: urlSegment } : null;
    },
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },

  {
    path: 'help',
    canActivate: [AuthGuard],
    resolve: { UserResolverService },
    loadChildren: () =>
      import('./modules/help/help.module').then((m) => m.HelpModule),
  },

  {
    path: 'settings',
    canActivate: [AuthGuard, UserRoleGuard],
    data: { roles_allowed: ['super_user', 'manager', 'user', 'deployer'] },
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'filter-portfolios',
    canActivate: [AuthGuard, UserRoleGuard, SpacesGuard],
    data: { roles_allowed: ['super_user'], redirect_to: 'spaces' },
    loadChildren: () =>
      import('./modules/filter-portfolios/filter-portfolios.module').then(
        (m) => m.FilterPortfoliosModule
      ),
  },
  {
    path: 'spaces',
    canActivate: [AuthGuard, UserRoleGuard, SpacesGuard],
    runGuardsAndResolvers: 'always',
    data: { roles_allowed: ['super_user', 'manager'], redirect_to: 'feedback' },
    loadChildren: () =>
      import('./modules/spaces/spaces.module').then((m) => m.SpacesModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, UserRoleGuard, DashboardGuard],
    resolve: { portfolio: DashboardResolver },
    data: { roles_allowed: ['super_user', 'manager'], redirect_to: 'feedback' },
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'operations',
    canActivate: [AuthGuard, UserRoleGuard],
    data: { roles_allowed: ['super_user', 'manager'], redirect_to: 'feedback' },
    loadChildren: () =>
      import('./modules/operations/operations.module').then(
        (m) => m.OperationsModule
      ),
  },
  {
    path: 'deployment',
    canActivate: [AuthGuard, UserRoleGuard],
    data: { roles_allowed: ['super_user', 'deployer'], redirect_to: 'spaces' },
    loadChildren: () =>
      import('./modules/deployment/deployment.module').then(
        (m) => m.DeploymentModule
      ),
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard, UserRoleGuard],
    data: { roles_allowed: ['super_user', 'manager', 'user'] },
    loadChildren: () =>
      import('./modules/feedback/feedback.module').then(
        (m) => m.FeedbackModule
      ),
  },
  {
    path: 'kiosk',
    canActivate: [AuthGuard, UserRoleGuard],
    data: { roles_allowed: ['super_user', 'manager', 'user'] },
    loadChildren: () =>
      import('./modules/kiosk/kiosk.module').then((m) => m.KioskModule),
  },
  {
    path: 'arc_complete',
    canActivate: [UserRoleGuard],
    data: { roles_allowed: ['super_user', 'manager', 'user'] },
    loadChildren: () =>
      import('./modules/arc-completion/arc-completion.module').then(
        (m) => m.ArcCompletionModule
      ),
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

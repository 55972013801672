import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'resourceStatus'
})
export class ResourceStatusPipe implements PipeTransform {

  transform(value: ResourceTypes, ...args: unknown[]): string {
    switch (value) {
      case 'acceptable':
        return 'Warning';
      case 'blank_state':
        return 'Offline';
      case 'investigate':
        return 'Investigate';
      case 'out_of_hours':
        return 'Out of Hours';
      case 'pass':
        return 'Good';
      default:
        return 'Unknown';
    }
  }

}
// this order represents the priority - the higher the value the worse the reading (which we will prioritise showing)
// so pass > out of hours, while investigate takes precidence
export enum ResourceStatusEnum {
  'investigate',
  'acceptable',
  'pass',
  'out_of_hours',
  'blank_state' ,
}

export type ResourceTypes = 'pass' | 'blank_state' | 'investigate' | 'acceptable' | 'out_of_hours';

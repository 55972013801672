<div class="container tree_container">
  <ng-container *ngIf="currentAsyncData | async">
    <ng-container *ngIf="!error; else errorTemplate">
      <app-side-menu
        #sideMenu
        [openEvents]="sideMenuSubject.asObservable()"
        [columnTypes]="columnTypes"
      ></app-side-menu>
      <!-- root of the tree -->
      <div class="level_0">
        <ng-container
          [ngTemplateOutlet]="headerTemplate"
          [ngTemplateOutletContext]="{ level: 0 }"
        ></ng-container>
        <!-- flow all the elements at level 0 -->
        <ng-container
          *ngFor="let item_L0 of startPointData; index as i"
        >
          <ng-container
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ level: 0, item: item_L0, indexMap: [i] }"
          ></ng-container>
  
          <ng-container *ngIf="item_L0.isOpen">
            <!-- Level 1 - flow the campuses -->
            <div class="level_1">
              <div class="header_container">
                <div class="left1"></div>
                <div class="right1">
                  <div></div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="headerTemplate"
                  [ngTemplateOutletContext]="{ level: 1 }"
                ></ng-container>
              </div>
  
              <ng-container
                *ngFor="
                  let item_L1 of item_L0[hierarchy[1]]; index as j
                "
              >
                <div>
                  <div class="space_container">
                    <div class="left1"></div>
                    <div class="right1">
                      <div class="top"></div>
                      <div class="bottom"></div>
                    </div>
                    <ng-container
                      [ngTemplateOutlet]="rowTemplate"
                      [ngTemplateOutletContext]="{
                        item: item_L1,
                        level: 1,
                        indexMap: [i, j]
                      }"
                    ></ng-container>
                  </div>
                  <ng-container
                    *ngIf="
                      item_L1[hierarchy[2]] && item_L1.isOpen
                    "
                  >
                    <!-- Level 2 - show the buildings -->
                    <div class="level_2">
                      <div class="header_container">
                        <div class="left2"></div>
                        <div class="right2 first">
                          <div></div>
                        </div>
                        <div class="left2"></div>
                        <div class="right2 last">
                          <div></div>
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="headerTemplate"
                          [ngTemplateOutletContext]="{ level: 2 }"
                        ></ng-container>
                      </div>
  
                      <ng-container
                        *ngFor="
                          let item_L2 of item_L1[hierarchy[2]]; index as k
                        "
                      >
                        <div>
                          <div class="space_container">
                            <div class="left2"></div>
                            <div class="right2 first">
                              <div></div>
                            </div>
                            <div class="left2"></div>
                            <div class="right2 last">
                              <div class="top"></div>
                              <div class="bottom"></div>
                            </div>
                            <ng-container
                              [ngTemplateOutlet]="rowTemplate"
                              [ngTemplateOutletContext]="{
                                item: item_L2,
                                level: 2,
                                indexMap: [i, j, k]
                              }"
                            ></ng-container>
                          </div>
  
                          <div>
                            <ng-container
                              *ngIf="
                                item_L2[hierarchy[3]] && item_L2.isOpen
                              "
                            >
                              <!-- Level 3 - the floors -->
                              <div class="level_3">
                                <div class="header_container">
                                  <div class="left3"></div>
                                  <div class="right3 first">
                                    <div></div>
                                  </div>
                                  <div class="left3"></div>
                                  <div class="right3 mid"></div>
                                  <div class="left3"></div>
                                  <div class="right3 last">
                                    <div></div>
                                  </div>
                                  <ng-container
                                    [ngTemplateOutlet]="headerTemplate"
                                    [ngTemplateOutletContext]="{ level: 3 }"
                                  ></ng-container>
                                </div>
                                <div class="spaces_container">
                                  <ng-container
                                    *ngFor="
                                      let item_L3 of item_L2[hierarchy[3]]; index as l
                                    "
                                  >
                                    <div>
                                      <div class="space_container">
                                        <div class="left3"></div>
                                        <div class="right3 first">
                                          <div></div>
                                        </div>
                                        <div class="left3"></div>
                                        <div class="right3 mid"></div>
                                        <div class="left3"></div>
                                        <div class="right3 last">
                                          <div class="top"></div>
                                          <div class="bottom"></div>
                                        </div>
                                        <ng-container
                                          [ngTemplateOutlet]="rowTemplate"
                                          [ngTemplateOutletContext]="{
                                            item: item_L3,
                                            level: 3,
                                            indexMap: [i, j, k, l]
                                          }"
                                        ></ng-container>
                                      </div>
                                      <div></div>
                                    </div>
                                  </ng-container>
                                </div>
                                <ng-container></ng-container>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container> </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container> </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container> </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- loading placeholder template -->
<ng-template #loadingRowsTemplate>
  <tr class="no_data_row loading">
    <td style="font-style: italic" [attr.colspan]="parameters.length">
      Loading...
    </td>
  </tr>
</ng-template>
<ng-template #errorTemplate>
  <app-query-error [error]="error"></app-query-error>
</ng-template>
<!-- no rows template -->
<ng-template #noRowsTemplate>
  <tr class="no_data_row">
    <td style="font-style: italic" [attr.colspan]="parameters.length">
      No data found
    </td>
  </tr>
</ng-template>

<ng-container *ngIf="!childLoginView; else loginForm">
  <div class="page_container">
    <div class="login_container">
      <div class="left-column">
        <div class="logo">
          <img src="../assets/logos/login_page_logo.svg" alt="arb n co logo" />
        </div>
      </div>
      <div class="right-column">
        <ng-container *ngTemplateOutlet="loginForm"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loginForm>
  <div class="horizontal-layout no-seperator-line content-container">
    <ng-container [ngSwitch]="currentMode">
      <ng-container *ngSwitchCase="'confirmAuth'"
        ><app-auth-confirm></app-auth-confirm
      ></ng-container>
      <ng-container *ngSwitchDefault
        ><ng-container *ngTemplateOutlet="mainPageTemplate"></ng-container
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #mainPageTemplate>
  <div>
    <h2 class="login-page-title">
      {{ pageTitle }}
    </h2>
    <p
      *ngIf="successMessage"
      class="success"
      [ngStyle]="{ display: recoverSuccessTrue ? 'block' : 'none' }"
    >
      {{ successMessage }}
    </p>
    <p *ngIf="successTrue && successMessage">
      Please check your inbox and confirm your e-mail.
    </p>
  </div>
  <div class="actions" *ngIf="successTrue">
    <button
      arbn-button
      arbnStyle="primary"
      class="success button-left"
      (click)="setToLogin()"
    >
      back
    </button>
  </div>

  <form
    class="form-container"
    [formGroup]="form"
    (keyup.enter)="onSubmitLoginForm()"
    (ngSubmit)="onSubmitLoginForm()"
    [ngStyle]="{ display: successTrue ? 'none' : 'block' }"
  >
    <ng-container *ngIf="currentMode === 'signUp'">
      <arbn-form-field>
        <arbn-label for="name">Name</arbn-label>
        <input
          arbnInput
          id="name"
          type="text"
          formControlName="name"
          placeholder="John Doe"
          required
          class="custom"
        />
        <arbn-error error="required">Name is required</arbn-error>
        <arbn-error error="invalid">Name is invalid</arbn-error>
      </arbn-form-field>

      <arbn-form-field>
        <arbn-label for="mobilePhone">Mobile phone</arbn-label>
        <input
          arbnInput
          id="mobilePhone"
          type="text"
          formControlName="mobilePhone"
          placeholder="+44 1234567899"
          class="custom"
        />
        <arbn-error error="invalid">
          Mobile number format: +## ####...
        </arbn-error>
      </arbn-form-field>

      <arbn-form-field>
        <arbn-label for="email">Email</arbn-label>

        <input
          arbnInput
          id="email"
          type="text"
          formControlName="email"
          placeholder="email@company.com"
          required
          class="custom"
          autocomplete="email"
        />
        <arbn-error error="required">Required</arbn-error>
        <arbn-error [error]="['invalid', 'email']"
          >Email is not valid</arbn-error
        >
      </arbn-form-field>

      <arbn-form-field class="field-with-tips">
        <arbn-label for="password"
          >{{ passwordLabel }}
          <div class="arbn-field-tips-container">
            <app-password-tips></app-password-tips></div
        ></arbn-label>
        <input
          arbnInput
          #passwordInput
          id="password"
          type="password"
          formControlName="password"
          placeholder="*************"
          required
          class="custom"
          autocomplete="new-password"
        />
        <arbn-error error="strength">Password is not strong enough</arbn-error>
        <arbn-error error="required">Password is required</arbn-error>
        <arbn-error error="invalid">Password is not valid</arbn-error>
      </arbn-form-field>

      <arbn-form-field>
        <arbn-label for="confirmPassword">{{ repeatPasswordLabel }}</arbn-label>
        <input
          arbnInput
          id="confirmPassword"
          type="password"
          formControlName="confirmPassword"
          placeholder="*************"
          required
          class="custom"
        />
        <arbn-error error="match">Passwords don't match</arbn-error>
      </arbn-form-field>
    </ng-container>

    <ng-container *ngIf="currentMode === 'login'"
      ><arbn-form-field>
        <arbn-label for="email">Email</arbn-label>
        <input
          arbnInput
          id="email"
          type="text"
          formControlName="email"
          placeholder="email@company.com"
          required
          class="custom"
          autocomplete="username"
        />
        <arbn-error error="required">Required</arbn-error>
        <arbn-error [error]="['invalid', 'email']"
          >Email is not valid</arbn-error
        >
      </arbn-form-field>

      <arbn-form-field>
        <arbn-label for="password">{{ passwordLabel }} </arbn-label>
        <input
          arbnInput
          #passwordInput
          id="password"
          type="password"
          formControlName="password"
          placeholder="*************"
          required
          class="custom"
          autocomplete="current-password"
        />
        <arbn-error error="required">Password is required</arbn-error>
        <arbn-error error="invalid">Password is not valid</arbn-error>
      </arbn-form-field>
    </ng-container>

    <ng-container *ngIf="currentMode === 'forgot'">
      <arbn-form-field>
        <arbn-label for="email">Email</arbn-label>
        <input
          arbnInput
          id="email"
          type="text"
          formControlName="email"
          placeholder="email@company.com"
          required
          class="custom"
        />
        <arbn-error error="required">Required</arbn-error>
        <arbn-error [error]="['invalid', 'email']"
          >Email is not valid</arbn-error
        >
      </arbn-form-field>
    </ng-container>

    <ng-container *ngIf="currentMode === 'recover' && !recoverSuccessTrue">
      <arbn-form-field>
        <arbn-label for="password">{{ passwordLabel }} </arbn-label>
        <input
          arbnInput
          #passwordInput
          id="password"
          type="password"
          formControlName="password"
          placeholder="*************"
          required
          class="custom"
        />
        <arbn-error error="strength">Password is not strong enough</arbn-error>
        <arbn-error error="required">Password is required</arbn-error>
        <arbn-error error="invalid">Password is not valid</arbn-error>
      </arbn-form-field>

      <arbn-form-field>
        <arbn-label for="confirmPassword">{{ repeatPasswordLabel }}</arbn-label>
        <input
          arbnInput
          id="confirmPassword"
          type="password"
          formControlName="confirmPassword"
          placeholder="*************"
          required
          class="custom"
        />
        <arbn-error error="match">Passwords don't match</arbn-error>
      </arbn-form-field>
    </ng-container>
  </form>
  <div *ngIf="currentMode === 'login'" class="form-helpers-options">
    <arbn-checkbox
      labelPosition="after"
      [(ngModel)]="rememberMe"
      class="remember-me"
    >
      Remember me
    </arbn-checkbox>
    <div class="forgotten-password clickable" (click)="setToForgotPassword()">
      Forgot password?
    </div>
  </div>
  <div *ngIf="loginErrorMessage$ | async as error" class="errors_list">
    <ng-container [ngSwitch]="error.type">
      <!--          present login errors-->
      <ng-container *ngSwitchCase="'loginUser'">
        <div class="not-valid">{{ error.generic_errors }}</div>
      </ng-container>
      <!--          present sign up errors-->
      <ng-container *ngSwitchDefault>
        <div class="not-valid">
          <div class="not-valid" *ngIf="error.password_errors as errors">
            <span
              >Password isn't valid:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.email_errors as errors">
            <span
              >Email isn't valid:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.name_errors as errors">
            <span
              >Name isn't valid:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.surname_errors as errors">
            <span
              >Surname isn't valid:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.mobilePhone_errors as errors">
            <span
              >Mobile Phone isn't valid:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.recoveryErrors as errors">
            <span
              >Change password failed:
              <span *ngFor="let error of errors; let index = i">
                {{ error }}
                <span *ngIf="index + 1 < errors.length">, </span>
              </span>
            </span>
          </div>
          <div class="not-valid" *ngIf="error.forgotErrors as errors">
            <span *ngFor="let error of errors; let index = i">
              {{ error }} <span *ngIf="index + 1 < errors.length">, </span>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="recoverEmailSent" class="text-tip">
    <p>An email has been sent to help you change your password</p>
  </div>
  <div class="actions" *ngIf="!successTrue" [class]="currentMode">
    <ng-container *ngIf="loadingStatus$ | async as loadingStatus">
      <ng-container *ngIf="loadingStatus.status === 'Loading'">
        <div class="loading"></div>
      </ng-container> </ng-container
    ><ng-container *ngIf="currentMode === 'login'">
      <button
        arbn-button
        arbnStyle="primary"
        class="button-left"
        (click)="tryLogin()"
      >
        Login
      </button>
      <button arbn-button arbnStyle="primary" (click)="setToSignUp(true)">
        Sign up
      </button>
    </ng-container>
    <button
      arbn-button
      arbnStyle="primary"
      *ngIf="currentMode === 'signUp'"
      [disabled]="!form.valid"
      (click)="trySignUp()"
    >
      Sign up
    </button>
    <button
      arbn-button
      arbnStyle="primary"
      *ngIf="currentMode === 'forgot'"
      [disabled]="!form.valid"
      (click)="tryForgotPassword()"
    >
      Send
    </button>
    <button
      arbn-button
      arbnStyle="primary"
      *ngIf="currentMode === 'recover' || recoverSuccessTrue"
      [disabled]="!form.valid"
      (click)="tryResetPassword()"
    >
      Save
    </button>
    <button
      arbn-button
      arbnStyle="naked"
      *ngIf="currentMode !== 'login'"
      class="plain"
      (click)="setToLogin(true)"
    >
      {{ backToLoginLabel }}
    </button>
  </div>
</ng-template>

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { 
  Observable,
  Subject,
  catchError,
  map,
  tap,
} from "rxjs";
import { FilterSettings } from "../../inputs/issues-filters/issues-filters.component";
import { AsyncWrapInterface } from "../../services/api-calls-interfaces";
import { CustomHttpError } from "../../../guards/apikey.interceptor";
import { SideMenuDataInput } from "../side-menu/side-menu.component";
import { Item, PortfolioTiers } from "src/app/modules/spaces/data/tree-view-data.service";
import { ColumnTypes } from "src/app/modules/spaces/0-portfolio/portfolio.component";
import { columnTypes, hierachy } from "../../constants/portfolio";

@Component({
  selector: "app-layout-tree",
  templateUrl: "./layout-tree.component.html",
  styleUrls: ["./layout-tree.component.scss"],
})
export class LayoutTreeComponent implements OnInit {
  
  sideMenuSubject: Subject<SideMenuDataInput> = new Subject<SideMenuDataInput>();
  currentData: PortfolioTiers;
  currentAsyncData: AsyncWrapInterface<PortfolioTiers>;
  error: CustomHttpError;
  loading = false;
  readonly hierarchy: Array<string> = hierachy;
  readonly columnTypes: ColumnTypes = columnTypes;

  @Input() title: string;
  @Input() headerColor: string;
  @Input() headerTextColor: string;
  @Input() rowTemplate: TemplateRef<any>;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() startPointData: Item[];
  @Input() set asyncdata(input: Observable<PortfolioTiers>) {
    this.loading = true;
    this.currentAsyncData = input.pipe(
      catchError(err => {
        this.error = err;
        return [] as PortfolioTiers;
      }),
      tap((data: PortfolioTiers) => {
        this.loading = false;
        this.data = data;
      }),
      map(() => {
        return {
          data: this.data,
          error: this.error
        };
      })
    );
  }

  @Input() set data(input: PortfolioTiers) {
    if (!input) {
      this.loading = true;
      return;
    }
    this.loading = false;
    this.currentData = input;
  }

  @ViewChild("sideMenu", { read: ElementRef }) element: ElementRef<any>;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("outerContainer") outerContainer: ElementRef;

  @HostListener("document:click", ["$event"]) onClick(event: MouseEvent) {
    const path = event.composedPath();
    const hasSideMenu = path.includes(this.element.nativeElement);
    if (!hasSideMenu) {
      this.closeSideMenu();
    }
  }

  @Output() readonly searchOutput = new EventEmitter<FilterSettings>();

  constructor(private hostRef: ElementRef) { }

  ngOnInit(): void {
    this.autoOpen(this.startPointData);
  }

  autoOpen(data: Item[]): void {
    if (data.length === 1) {
      this.toggleOpenCloseTree(data[0], 0, [0]);
    }
  }

  toggleOpenCloseTree(item: Item, level: number, indexMap: number[]): { item: Item, level: number }[] {

    const array = [];
    const depth = this.hierarchy.length - level;

    if (depth >= 0) {
      const newLevel = level + 1;
      const children: Item[] = item[this.hierarchy[newLevel]];

      if (children?.length === 1) {
        children[0].isOpen = true;
      }

      if (indexMap.length === level + 1) {
        item.isOpen = !item.isOpen;
      }

      if (item.isOpen) {
        children?.forEach(child => {
          array.push({ item: child, level: newLevel });
          
          if (child.isOpen) {
            array.push(...this.toggleOpenCloseTree(child, newLevel, indexMap));
          }
        });
      }
    }

    return array;
  }

  openSideMenu(id: number, index: number, name: string): void {
    const input: SideMenuDataInput = {
      action: "open",
      id: id,
      index: index,
      hierarchy: this.hierarchy,
      name: name,
    };
    this.sideMenuSubject.next(input);
  }

  closeSideMenu(): void {
    this.sideMenuSubject.next({ action: "close" });
  }
}

export interface TableParameter {
  label: string;
  dataName: string;
  isAlpha: boolean;
  colSize?: number;
  colSizePc?: number;
  colSpan?: number;
  defaultIsAsc: boolean;
  hidden?: boolean;
  noSort?: boolean;
  tooltip?: string;
  customSortFunction?: customSortFunctionType;
}

export type customSortFunctionType = (a: any, b: any, isAsc: boolean) => number;

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowMoreToolTip } from '../tooltip.component';

@Component({
  selector: 'app-tooltip-more-info',
  templateUrl: './tooltip-more-info.component.html',
  styleUrls: ['./tooltip-more-info.component.scss']
})
export class TooltipMoreInfoComponent {


    constructor(
      public dialogRef: MatDialogRef<TooltipMoreInfoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ShowMoreToolTip,
    ) {}


    close(){
      this.dialogRef.close();
    }
  
  }



import { ColumnTypes } from "src/app/modules/spaces/0-portfolio/portfolio.component";
import { ItemType } from "src/app/modules/spaces/data/tree-view-data.service";
import { ShowMoreToolTip, ToolTipParams } from "../modules/tooltip/tooltip.component";

export const wellnessText = 'This score is based on USGBC standards.  The longer your readings are within those standards the higher your score will be.  ';

export const wellnessShowMore: ShowMoreToolTip = {
  title: 'Wellness Score',
  message: "The wellness score represents a building's indoor environmental quality when measured using the standards set by USGBC's <a href=https://arcskoru.com/re-entry>Arc Re-entry</a> certification. The longer your data is in the green and amber zones, i.e., within standards, the higher your score. The score takes into account: <br><br>" +
    "<ul class='list'>" +
    "<li>-  The measured data itself, i.e., measurements of CO<sub>2</sub>, PM<sub>2.5</sub>, TVOC, and Relative Humidity,</li>" +
    "<li>-  The spatial coverage of these measurements, i.e., how much indoor area is represented by a single sensor,</li>" +
    "<li>-  The temporal coverage of these measurements, i.e, how frequently the measurements are taken.</li>" +
    "</ul><br>" +

    "The score is calculated every hour and the comparison indicator (up/down arrow and no-change dot) compares the last full hour to the previous day. The score for a building includes all sensors across all floors. The scores for campuses and portfolios are averages of scores for each building within them.<br><br>" +

    " To find out more how you can improve your building's scores or get started with Arc Re-entry certification, contact <a href=mailto:info@arbnco.com>info@arbnco.com</a>.<br><br>" +

    " <b>Exception for Arc Re-entry Users</b>: The building-level score is the Re-entry score received from Arc, which includes scores for data <i>and</i> surveys. If the survey scores are very different from the data-based scores, the building-level score will be different from the floor-level scores, which do not account for surveys. Arc also only updates its scores every day, so the building score you will see now is from yesterday and its comparison is to the day before. The campus and portfolio scores will then follow the building score and diverge from the floor-level scores.'"

}

export const triBarToolTip: ToolTipParams = {
  message: "Represents the status of the sensors in this space.  ",
  position: 'bottom',
  showMore: {
    title: 'Red, Amber Green Status Bars',
    message: "The colours (Red, Amber, and Green) in these bars represent the status of individual sensors contained within each grouping: floor, building, campus, or portfolio." +
      "Each sensor is a slice of the bar, so collectively the slices represent an overview of conditions at each level.<br>" +
      "The status of each sensor shows whether the latest measurements from that sensor are within set thresholds or not. Thresholds are set at the building level by selecting from a menu of global standards for each measured parameter." +
      "(A grey bar represents a building that is offline<br>" +
      "The statuses are determined for each individual measured parameter, e.g., PM<sub>2.5</sub> or TVOC. When parameters are grouped together, e.g., in the Air Quality group, the worst status from all constituent parameters is used. When only a single status bar is presented, e.g., at the portfolio level, it is a combination of all parameter statuses together."
  }
}

export const columnTypes: ColumnTypes = {
  Portfolio: {
    name: "Portfolio Name",
    icon: "../../../../assets/icons/darkgray/ic-portfolio.svg",
    columns: ["Status", "Wellness Score"],
    download: false,
    sideMenu: true,
    arc: false,
    tooltips: {
      'wellness_score': { message: wellnessText, position: 'left', showMore: wellnessShowMore },
      'sideMenu': { message: 'More Info', position: 'left' }
    }
  },
  Campus: {
    name: "Campus Name",
    icon: "../../../../assets/icons/darkgray/ic-campus.svg",
    columns: ["Status", "Wellness Score"],
    download: false,
    sideMenu: true,
    arc: false,
    tooltips: {
      'wellness_score': { message: wellnessText, position: 'left', showMore: wellnessShowMore },
      'sideMenu': { message: 'More Info', position: 'left' }
    }
  },
  Site: {
    name: "Building Name",
    icon: "../../../../assets/icons/darkgray/ic-building.svg",
    columns: ["comfort", "aqi", "ventilation", "Wellness Score"],
    download: true,
    sideMenu: true,
    arc: true,
    tooltips: {
      'wellness_score': { message: wellnessText, position: 'left', showMore: wellnessShowMore },
      'sideMenu': { message: 'More Info', position: 'left' },
      'download': { message: 'Go to building page', position: 'bottom' }
    }
  },
  Floor: {
    name: "Floor",
    icon: "../../../../assets/icons/darkgray/ic-floor.svg",
    columns: ["comfort", "aqi", "ventilation", "Wellness Score"],
    download: true,
    sideMenu: false,
    arc: false,
    tooltips: {
      'wellness_score': { message: wellnessText, position: 'left', showMore: wellnessShowMore },
      'download': { message: 'Go to floor page', position: 'left' }
    }
  },
};

export const hierachy = ["Portfolio", "Campus", "Site", "Floor"];

export const getResourceFromHierarchy = (h: ItemType) => {

  switch(h) {
    case "Portfolio":
      return 'portfolio_id';
    case "Campus":
      return 'campus_id';
    case "Site":
      return 'building_id';
    case "Floor":
      return 'floor_id'
  }
}
<ng-container *ngIf="currentAsyncData | async"></ng-container>
<ng-container *ngIf="!error; else errorTemplate">
  <div class="well_table" *ngIf="currentSort !== undefined">
    <div class="pre_headers" *ngIf="title || searchable">
      <h1 class="title">{{ title ? title : '' }}</h1>
      <div class="header_actions">
        <ng-container *ngTemplateOutlet="headerActionsTemplate"></ng-container>
      </div>
    </div>
    <div *ngIf="afterHeaderContent" class="after-header-content"><ng-container *ngTemplateOutlet="afterHeaderContent"></ng-container></div>
    <ng-container *ngTemplateOutlet="thTemplate"></ng-container>

    <div *ngIf="searchable">
      <div class="search_group">
        <fa-icon
          [icon]="faSearch"
          [style.opacity]="searchInput.value.length > 0 ? 0 : 1"
        ></fa-icon>
        <fa-icon
          [icon]="faTimes"
          [style.opacity]="searchInput.value.length > 0 ? 1 : 0"
          (click)="searchInput.value = ''; updateSearch()"
        ></fa-icon>
        <input
          #searchInput
          placeholder="{{ searchPlaceholder ? searchPlaceholder : '...' }}"
        />
      </div>
    </div>

    <div #outerContainer class="table_container">
      <table>
        <tbody>
          <tr class="well_table_headers">
            <ng-container *ngFor="let parameter of parameters">
              <ng-container *ngIf="parameter.length === 1; else fixedColumns">
                <th
                  class="single"
                  *ngIf="!parameter[0].hidden"
                  [ngClass]="{ loading: preventSorting }"
                  (click)="
                    !parameter[0].noSort && !preventSorting
                      ? sortTable(parameter[0].dataName)
                      : undefined
                  "
                  [style.background-color]="
                    headerColor ? headerColor : undefined
                  "
                  [style.color]="headerTextColor ? headerTextColor : undefined"
                  [style.width.%]="parameter[0].colSizePc"
                  [style.width.px]="parameter[0].colSize"
                  [attr.colspan]="parameter[0].colSpan"
                >
                  <ng-container
                    *ngIf="parameter[0].useThTemplate; else thContent"
                  >
                    <ng-container
                      *ngTemplateOutlet="thSingleTemplate"
                    ></ng-container>
                  </ng-container>
                  <ng-template #thContent>
                    <div>
                      {{ parameter[0].label }}
                      <ng-container *ngIf="parameter[0].tooltip">
                        <fa-icon
                          [icon]="faInfoIcon"
                          class="infoIcon"
                          tooltip
                          [data]="parameter[0].tooltip"
                        ></fa-icon>
                      </ng-container>
                      <app-table-sort-icon
                        [color]="headerTextColor ? headerTextColor : undefined"
                        *ngIf="
                          parameter[0].isAlpha !== undefined &&
                          !parameter[0].noSort
                        "
                        [isAlpha]="parameter[0].isAlpha"
                        [isActive]="
                          currentSort.sort.name === parameter[0].dataName
                        "
                        [isAsc]="currentSort.sort.isAsc"
                      ></app-table-sort-icon></div
                  ></ng-template>
                </th>
              </ng-container>
              <ng-template #fixedColumns>
                <th class="multiple">
                  <ng-container *ngFor="let item of parameter">
                    <div
                      class="column"
                      *ngIf="!item.hidden"
                      [ngClass]="{ loading: preventSorting }"
                      (click)="
                        !item.noSort && !preventSorting
                          ? sortTable(item.dataName)
                          : undefined
                      "
                      [style.background-color]="
                        headerColor ? headerColor : undefined
                      "
                      [style.color]="
                        headerTextColor ? headerTextColor : undefined
                      "
                      [style.width.%]="item.colSizePc"
                      [style.width.px]="item.colSize"
                      [attr.colspan]="item.colSpan"
                    >
                      <div>
                        {{ item.label }}
                        <ng-container *ngIf="item.tooltip">
                          <fa-icon
                            [icon]="faInfoIcon"
                            class="infoIcon"
                            tooltip
                            [data]="item.tooltip"
                          ></fa-icon>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </th>
              </ng-template>
            </ng-container>
          </tr>
          <ng-container *ngIf="loading === false; else loadingRowsTemplate">
            <ng-container *ngIf="currentData?.length > 0; else noRowsTemplate">
              <ng-container
                *ngFor="
                  let item of currentData.slice(
                    currentPage * pageSize,
                    (currentPage + 1) * pageSize
                  );
                  let i = index
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    rowTemplate;
                    context: {
                      $implicit: item,
                      index: i + currentPage * pageSize
                    }
                  "
                ></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="well_table_pagination" *ngIf="maxPage > 0">
      <div class="page_action" (click)="changePage(-1)">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        <span>Back</span>
      </div>
      <button
        class="page_button"
        [ngClass]="{ active: 0 === currentPage }"
        (click)="setPage(0)"
      >
        1
      </button>
      <button class="page_button ellipses" *ngIf="currentPage - 1 >= 2">
        ...
      </button>
      <ng-container
        *ngFor="
          let item of pages.slice(
            Math.max(1, currentPage - 1),
            Math.min(maxPage, currentPage + 2)
          )
        "
      >
        <button
          class="page_button"
          [ngClass]="{ active: item === currentPage }"
          (click)="setPage(item)"
        >
          {{ item + 1 }}
        </button>
      </ng-container>
      <button class="page_button ellipses" *ngIf="currentPage + 2 < maxPage">
        ...
      </button>
      <button
        class="page_button"
        [ngClass]="{ active: maxPage === currentPage }"
        (click)="setPage(maxPage)"
      >
        {{ maxPage + 1 }}
      </button>
      <div class="page_action" (click)="changePage(1)">
        <span>Next</span>
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRowsTemplate>
  <tr class="no_data_row">
    <td style="font-style: italic" [attr.colspan]="parameters.length">
      Loading...
    </td>
  </tr>
</ng-template>
<ng-template #errorTemplate>
  <app-query-error [error]="error"></app-query-error>
</ng-template>

<ng-template #noRowsTemplate>
  <tr class="no_data_row">
    <td style="font-style: italic" [attr.colspan]="parameters.length">
      No data found
    </td>
  </tr>
</ng-template>

import { Directive, OnDestroy } from "@angular/core";
import { Observable, Subject, UnaryFunction, pipe } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Directive()
export abstract class takeUntilComponent implements OnDestroy {
  public onDestroy$ = new Subject<boolean>();

  takeUntilDestroyed<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      map((data: T) => data),
      takeUntil(this.onDestroy$)
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}

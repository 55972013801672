import {Component, Input, OnInit} from '@angular/core';
import {tsColours} from '../../../../../../shared/colours';
import {UnitsPipePipe} from '../../../../../../pipes/units-pipe.pipe';
import {StandardItem} from '../standards-api.service';

@Component({
  selector: 'app-standard-histogram',
  templateUrl: './standard-histogram.component.html',
  styleUrls: ['./standard-histogram.component.scss']
})
export class StandardHistogramComponent implements OnInit {
  currentStandard: StandardItem;
  currentRange: number;
  currentLimits: number[];

  viewLess: {
    colour: string;
    tooltip: string;
  }[];
  viewMore: {
    colour: string;
    tooltip: string;
  }[];
  histogram: {
    colour: string;
    width: number;
    left: number
  }[];

  @Input() set standard(input: StandardItem) {
    if (input) {
      this.currentStandard = input;
      this.currentLimits = [parameterLimits[input.parameter].min, parameterLimits[input.parameter].max];
      this.currentRange = parameterLimits[input.parameter][1] - parameterLimits[input.parameter][0];
      this.histogram = [];
      this.viewLess = [];
      this.viewMore = [];

      const isImperial: boolean = localStorage.getItem('user_units_imperial') === 'true';

      const lowerRed = this.addToHistogram(this.currentLimits[0], this.currentStandard.LTI, 'red', parameterLimits[input.parameter]);
      const lowerValue = parameterLimits[input.parameter].min <= this.currentStandard.LTI ? parameterLimits[input.parameter].min : this.currentStandard.LTI;
      if (lowerRed === -1 || lowerRed === 2) { this.viewLess.push({colour: tsColours.fail, tooltip: `${UnitsPipePipe.convertUnit(lowerValue, input.parameter, isImperial, true, true)} and below`}); }

      const lowerOrange = this.addToHistogram(this.currentStandard.LTI, this.currentStandard.LTA, 'orange', parameterLimits[input.parameter]);
      if (lowerOrange === -1 || lowerOrange === 2) { this.viewLess.push({colour: tsColours.warning, tooltip: `From ${UnitsPipePipe.convertUnit(this.currentStandard.LTI, input.parameter, isImperial, true, true)} to ${UnitsPipePipe.convertUnit(this.currentStandard.LTA, input.parameter, isImperial, true, true)}`}); }

      const green = this.addToHistogram(this.currentStandard.LTA, this.currentStandard.UTA, 'green', parameterLimits[input.parameter]);
      if (green === 1 || green === 2) { this.viewMore.push({colour: tsColours.pass, tooltip: `From ${UnitsPipePipe.convertUnit(this.currentStandard.LTA, input.parameter, isImperial, true, true)} to ${UnitsPipePipe.convertUnit(this.currentStandard.UTA, input.parameter, isImperial, true, true)}`}); }
      if (green === -1 || green === 2) { this.viewLess.push({colour: tsColours.pass, tooltip: `From ${UnitsPipePipe.convertUnit(this.currentStandard.LTA, input.parameter, isImperial, true, true)} to ${UnitsPipePipe.convertUnit(this.currentStandard.UTA, input.parameter, isImperial, true, true)}`}); }

      const upperOrange = this.addToHistogram(this.currentStandard.UTA, this.currentStandard.UTI, 'orange', parameterLimits[input.parameter]);
      if (upperOrange === 1 || upperOrange === 2) { this.viewMore.push({colour: tsColours.warning, tooltip: `From ${UnitsPipePipe.convertUnit(this.currentStandard.UTA, input.parameter, isImperial, true, true)} to ${UnitsPipePipe.convertUnit(this.currentStandard.UTI, input.parameter, isImperial, true, true)}`}); }

      const upperRed = this.addToHistogram(this.currentStandard.UTI, this.currentLimits[1], 'red', parameterLimits[input.parameter]);
      const upperValue = parameterLimits[input.parameter].max >= this.currentStandard.UTI ? parameterLimits[input.parameter].max : this.currentStandard.UTI;
      if (upperRed === 1 || upperRed === 2) { this.viewMore.push({colour: tsColours.fail, tooltip: `${UnitsPipePipe.convertUnit(upperValue, input.parameter, isImperial, true, true)} upwards`}); }
    } else {
      // preview
      this.currentStandard =  undefined;
    }
  }

  // return true if the bar goes outside of the graph
  addToHistogram(start, end, color, parameterLimits: ParameterLimit): -1 | 0 | 1 | 2 {
    const range = parameterLimits.max - parameterLimits.min;
    if (start === end) { return 0; }
    let toReturn: -1 | 0 | 1 | 2 = 0;
    const toAdd = {
      left: (start - parameterLimits.min) / range * 100,
      width: (end - start) / range * 100,
      colour: color
    };
    // if it starts before it's on the range
    if (start < parameterLimits.min) {
      // if ends <min then add left but not box
      if (end < parameterLimits.min) {
        return -1;
      }
      if ((toAdd.left + toAdd.width) >= 100 && (toAdd.left + toAdd.width) > 0) {
      // if bigger than 100% and on screen, fit to screen, add left and right
        toAdd.width = 100 - toAdd.left;
        toAdd.width -= toAdd.left;
        toAdd.left = 0;
        toReturn = 2;
      } else if ((toAdd.left + toAdd.width) >= 100) {
      // if ends afters 100% add right
        toAdd.width = 100 - toAdd.left;
        toReturn = 1;
      } else if ((toAdd.left + toAdd.width) > 0) {
      // else if on the screen then fix sizing and add left
        toAdd.width -= toAdd.left > 0 ? toAdd.left : 0;
        toAdd.left = 0;
        toReturn = -1;
      }
      this.histogram.push(toAdd);
    } else if (start < parameterLimits.max) {
    // if the right arrow goes over
      if ((toAdd.left + toAdd.width) >= 100) {
        toAdd.width = 100 - toAdd.left;
        toReturn = 1;
      }
      this.histogram.push(toAdd);
    } else {
      toReturn = 2;
    }
    if (color === 'red') {
      toReturn = 2;
    }
    if (parameterLimits.min === 0 && !parameterLimits.canNegative && toReturn === -1) {
      toReturn = 0;
    } else if (parameterLimits.min === 0 && !parameterLimits.canNegative && toReturn === 2) {
      toReturn = 1;
    }
    return parameterLimits.fixedRange ? 0 : toReturn;
  }

  constructor() { }

  ngOnInit(): void {}

}

export interface ParameterLimit {
  min: number;
  max: number;
  fixedRange: boolean;
  canNegative: boolean;
}

export const parameterLimits: {[key: string]: ParameterLimit} = {
  temperature: {min: 0, max: 45, canNegative: true, fixedRange: false},
  humidity: {min: 0, max: 100, canNegative: false, fixedRange: true},
  co2: {min: 450, max: 2000, canNegative: false, fixedRange: false},
  pm01: {min: 0, max: 100, canNegative: false, fixedRange: false},
  pm25: {min: 0, max: 100, canNegative: false, fixedRange: false},
  pm10: {min: 0, max: 100, canNegative: false, fixedRange: false},
  pm04: {min: 0, max: 100, canNegative: false, fixedRange: false},
  tvoc: {min: 0, max: 425, canNegative: false, fixedRange: false},
  formaldehyde: {min: 0, max: 50, canNegative: false, fixedRange: false},
  als: {min: 0, max: 800, canNegative: false, fixedRange: false},
  current: {min: 0, max: 1000, canNegative: false, fixedRange: false},
};

export const parameterSteps = {
  celcius: 5,
  fahrenheit: 2.77777,
  humidity: 10,
  co2: 100,
  lux: 150,
  fc: 107.639,
  tvoc: 25,
  formaldehyde: 5,
  temperature: 5,
  als: 100,
  pm01: 5,
  pm25: 5,
  pm04: 5,
  pm10: 10,
  current: 0.1
};

export const minorParameterSteps = {
  humidity: 5,
  co2: 50,
  lux: 50,
  tvoc: 5,
  formaldehyde: 1,
  temperature: 2,
  als: 50,
  pm01: 1,
  pm04: 1,
  pm25: 1,
  pm10: 5,
  current: 0.01
};

<ng-container
  *ngIf="requestIsDone; then requestDoneTemplate; else requestTemplate"
></ng-container>

<ng-template #requestTemplate>
  <div class="margin-bottom-large">
    <h2 class="login-page-title">Account authentication</h2>
    <p class="text-paragraph">
      It seems that your account has not been active for the last 6 months, for
      security measures an account authentication is requested.
    </p>
    <p class="text-paragraph">
      A link will be sent to your email to allow your access to be reinstated.
    </p>
    <p class="text-paragraph">
      To confirm your account, please click on send link.
    </p>
  </div>
  <form [formGroup]="form" (ngSubmit)="handleSubmit()">
    <div class="margin-bottom-large">
      <arbn-form-field>
        <arbn-label for="email">Email</arbn-label>
        <input
          arbnInput
          id="email"
          type="text"
          formControlName="email"
          placeholder="email@company.com"
          required
          class="custom"
        />
        <arbn-error error="required">Required</arbn-error>
        <arbn-error error="email">Email is not valid</arbn-error>
      </arbn-form-field>
    </div>
    <div *ngIf="errorMessage" class="not-valid margin-bottom-medium">
      {{ errorMessage }}
    </div>
    <div class="actions">
      <button
        arbn-button
        arbnStyle="primary"
        type="submit"
        [disabled]="!form.valid"
      >
        Send link
      </button>
      <a routerLink="/login"
        ><button arbn-button arbnStyle="naked">Cancel</button></a
      >
    </div>
  </form>
</ng-template>

<ng-template #requestDoneTemplate>
  <div class="margin-bottom-large">
    <h2 class="login-page-title">Account authentication requested</h2>
    <p class="text-paragraph">
      Please check your inbox and follow the link sent to confirm your account
      access.
    </p>
  </div>
  <div class="actions">
    <a routerLink="/login"
      ><button arbn-button arbnStyle="primary">Login</button></a
    >
  </div>
</ng-template>

import { BehaviorSubject, Observable } from 'rxjs';
import {
  LoginApiService,
  SendForgotPasswordResponse,
} from './login-api.service';
import { LoginDataService, LoginErrorInterface } from './login-data.service';
import { SignupUserOptions, UpdateUserOptions } from '../types';
import { finalize, flatMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioDataService } from '../../spaces/data/portfolio-data.service';
import { SettingsDataService } from '../../settings/settings-data.service';

@Injectable({
  providedIn: 'root',
})
export class LoginLogicService {
  constructor(
    private router: Router,
    private loginApiService: LoginApiService,
    private lds: LoginDataService,
    private pds: PortfolioDataService,
    private sds: SettingsDataService
  ) {}

  preloadUserIntoApp(): Promise<any> {
    this.lds.preloadUserToken();

    this.connectLocalStorageStream();
    this.lds.getUserTokenObs().subscribe((userToken) => {
      if (userToken) {
        this.tryAutoLogin();
      }
    });

    return new Promise<any>((resolve) => {
      this.lds
        .getUserTokenObs()
        .pipe(take(1))
        .subscribe(() => {
          resolve(true);
        });
    });
  }

  signUpUser(singupData: SignupUserOptions): void {
    this.lds.setErrorMessage(undefined);
    this.lds.setSavingStatus({
      status: 'Loading',
      message: 'Signing up...',
    });

    this.loginApiService.signUpUser(singupData).subscribe(
      (res) => {
        const session_token = res.session_token;
        this.lds.setUserToken(session_token);
        this.getCurrentUser();
        this.lds.setSavingStatus({
          status: 'Complete',
          message: 'Sign Up Successful',
        });
      },
      (err) => {
        const toError: LoginErrorInterface = {
          type: 'signUpUser',
        };
        const errors = err?.error?.data?.errors;

        if (errors?.email) {
          toError.email_status = !errors?.email;
          toError.email_errors = errors?.email;
        }
        if (errors?.password) {
          toError.password_status = !errors?.password;
          toError.password_errors = errors?.password;
        }
        if (errors?.name) {
          toError.name_status = false;
          toError.name_errors = errors?.name;
        }
        if (errors?.surname) {
          toError.surname_status = false;
          toError.surname_errors = errors?.surname;
        }
        if (errors?.mobilePhone) {
          toError.mobilePhone_status = false;
          toError.mobilePhone_errors = errors?.mobilePhone;
        }

        this.lds.setErrorMessage(toError);
        this.lds.setSavingStatus({
          status: 'Error',
          message: 'Try again',
        });
      }
    );
  }

  updateUser(updateData: UpdateUserOptions): void {
    this.lds.setSavingStatus({
      status: 'Loading',
      message: 'Updating',
    });
    this.lds.setErrorMessage(undefined);
    this.loginApiService.updateUser(updateData).subscribe(
      () => {
        this.lds.setSavingStatus({
          status: 'Complete',
          message: 'Update Complete',
        });
        this.getCurrentUser();
      },
      (err) => {
        this.lds.setSavingStatus({
          status: 'Error',
          message: 'Try again',
        });
        this.lds.setErrorMessage(err.error.error);
      }
    );
  }

  forgotPassword(email: string): Observable<SendForgotPasswordResponse> {
    return this.loginApiService.forgotPassword(email).pipe(
      tap(
        () => {},
        (err) => {
          const toError: LoginErrorInterface = {
            type: 'forgotPassword',
            forgot_status: false,
          };
          if (err.error.user?.length === 0) {
            toError.forgotErrors = ['email is not registered.'];
          } else {
            toError.forgotErrors = ['unknown error during recover password.'];
          }
          this.lds.setErrorMessage(toError);
        }
      )
    );
  }

  getCurrentUser(): void {
    this.loginApiService.getCurrentUser().subscribe(
      (data) => {
        this.lds.setUser(data);
      },
      () => {
        this.logout(false);
      }
    );
  }

  setRecoveryPassword(password: string, token: string): void {
    this.loginApiService.setRecoveryPassword(password, token).subscribe(
      (data) => {
        setTimeout(
          () => this.loginUser({ email: data.data.user.email, password }),
          300
        );
      },
      (err) => {
        const toError: LoginErrorInterface = {
          type: 'recoverPassword',
          recovery_status: false,
        };
        if (err.error.password_recovery_code?.length === 0) {
          toError.recoveryErrors = ['password reset token is not valid.'];
        } else {
          toError.recoveryErrors = ['unknown error during password change.'];
        }
        this.lds.setErrorMessage(toError);
      }
    );
  }

  loginUser({
    email,
    password,
    signInUnlockToken,
    shouldRemember = false,
  }: {
    email: string;
    password: string;
    signInUnlockToken?: string;
    shouldRemember?: boolean;
  }): void {
    this.lds.setErrorMessage(undefined);
    this.lds.setSavingStatus({
      status: 'Loading',
      message: 'Logging in...',
    });
    this.loginApiService
      .loginUser(email, password, signInUnlockToken)
      .subscribe(
        (res) => {
          const session_token = res.data.session_token;
          this.lds.setUserToken(session_token, shouldRemember);
          this.lds.setSavingStatus({
            status: 'Default',
            message: '',
          });
        },
        (err) => {
          const toError: LoginErrorInterface = {
            type: 'loginUser',
            email_status: false,
            password_status: false,
            generic_errors: err.error.error,
            responseData: err.error,
          };
          this.lds.setErrorMessage(toError);
          this.lds.setSavingStatus({
            status: 'Error',
            message: 'Error Logging in',
          });
        }
      );
  }

  loginPreAuthenication(token: string): void {
    this.lds.setErrorMessage(undefined);
    this.lds.setSavingStatus({
      status: 'Loading',
      message: 'Logging in...',
    });
    this.loginApiService.getPreAuthNewSessionToken(token).subscribe(
      (res) => {
        const result = res.data.pre_authentication_token.new_session_token;
        const user = result.user;
        const token = result.token;

        this.lds.setUser(user);
        this.lds.setUserToken(token);
        this.lds.setSavingStatus({
          status: 'Default',
          message: '',
        });
      },
      (err) => {
        const toError: LoginErrorInterface = {
          type: 'preAuthUser',
          email_status: false,
          password_status: false,
          generic_errors: err.error.error,
        };
        this.lds.setErrorMessage(toError);
        this.lds.setSavingStatus({
          status: 'Error',
          message: 'Error Logging in',
        });
      }
    );
  }

  checkingLogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  tryAutoLogin(): void {
    const sessionKey: string = this.lds.getSessionKey();
    if (!sessionKey) {
      return;
    }

    this.checkingLogin
      .pipe(
        take(1),
        flatMap((alreadyChecking) => {
          if (!alreadyChecking) {
            this.checkingLogin.next(true);
            return this.loginApiService.getCurrentUser();
          }
          return undefined;
        })
      )
      .subscribe(
        (user) => {
          this.checkingLogin.next(false);
          this.lds.setUser(user);
        },
        () => {
          this.checkingLogin.next(false);
        }
      );
  }

  logout(route = true): void {
    this.loginApiService.logoutUser()
      .pipe(finalize(() => this.pds.empty_current_portfolio()))
      .subscribe({
        next: (res) => {
          if (this.lds.getUserToken()) {
            this.lds.removeUserToken();
          }

          this.lds.setUser(undefined);
          this.sds.clear();

          if (res.data.signed_out) {
            if (!route) {
              return;
            }

            this.router.navigate(['/login']).then();
          }
        },
        error: () => {
          if (this.lds.getUserToken()) {
            this.lds.removeUserToken();
          }
          this.lds.setUser(undefined);
          if (!route) {
            return;
          }
          this.router.navigate(['/login']).then();
        }
      });
  }

  connectLocalStorageStream(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === 'session_key') {
        this.tryAutoLogin();
      }
    });
  }
}

<fa-icon
  class="tips-icon clickable"
  [icon]="tooltipIcon"
  [tippy]="'Click for password tips'"
  [matMenuTriggerFor]="passwordTips"
></fa-icon>
<mat-menu #passwordTips>
  <div class="password_tips_menu horizontal-layout no-seperator-line">
    <p style="font-style: italic">Tips for a stronger password:</p>
    <ul>
      <li>A length of at least 10 characters</li>
      <li>1 or more numerals</li>
      <li>1 or more special characters</li>
      <li>Upper and lower case characters</li>
      <li>No repeating patterns</li>
      <li>Avoid common passwords</li>
    </ul>
  </div>
</mat-menu>

function checkValidDateStr(str: string): boolean {
  return /(\d+\-\d+\-\d+)\D?(\d+\:\d+\:\d+)*( )*(UTC){0,1}$/.test(str);
}

export default class Utils {

  /**
   * Returns the unix timestamp in seconds, which is the official timestamp accepted to the BE.
   * @param datetime the date or timestamp of the given date. If not provided, use the current timestamp.
   * @returns the unix timestamp in seconds.
   */
  public static toUnixTimestamp(datetime: Date | number = Date.now()): number {
    return Math.floor(new Date(datetime).getTime() / 1000);
  }

  /**
   * Converts a valid date string in its corresponding UTC ISO 8601 formatted string.
   * ISO 8601 format is required for Firefox to interact properly with Date objects.
   * @param dateString 
   * @returns the formatted date string or the string 'Invalid Date' if the input is invalid
   */
   public static toUTCISO8601(dateString: string): string {
    if (checkValidDateStr(dateString)) {
      return dateString.replace(' ', 'T').replace(' UTC', 'Z');
    }

    return 'Invalid Date';
  }

  public static isWeekend(date: Date = new Date()): boolean {
    const dayOfWeek = date.getDay();
    // 6 = Saturday, 0 = Sunday
    return (dayOfWeek === 6) || (dayOfWeek  === 0);
  }

  public static isObject(item: any): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  public static merge<T extends {}>(target: T, ...sources: T[]) {
    if (!sources.length) return target;
    const source = sources.shift();
    const isObject = Utils.isObject;
  
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          Utils.merge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return Utils.merge(target, ...sources);
  }

  public static getDateStringFromTime(input: number, timeInterval: number): string {
    const date = new Date(
      input - (input % (60 * timeInterval * 1000)) + tzDiffMinutes * 60 * 1000
    );
    
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")} UTC`;
  }
}

// based on the user machine, returns 60 minutes for 1 hour offset
export const tzDiffMinutes = new Date().getTimezoneOffset();
import { AlertSettings, SettingsApiService } from './settings-api.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { DataRequestStatus } from '../../shared/interfaces/data-request-status';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

const STORAGE_KEYS: string[] = [
  'user_units_imperial',
  'user_show_weekends_charts'
];

@Injectable({
  providedIn: 'root',
})
export class SettingsDataService {
  private settingsDataObs$ = new ReplaySubject<AlertSettings>(1);
  setNewSettings(input: AlertSettings): void {
    this.settingsDataObs$.next(input);
  }
  settingsObs$ = this.settingsDataObs$.asObservable();
  isImperial$: Observable<boolean> = this.settingsObs$.pipe(
    map((data) => data.preferable_units === 'us_units')
  );

  private currentSavingStatus$: BehaviorSubject<DataRequestStatus> =
    new BehaviorSubject<DataRequestStatus>(undefined);
  currentSavingStatusObs$ = this.currentSavingStatus$.asObservable();

  constructor(private settingsApiService: SettingsApiService) {}

  getSettings(): void {
    this.settingsApiService.getAlertSettings().subscribe((data) => {
      this.setNewSettings(data);
      localStorage.setItem(
        STORAGE_KEYS[0],
        data.preferable_units === 'us_units' ? 'true' : 'false'
      );
    });
  }

  updateSettings(input: Partial<AlertSettings>): void {
    this.currentSavingStatus$.next({
      status: 'Loading',
      message: 'Updating',
    });

    this.settingsApiService.patchAlertSettings(input).subscribe(
      (data) => {
        this.setNewSettings(data);
        this.currentSavingStatus$.next({
          status: 'Complete',
          message: 'Update Complete',
        });

        localStorage.setItem(
          STORAGE_KEYS[0],
          data.preferable_units === 'us_units' ? 'true' : 'false'
        );

        this.delayedEmptySavingStatus();
      },
      (err) => {
        this.currentSavingStatus$.next({
          status: 'Error',
          message: 'Error updating settings, please try again',
        });

        this.delayedEmptySavingStatus();
      }
    );
  }

  delayedEmptySavingStatus(): void {
    setTimeout(() => this.currentSavingStatus$.next(undefined), 4000);
  }

  public setWeekendsForCharts(value: boolean): void {
    localStorage.setItem(STORAGE_KEYS[1], '' + value);
  }

  public getWeekendsForCharts(): boolean {

    const value = localStorage.getItem(STORAGE_KEYS[1]);

    if (!value) {
      return true;
    }

    return value === 'true';
  }

  public clear(): void {
    STORAGE_KEYS.forEach(key => localStorage.removeItem(key));
  }
}

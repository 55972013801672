<div class="outer horizontal-layout no-seperator-line">
  <img src="../../../../assets/feedback_backgrounds/parameter_feedback.png" alt="there was an error">
  <h1 class="center-text">Sorry, there was an error!</h1>
  <div>
    <h3>Message:</h3>
    <p>{{error?.message}}</p>
  </div>
  <div>
    <h3>Errors:</h3>
    <div>
      <ng-container *ngIf="errorsIsArray; else errorIsObjectTemplate">
        <ng-container *ngFor="let error of error?.error; let i = index">
          <ol>
            <li [value]="i+1">{{error}}</li>
          </ol>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <ng-content></ng-content>
</div>

<ng-template #errorIsObjectTemplate>
  <ng-container *ngFor="let error of error?.error | keyvalue; let i = index">
    <ol>
      <li [value]="i+1">{{error.key}}:
        <ng-container *ngIf="isThisArray(error.value); else singleValueTemplate">
          <ng-container *ngIf="!error.value.length">
            <span>no value</span>
          </ng-container>
          <ng-container *ngFor="let value of error.value">
            <p>{{value + ' '}}</p>
          </ng-container>
        </ng-container>
        <ng-template #singleValueTemplate>
          <ng-container *ngIf="isThisObject(error.value); else singleValueNotObjectTemplate">
            <ng-container *ngFor="let error of error.value | keyvalue">
              {{error.key}}: {{error.value}}
            </ng-container>
          </ng-container>
          <ng-template #singleValueNotObjectTemplate>
            {{error.value ? error.value : 'Not present'}}
          </ng-template>
        </ng-template>
      </li>
    </ol>
  </ng-container>
</ng-template>
